import { Layout, Skeleton, Space } from 'antd'
import React from 'react'
const { Content } = Layout

const contentStyle: React.CSSProperties = {
    color: '#fff',
    height: 'calc(100vh - 65px)',
}

const TableSkeleton = () => {
    return (
        <Content style={contentStyle} className="bg-[#EFF1F9] min-h-dvh">
            <div className="w-full bg-white rounded-lg py-[10px] px-[20px]">
                <Space size={11} className="float-right">
                    <Skeleton.Input active={true} size="default" />
                    <Skeleton.Input
                        active={true}
                        size="default"
                        style={{ width: 300 }}
                    />
                    <Skeleton.Button
                        active={true}
                        size="default"
                        style={{ width: 50 }}
                        block={false}
                    />
                </Space>
                {/* <Space className='d-block' style={{marginTop: "40px"}}> */}
                <Skeleton.Input
                    active={true}
                    size="large"
                    style={{ height: 200, marginTop: '10px' }}
                    block={true}
                />
                {/* </Space> */}
                <br />
                <div>
                    <Space
                        size={5}
                        className="mt-2"
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Skeleton.Avatar
                            active={true}
                            size="small"
                            shape="square"
                        />
                        <Skeleton.Avatar
                            active={true}
                            size="small"
                            shape="square"
                        />
                        <Skeleton.Avatar
                            active={true}
                            size="small"
                            shape="square"
                        />
                        <Skeleton.Avatar
                            active={true}
                            size="small"
                            shape="square"
                        />
                        <Skeleton.Button
                            active={true}
                            size="small"
                            style={{ width: '15px' }}
                            block={false}
                        />
                    </Space>
                </div>
            </div>
        </Content>
    )
}

export default TableSkeleton
