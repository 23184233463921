import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { ElementExecutor } from '@apexcura/core';
import { toast } from 'react-toastify';
import { CONSTANTS } from '../utils/app-constants';
import Utils from '../utils';
import Storage from '../utils/local-storage';
import { useDispatch, useSelector } from 'react-redux';
import { getPagePermissions } from '../redux/auth/authSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { setActiveMenu, setTopbar } from '../redux/uiSchema/uiSchemaSlice';
import {Tooltip,Avatar} from "antd"
import {Popover} from "antd"
import { Select } from 'antd';
import type { SelectProps } from 'antd';
import { FaRegFaceSmileBeam } from "react-icons/fa6";
import { MdCall } from "react-icons/md";
import { setLoading } from '../redux/appState/appStateSlice';

import { setDateRange, setFilters, setSearchText ,setPagination} from '../redux/calls/callSlice';
const { BASE_URL, FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;

const newCards:any=[
    {
        name: 'avg_sent_score',
        visible: true,
        className: 'calls-top-card',
        element: '',
        fields: [
            {
              name:"avg_sent_score_icon_and_value",
              className:"flex gap-2 justify-center items-center",
              fields:[
                {
                  name: 'avg_sent_score_icon',
                  element: 'image',
                  img:`${BASE_URL}/api/public/assets/images/calls-rounded.svg`,
                  imgClassName:"w-8 h-8  cursor-default",
                  visible: true,
                },
                {
                  name: 'avg_sent_score_text',
                  className: 'text-sm font-semibold text-[#37474F]',
                  element: 'div',
                  label: <span>Sentiment Score <small>(Avg)</small></span>,
                  visible: true,
                },
              ]
            },
            {
              name: 'avg_sent_score_value',
              className:'text-lg font-semibold text-[#254AAA]',
              element: 'div',
              label: 0,
              visible: true,
          },
        ],
    },
    {
      name: 'avg_call_quality',
      visible: true,
      className: 'calls-top-card',
      element: '',
      fields: [
          {
            name:"avg_call_quality_icon_and_value",
            className:"flex gap-2 justify-center items-center",
            fields:[
              {
                name: 'avg_call_quality_icon',
                element: 'image',
                img:`${BASE_URL}/api/public/assets/images/calls-rounded.svg`,
                imgClassName:"w-8 h-8  cursor-default",
                visible: true,
              },
              {
                name: 'avg_call_quality_text',
                className: 'text-sm font-semibold text-[#37474F]',
                element: 'div',
                label: <span>Call Quality <small>(Avg)</small></span>,
                visible: true,
              },
            ]
          },
          {
            name: 'avg_call_quality_value',
            className:'text-lg font-semibold text-[#254AAA]',
            element: 'div',
            label: 0,
            visible: true,
        },
      ],
    },
]

const Calls = () => {
  const [selectedCell, setSelectedCell] = useState<any>()
  const[ multiselectschema, setMultiselectschema] = useState<any>({
    className: 'w-full',
    schema: [{
      name: "call-tags",
      element: "single-select",
      placeholder: "",
      containerClassName: "w-full flex flex-col col-span-full",
      className: "rounded-md text-gray-700 font-semibold focus:outline-none min-w-[150px] max-w-[300px] call-tags",
      visible: true,
      mode: "tags",
      value: null,
      options: [],
      disabled: false,
      customColor:true,
    }]
  })
  const [tagOptions, setTagOptions] = useState<SelectProps['options']>([]);
  
  const {dateRange,searchText,filters,pagination} = useSelector((store:any) => store.calls);
  const {page,pageSize}=pagination
    const [schema, setSchema] = useState<any>({
        className: 'w-full',
        schema: [
          {
              name: 'call_details',
              visible: true,
              className: 'grid grid-cols-6 gap-2 font-semibold w-full',
              fields:[]
          },
          {
            name: 'filters',
            fields: [
              {
                name:"date_and_search",
                className:"flex gap-2 justify-center items-center ",
                fields:[
                  {
                    name: "search",
                    label: "",
                    placeholder: "Search by Customer Number",
                    isSearch:true,
                    element: "input-text",
                    value: searchText,
                    containerClassName: 'w-60 flex flex-col rounded-3xl overflow-hidden',
                    className: 'w-full rounded-3xl p-2 px-3 font-medium bg-white overflow-hidden',
                    icon:"search",
                    iconsClassName:"w-[15px] mr-1 flex justify-center items-center",
                    
                  },
                  {
                    name:"segments",
                    element:"segmented",
                    segmentedOptions:[
                      {
                          label:(<Tooltip title="Today">1D</Tooltip>),
                          value:"TD"
                      },
                      // {
                      //     label:(<Tooltip title="Yesterday">YD</Tooltip>),
                      //     value:"YD"
                      // },
                      {
                          label:(<Tooltip title="This Week">1W</Tooltip>),
                          value:"TW"
                      },
                      {
                          label:(<Tooltip title="This Month">1M</Tooltip>),
                          value:"TM"
                      },
                    ],
                    visible:true,
                    value:"TW"
                  },
                  {
                    name: 'date',
                    label: '',
                    placeholder: 'Select a date',
                    element: 'daterangepicker',
                    value:dateRange,
                    className: 'flex-1 w-[240px]',
                  },
                ]
              },
              {
                name:"refresh_and_download",
                className:"flex gap-2 justify-center items-center ",
                fields:[
                  {
                    name: 'btn_refresh',
                    label: 'Refresh',
                    element: 'button',
                    icon: 'refresh',
                    className: 'secondary-button',
                    action: 'download_list',
                    iconsClassName: 'size-4',
                  },
                  {
                    name: 'btn_download',
                    label: 'Download',
                    element: 'button',
                    icon: 'download',
                    visible: false,
                    className: 'secondary-button',
                    action: 'download_list',
                    iconsClassName: 'size-4',
                  },
                ]
              },
            ],
            className: 'flex justify-between items-center gap-2 bg-white p-4 rounded-none rounded-t-lg mt-2',
          },
          {
            count:0,
            className: 'bg-white p-4 rounded-none rounded-b-lg pt-0',
            name: "table",
            element: 'table-element',
            loading: true,
            variant: 'plain',
            size: 'small',
            pagination: true,
            value:[{name:"pagination",page,pageSize},{name:"filters",value:{status:filters.status}}],
            rowClick: true,
            view: true,
            visible: true,
            // value:null,
            thead: [
              {
                name:"Name",label:"Customer",key:"Name",fixed:"left",
                render:(e:any,record:any)=>{
                  return (
                    <div className="flex gap-1 items-center">
                      <Avatar size={"small"} style={{backgroundColor:`${Utils.stringToColour(e)}`}}> 
                              <p className={`capitalize`}>{Utils.initials(e)}</p>
                      </Avatar>
                      <div className={"capitalize"}>{e}<p className='text-xs'>({record["From"]})</p></div>
                    </div>
                  )
                }
              },
              // { name: 'From', label: 'From', key: 'From' },
              { name: 'DialWhomNumber', label: 'Agent', key: 'DialWhomNumber', render: (e:any) => <p className='text-center'>{e || "--"}</p> },
              // { name: 'To', label: 'IVR', key: 'To' },
              // {name: 'Direction', label: 'Direction', key: 'Direction'},
              {
                name: 'StartTime',
                label: 'Arrived on',
                key: 'StartTime',
              },
              {
                name: 'DialCallDuration',
                label: 'Duration',
                key: 'DialCallDuration',
                render: (e: any) => <p className='text-center'>{e || "--"}</p>
              },
              // {
              //   name:"status",
              //   label:"Status",
              //   key:"status",
              //   filters: [
              //     { text: 'None', value: 'None' },
              //     { text: 'Open', value: 'Open' },
              //     { text: 'Pending', value: 'Pending' },
              //     { text: 'Closed', value: 'Closed' },
              //   ],
              //   render:(e:any,record:any)=>{
              //     const colors:any = {
              //       "None": "bg-[#29AAE1]/10 text-[#29AAE1]",
              //       "Open": "bg-fuchsia-500/5 text-fuchsia-500",
              //       "Pending": "bg-yellow-500/10 text-yellow-500",
              //       "Closed/Callback-done": "bg-green-500/5 text-green-500",
              //       "Closed/Call-back done": "bg-green-500/5 text-green-500",
              //       "Callback": "bg-fuchsia-500/5 text-fuchsia-500",
              //       "Closed": "bg-green-500/5 text-green-500  ",
              //   };
              //     const bgColor:string = colors[record?.status];
              //     return <span className={`w-full p-1 px-2 rounded-md font-medium ${bgColor}`}>{e}</span>
              //   }
              // },
              { name: 'status', label: 'Status', key: 'status',
                // filters:[
                //   { text: 'Call Back Request', value: 'call_back' },
                //   { text: 'Ambulance Request', value: 'ambulance_request' },
                //   { text: 'Call Was Successful', value: 'answered_calls' },
                //   { text: 'Client Hungup', value: 'hangup_sessions' },
                //   { text: 'Missed Call', value: 'missed_calls' },],
                filters:[
                  { text: 'Call Back Request', value: 'call_attempt' },
                  { text: 'Answered', value: 'answered' },
                  { text: 'Missed', value: 'missed' },],
                render:(e:any,record:any)=>{
                const colors:any = {
                  "call_attempt": "bg-[#29AAE1]/5 text-[#29AAE1]",
                  "callback": "bg-[#29AAE1]/5 text-[#29AAE1]",
                  "Ambulance Request": "bg-[#BF0E0E]/5 text-[#BF0E0E]",
                  "answered": "bg-[#4DA723]/5 text-[#4DA723]",
                  "Client Hungup": "bg-[#F59F0D]/5 text-[#F59F0D]",
                  "missed": "bg-[#F9A07A]/5 text-[#F37748]"
                };
                const status = record?.status === "" ? "--" : e;
                const bgColor:string = colors[record?.status_id] || "bg-[#F2F2F2]/50 text-default-300"
                return <span className={`w-full p-1 px-2 rounded-md font-medium ${bgColor}`}>{status}</span>
                
              } },
              {
                name: 'call-tags',
                label: 'Call Type',
                key: 'call-tags',
                render: (e: any, record: any) => {
                  let select : any;
                  const flag = true
                  return (
                    <div role="presentation" className={record?._id} id={record?._id}
                      onClick={(e) => {
                      e.stopPropagation();
                      // const targetElement = document.getElementById(record?._id);
                      
                      // if (targetElement instanceof HTMLElement) {
                      //     select = targetElement.getAttribute('id');
                      // }
                      // flag = !(select == record?._id)
                      // setSelectedCell(record?._id);
          
                      // console.log(record?._id, select, flag);
                      
                      // setMultiselectschema((prev:any) => {
                      //   const updatedSchema = prev?.schema?.map((f:any) => {
                      //     f.disabled = true
                      //   })
                      //   return {...prev, updatedSchema}
                      // })
                    }}>
                      <ElementExecutor
                        data={displayTags(record, flag)}
                        selectedRecord={(e: any) => {
                          handleSelectedRecord({name: e.name, value: e.value, call_id: record?._id});
                        }}
                      />
                    </div>
                  );
                }
                
              },
              {
                name: 'sentiment_score',
                label: 'Sentiment Score',
                key: 'sentiment_score',
                render: (e: any) => <p className='text-center'>{e ? e.toFixed(1) : "--"}</p>
              },
              {
                name: 'quality_score',
                label: 'Quality Score',
                key: 'quality_score',
                render:(e:any,record:any)=>{
                  const colors:any = {
                    "<50": "bg-[#BF0E0E]/5 text-[#BF0E0E]",
                    "81-100": "bg-[#4DA723]/5 text-[#4DA723]",
                    "51-80": "bg-[#F9A07A]/5 text-[#F37748]"
                  };
                  const bgColor:string =e<=50? colors["<50"]: e>=51 && e<=80?colors["51-80"]: colors["81-100"]
                  return <div className='flex justify-center'><span className={`p-1 px-2 rounded-md font-medium ${bgColor}`}>{e ? e?.toFixed(1) : "--"}</span></div>
                }
              },
            ],
            tbody: [],
            modalContent: {
              className: "flex flex-col gap-2 overflow-auto max-h-[400px] p-[10px]",
              fields: [],
            },
          },
          {
            name: 'view_modal_container',
            containerClassName:"  ",
            className: 'rounded-lg !w-full',
            element: "modal",
            width: 600,
            visible: false,
            fields:[
              {
                name: 'modal_title',
                className:
                  'w-full bg-primary/5 p-2 pl-4 flex items-center justify-between',
                fields: [
                  {
                    name: 'modal_title_key',
                    element: 'div',
                    label: 'Call Transcripts',
                    className:
                      'font-semibold text-lg text-primary',
                  },
                  {
                    name: 'modal_close',
                    element: 'button',
                    label: 'X',
                    className:
                      'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
                  },
                ],
              },
              {
                name: 'modal_body',
                className:
                  'flex flex-col p-4 !max-h-[80vh] overflow-auto gap-3',
                fields: [
                  {
                    name:"audio",
                    element:"div",
                    label:"",
                    className:"h-[20%] w-full"
                  },
                  {
                    name:"audio_text",
                    element:"div",
                    label:"Call Transcription",
                    className:"text-xl font-medium text-center"
                  },
                  {
                    name: "audio_transcription",
                    className: "flex flex-col gap-1 ",
                    fields: [

                    ]
                  },
                  // {
                  //   name:"audio_transcription",
                  //   element:"div",
                  //   label:"",
                  //   className:"h-[60%] w-full overflow-scroll [&::-webkit-scrollbar]:hidden font-base text-base text-black"
                  // },
                ],
              },
            ]
          },
          {
            name: "tag-modal",
            containerClassName:"!w-[50%]",
            className:
                'rounded-lg',
            visible:false,
            element:"modal",
            fields: [
              {
                  name: 'modal_title',
                  className:
                      'w-full bg-[#F2F2F2] p-2 pl-4 flex items-center rounded-t-lg justify-between',
                  fields: [
                      {
                          name: 'modal_title_key',
                          element: 'div',
                          label: 'Add Tag',
                          className:
                              'font-semibold text-lg text-primary',
                      },
                      {
                          name: 'modal_close',
                          element: 'button',
                          label: 'X',
                          className:
                              'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
                      },
                  ],
              },
              {
                name: "modal-body",
                className:
                      'w-full bg-[#F2F2F2] p-2 pl-4 flex flex-col items-center rounded-t-lg',
                fields: [
                  {
                    name: "tagname",
                    element: "input-text",
                    placeholder: "",
                    label: "Tag",
                    containerClassName: "w-full flex flex-col",
                    labelClassName:
                      "text-sm mb-1 text-gray-500 font-semibold text-start",
                    className:
                      "rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                    required: true,
                    visible: true,
                  },
                  {
                    name: "cancel_and_submit_update",
                    className: "w-full justify-end flex gap-2 pt-4",
                    fields: [
                      {
                        name: "cancel_feature_config",
                        label: "Cancel",
                        element: "button",
                        className:
                          "p-2 px-3  text-default-500 bg-gray-200 text-black rounded-md font-semibold",
                      },
                      {
                        name: "submit_feature_config",
                        label: "Save",
                        element: "button",
                        className:
                          "p-2 px-5  text-default-500 bg-primary  rounded-md text-white font-semibold",
                      },
                    ],
                   
                  },
                ]
              },
              
            ]
          }
        ],
    });
    // const [dateRange, setDateRange] = useState<[string, string]>([
    //   moment().subtract(7, 'days').format('DD-MM-YYYY'),
    //   moment().format('DD-MM-YYYY'),
    // ])
    // const [tablePageDetails, setTablePageDetails] = useState<any>({page:1, pageSize: 10})

    const [agentDateRange, setAgentDateRange] = useState<[string, string]>([
      moment().subtract(7, 'days').format('DD-MM-YYYY'),
      moment().format('DD-MM-YYYY'),
    ])

    // const [searchText, setSearchText] = useState('');
    const [agentSearchText, setAgentSearchText] = useState('');
    // const [pagination,setPagination]=useState({pagenum:1,limit:10});
    // const [filters,setFilters]=useState({status:[]})
    const dispatch=useDispatch()
    const location=useLocation()
    const navigate=useNavigate();
    const [calltype,setCalltype]=useState("");
    const tagDebounceTime = useRef<ReturnType<typeof setTimeout> | null>(null);

    const org_id = Storage.getItem("details")?.["org_id"];

    const pagePermissions = useSelector(getPagePermissions)

    const change_calls_data = (data: any) => {
      const download = pagePermissions.find((r:any) => r.route === location.pathname)?.download || false;
      const add = pagePermissions.find((r:any) => r.route === location.pathname)?.add || false;
      const view = pagePermissions.find((r:any) => r.route === location.pathname)?.view || false;
      setSchema((prev:any)=>{
        const updatedschema=prev.schema.map((field:any)=>{
          if(field.name==="call_details"){
            field.fields=field.fields.map((ffield:any)=>{
              ffield.fields=ffield.fields.map((fffield:any)=>{
                if(fffield.name==="total_calls_value"){
                  fffield.label=data?.counts?.totalCalls;
                }
                if(fffield.name==="answered_calls_value"){
                  fffield.label=data?.counts?.totalAnsweredCalls;
                }
                if(fffield.name==="total_missed_calls_value"){
                  fffield.label=data?.counts?.totalMissedCalls;
                }
                if(fffield.name==="total_callback_requests_value"){
                  fffield.label=data?.counts?.totalCallBackRequest;
                }
                if(fffield.name==="total_ambulance_requests_value"){
                  fffield.label=data?.counts?.totalAmbulanceRequest;
                }
                if(fffield.name==="hangup_calls_value"){
                  fffield.label=data?.counts?.totalHangupSessions;
                }
                if(fffield.name==="avg_sent_score_value"){
                  fffield.label=data?.counts?.avgSentimentScore;
                }
                if(fffield.name==="avg_call_quality_value"){
                  fffield.label=data?.counts?.avgQualityScore;
                }
                return fffield;
              })
              return ffield;
            })
          }
          if(field.name==="filters"){
            field.fields=field.fields.map((ffield:any)=>{
              if(ffield.name==="refresh_and_download"){
                ffield.fields=ffield.fields.map((fffield:any)=>{
                  if(fffield.name==="btn_download"){
                    fffield.visible = download
                  }
                  return fffield;
                })
              }
              return ffield;
            })
          }
          if(field.name==="table"){
            field.loading = false;
            field.count = data?.total;
            field.tbody=data?.data.map((item:any) => {
              item.StartTime = moment(item.StartTime, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY HH:mm");
              // item["sentiment_score"]=Math.floor(Math.random() * 100) + 1;
              // item["quality_score"]=Math.floor(Math.random() * 100) + 1;
              return item;
            });
            const hasactions = field["thead"].some((ef:any)=>ef.name === "actions");
              if ((view || add) && !hasactions){
                field['thead'].push({
                  name: 'actions',
                  label: '',
                  key: 'actions',
                  fixed: 'right',
                  sortable:false,
                  render: (e: any, record: any) => {
                    return (
                      <div className='w-full flex gap-1 justify-end items-center'>
                        {record?.DialCallDuration?.length!==0 && record?.RecordingUrl && record?.RecordingUrl?.toLowerCase() !== "null" && <div
                            // onKeyDown={() =>
                            //   handleSelectedRecord({ name: "audio", value: record })
                            // }
                            // tabIndex={0}
                            // role="button"
                            role= "presentation"
                            onClick={(e) => {
                              e.stopPropagation(); 
                              handleSelectedRecord({ name: "audio", value: record })
                              }
                            }
                          >
                            <Tooltip title="Audio & Transcript">
                            <img
                              src={
                                `${BASE_URL}/api/public/assets/images/audio.svg`
                              }
                              alt="View the record"
                              className='w-[30px] h-[20px] cursor-pointer'
                            />
                            </Tooltip>
                          </div>}
                          {<div
                            className='w-[20px] h-[20px] p-1 cursor-pointer  flex items-center justify-center bg-white rounded-md'
                            role='presentation'
                            onClick={ (e) => {
                              e.stopPropagation(); 
                            }
                            }
                          >
                            <Tooltip title="Quick actions">
                              <Popover
                                content={
                                  <div className='flex flex-col gap-2'>
                                    {/* <p className='text-primary font-medium text-sm'>Quick Actions</p>
                                    <hr/> */}
                                    {[{"name": "comments", "label": `${"  "}Add a Comment`, icon:"comments.svg"}, {name: "book_appointment", label: "Book an appointment", icon: "appointments.svg"}].map((item:any)=>{
                                      return <button key={item.name} className='text-xs font-medium hover:shadow-sm transition-all duration-600 text-left text-gray-900 rounded-md p-1 flex items-center gap-1'>
                                        <img src={`${BASE_URL}/api/public/assets/images/${item.icon}`} alt={item.icon} className='w-[20px] h-[20px]'/>{item.label}
                                      </button>;
                                    })}
                                  </div>
                                }
                                placement="bottomRight"
                                title=""
                                trigger="click"
                              >
                                <p className='text-2xl font-bold' >+</p>
                              </Popover>
                            </Tooltip>
                          </div>}
                          {/* {view && <div
                            onKeyDown={() =>
                              handleSelectedRecord({ name: "view", value: record })
                            }
                            tabIndex={0}
                            role="button"
                            onClick={() =>
                              handleSelectedRecord({ name: "view", value: record })
                            }
                          >
                            <Tooltip title="View">
                            <img
                              src={
                                `${BASE_URL}/api/public/assets/images/eye.svg`
                              }
                              alt="View the record"
                              className='w-[80px] h-[15px]'
                            />
                            </Tooltip>
                          </div>} */}
                          
                      </div>
                    );
                  },
                })
              }
          }
          return field;
        })
        return {...prev,schema:updatedschema}
      })
    }


    useEffect(() => {
      if (location.state?.source === "Calls") {
        dispatch(setTopbar(location.state?.source))
        dispatch(setActiveMenu("/calls"))
      }
    }, [location.state?.source, dispatch]);

    useEffect(() => {
      const id = setTimeout(() => {
        API_GET_CALLS_Data({
              startDate: dateRange[0],
              endDate: dateRange[1],
              searctext: searchText,
              callType:calltype,
              status:filters.status
          })
      }, 500)

      return () => {
          clearTimeout(id)
      }
    }, [searchText])

    useEffect(()=>{
        if(dateRange[0]==="" && dateRange[1]===""){
            change_segments_value(null);
            return;
        }
        const first = moment(dateRange[0], 'DD-MM-YYYY');
        const second = moment(dateRange[1], 'DD-MM-YYYY');
        const diffInDays = second.diff(first, 'days');
        const today = moment().startOf('day');
    
        if (first.isSame(today, 'day') && second.isSame(today, 'day')) {
            change_segments_value("TD")
        } else if (diffInDays === 7 && second.isSame(today, 'day')) {
            change_segments_value("TW")
        } else if (diffInDays === 30 && second.isSame(today, 'day')) {
            change_segments_value("TM")
        }else{
            change_segments_value(null)
        }
    },[dateRange])
    
  const displayTags = (record: any, select:any) => {
    
    const updatedSchema = multiselectschema?.schema?.map((f: any) => {
      if (f.name === "call-tags") {
        return {
          ...f,
          value: record["call_tags"] || [],
          id : record?._id,
          // disabled : select
        };
      }
      return f; 
    });
    return {
      ...multiselectschema,
      schema: updatedSchema,
    };
  };

  const handleTagOptions = async() => {
    const res = await Utils.makeApiCall("/getCallTags", "GET");
    if(res.status) {
      setMultiselectschema((prev:any) => {
        const schema = prev?.schema?.map((f:any) => {
          if(f.name === "call-tags"){
            f.options = res?.data?.map((option:any) => ({label: option?.name, value: option?._id, color: option?.colour || "#8c8c88"}))
          }
          return f;
        })
        return {...prev, schema}
      })
    }
  }

    const change_segments_value=(val:string | null)=>{
      setSchema((prev:any)=>{
          const updatedSchema=prev.schema.map((field:any)=>{
              if(field.name==="filters"){
                field.fields=field.fields.map((ffield:any)=>{
                  if(ffield.name==="date_and_search"){
                    ffield.fields=ffield.fields.map((fffield:any)=>{
                      if(fffield.name==="segments"){
                        fffield.value=val
                      }
                      return fffield;
                    })
                  }
                  return ffield;
                })
              }
              return field;
          })
          return {...prev,schema:updatedSchema}
      })
    }

    const fetchCardsSchema = async() => {
      try{
        const res = await Utils.makeApiCall('/schema?schema_for=calls_cards', "GET")
        // const response = await axios.get("http://localhost:3001/api/schema?shema_for=calls_cards");
        // const res = response?.data;
        // console.log("ok", res);
        
        if (res.status) {
          setSchema((prev: any) => {
            const updatedSchema = prev?.schema?.map((f: any) => {
              if (f.name === "call_details") {
                // f.className = res?.data?.length < 4 ?`grid grid-cols-${res?.data?.length} gap-2 font-semibold w-full` : f.className;
                f.fields = [...res.data.map((ff: any) => {
                  if (!ff.fields[0].fields[0].img.startsWith(BASE_URL)) {
                    ff.fields[0].fields[0].img = BASE_URL + ff.fields[0].fields[0].img;
                  }
                  return ff;
                }),...newCards]
              }
              return f;
            });
            return { ...prev, schema: updatedSchema };
          });
        }
      } catch (e){
        console.log("error while fetching cards schema", e);
      }
    }

    useEffect(() => {
      fetchCardsSchema();
      handleTagOptions();
    }, [])
    // useEffect(() => {
    //   console.log(filters);
      
    //   if (filters !== null) {
    //     // Trigger the API call once filters are updated
    //     API_GET_CALLS_Data({
    //       startDate: dateRange[0],
    //       endDate: dateRange[1],
    //       searctext: searchText,
    //       callType: calltype,
    //       callStatus: filters,  // now it will have the updated filters
    //     });
    //   }
    // }, [filters]);

    useEffect(() => {
      const serverUrl = CONSTANTS.SOCKET_URL;
    const ws = new WebSocket(serverUrl);
      // Listen for WebSocket messages
      ws.onopen = () =>{
        console.log('Connected to WebSocket server.');
      }
      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);

        console.log("1010", event.data);
        
  
        if (message.type === 'ivr_calls') {

          if(message.org_id === org_id){
            console.log("Socket triggered!!!");
            
            API_GET_CALLS_Data();
          }
        }
      };
      ws.onclose = () =>{
        console.log("connection closed!!");
        
      }
  
      // Clean up WebSocket connection when component unmounts
      return () => {
        ws.close();
      };
    }, []);

    const API_GET_CALLS_Data=async ({
      page = 1,
      limit = 10,
      startDate = "",
      endDate = "",
      searctext = "",
      callType="",
      status=[]
    } = {})=>{
      setSchema((prev: any) => Utils.handleLoading(prev, true))
      try {
          // const callStatusString = callStatus&&callStatus ? callStatus.join(",") : "";
          const statusString = status&&status.length ? status.join(",") : "";
          
          const res: any = await Utils.makeApiCall(`/getIvrExotelCalls?org_id=${org_id}&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&search=${searctext}&call_type=${callType}&status=${statusString}`, "GET");
          if (res.status) {
              change_calls_data(res);

          } else {
              toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
          }
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
          console.error('Failed to fetch schema', error);
      }
      setSchema((prev: any) => Utils.handleLoading(prev, false))
    }
    const API_GET_CALLS_Scores=async ()=>{
      try {
          const res: any = await Utils.makeApiCall(`/getCallsScores`, "GET");
          if (!res.status) {
              toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
          }
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
          console.error('Failed to fetch schema', error);
      }
    }
    const API_GET_REFRESH_CALLS_Data=async ({
      page = 1,
      limit = 10,
      startDate = "",
      endDate = "",
      searctext = "",
      callType=""
    } = {})=>{
      dispatch(setLoading(true))
      try {
          const res: any = await Utils.makeApiCall(`/getIvrExotelCalls?org_id=${org_id}&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&searchText=${searctext}&call_type=${callType}`, "GET");
          if (res.status) {
              change_calls_data(res);

          } else {
              toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
          }
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
          console.error('Failed to fetch schema', error);
      }
      finally{
        dispatch(setLoading(false))
      }
    }

    const downloadToExcel = async () => {
      try {
        const tableSchema = schema.schema.find((s: any) => s.element === "table-element");
        const { thead } = tableSchema;
        dispatch(setLoading(true))

        const response = await Utils.makeApiCall(`/getIvrExotelCalls?page=1&limit=10&startDate=${dateRange[0]}&endDate=${dateRange[1]}&download=true&search=${searchText}`, "GET");
        // const formattedData: any = response?.data.map((item: any) => {
        //   const formattedStartTime = moment(item.StartTime).format('DD-MM-YYYY HH:mm:ss');
        //   let formattedDuration = '';
        //   let formattedDialCallStatus = item.DialCallStatus;
  
        //   if (item.DialCallStatus.toLowerCase() === "completed") {
        //       const durationInSeconds = item.DialCallDuration;
        //       const duration = moment.duration(durationInSeconds, 'seconds');
              
        //       if (duration.asHours() >= 1) {
        //           formattedDuration = `${Math.floor(duration.asHours())}h ${duration.minutes()}m ${duration.seconds()}s`;
        //       } else if (duration.asMinutes() >= 1) {
        //           formattedDuration = `${Math.floor(duration.asMinutes())}m ${duration.seconds()}s`;
        //       } else {
        //           formattedDuration = `${duration.seconds()}s`;
        //       }
  
        //       formattedDialCallStatus = "Call was successful";
        //   } else if (item.digits === "\"2\"") {
        //       formattedDialCallStatus = "Ambulance request";
        //   } else if (item.digits === "\"1\"") {
        //       formattedDialCallStatus = "Callback request";
        //   }
  
        //   const formattedDirection = item.Direction.charAt(0).toUpperCase() + item.Direction.slice(1).toLowerCase();
  
        //   return {
        //       ...item,
        //       DialCallDuration: formattedDuration,
        //       StartTime: formattedStartTime,
        //       originalStartTime: item.StartTime,
        //       DialCallStatus: formattedDialCallStatus,
        //       Direction: formattedDirection
        //   };
        // });
  
        // const sortedData = formattedData.sort((a:any, b:any) => {
        //     return moment(b.originalStartTime).valueOf() - moment(a.originalStartTime).valueOf();
        // });
        // const data = sortedData.map(({ originalStartTime, ...item }: any) => item);
        dispatch(setLoading(false))

        if (!response?.data || response?.data.length === 0) {
          toast.error("No data available to download", { position: "top-center", autoClose: AUTO_CLOSE });
          return;
        }

        Utils.exportToExcel(thead, response?.data, "Calls");
      } catch (error) {
        console.error("Error downloading Excel:", error);
        toast.error("Failed to download data as Excel", { position: "top-center", autoClose: AUTO_CLOSE });
      }
      finally{
        dispatch(setLoading(false))
      }
    };

    const setTablePagination=()=>{
      setSchema((prevState: any) => {
        const updatedSchema = prevState.schema.map((s: any) => {
          if (s.name === "table") {
            dispatch(setPagination({ name: 'pagination', page: 1, pageSize }))
            s.value = [{ name: 'pagination', page: 1, pageSize }];
          }
          return s;
        });
        return { ...prevState, schema: updatedSchema };
      });
    }

    const fetchAudio = async (url:any) => {
      const response = await fetch(url, {
          headers: {
              'Authorization': 'Basic ' + btoa('00d1ed376737e1388536fb2afbfda8e31c4d0e2e8e4731c5:809c45c638cb4a64a02daf60ce5d7eefadfde89306d6c7c9'),
          },
      });
      const blob = await response.blob();
      const audioUrl = URL.createObjectURL(blob);
      return audioUrl;
  };

    const change_model_data=async (url:any,res:any, CallFrom: any)=>{
      // const audioUrl=await fetchAudio(url)
      // // console.log(audioUrl)
      // if(audioUrl){
        setSchema((prev:any)=>{
          const updatedSchema=prev.schema.map((field:any)=>{
            if(field.name==="view_modal_container"){
              field.visible=true,
              field.fields=field.fields.map((ffield:any)=>{
                if(ffield.name==="modal_title"){
                  ffield?.fields?.map((l: any)=>{
                    if(l.name==="modal_title_key"){
                      l.label = `Call Transcripts (${CallFrom})`
                    }
                  })
                }
                if(ffield.name==="modal_body"){
                  ffield.fields=ffield.fields.map((fffield:any)=>{
                    // if(fffield.name === "audio_transcription"){
                    //   fffield.label=res?.transcript;
                    // }
                    if(fffield.name==="audio_transcription"){
                       if(res?.transcript?.length > 0){
                        fffield.fields = []
                        res?.transcript?.map((d: any)=>{
                          fffield?.fields.push({
                            name: "transcript_msg",
                            className: `flex flex-col !gap-0 items-start ${d.type === "AGENT" ? "calls-agent-transcript" : "calls-patient-transcript"}`,
                            label: "",
                            element: "",
                            fields: [
                              // {
                              //   name: "",
                              //   className: `text-[9px] font-light ${d.type === "AGENT" ? "text-gray-200" : "text-gray-400"}`,
                              //   label: d.type,
                              //   element: "div",
                              // },
                              {
                                name: "",
                                className: "",
                                label: d.message,
                                element: "div",
                              }
                            ]

                          })
                        })
                       }
                    }
                    if(fffield.name==="audio"){
                      fffield.label=<audio controls controlsList="nodownload" className='w-[100%]'><source src={`${BASE_URL}/api/callrecordings?audio=${url}`} type="audio/mpeg" /> <track kind="captions" src="your-captions-file.vtt" srcLang="en" label="English captions" /> Your browser does not support the audio element.</audio>
                    }
                    return fffield;
                  })
                }
                return ffield;
              })
            }
            return field;
          })
          return {...prev,schema:updatedSchema}
        })
      // }
    } 
    

    const API_GET_AUDIO_TEXT=async (record:any)=>{
      
      dispatch(setLoading(true))
      try {
          const res: any = await Utils.makeApiCall(`/getCallTranscripts?id=${record?._id}`, "GET");
          if (res.status) {
            change_model_data(record?.RecordingUrl,res?.data, record?.CallFrom);
          } else {
              toast.error(res?.message ? res?.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
          }
          dispatch(setLoading(false))
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
          console.error('Failed to fetch schema', error);
      }
      dispatch(setLoading(false))
    }

    const handleCardsClick=(e:any)=>{
      setSchema((prev:any)=>{
        const updatedSchema=prev.schema.map((field:any)=>{
          if(field.name==="call_details"){
            field.fields=field.fields.map((ffield:any)=>{
              if(ffield?.name===e){
                ffield.className="rounded-md flex cursor-pointer border-[#93B8E2] gap-2 items-center justify-between border-[2px]  p-[10px]"
              }
              if(ffield?.name!==e){
                ffield.className="rounded-md flex cursor-pointer border-[#93B8E2] gap-2 items-center justify-between border-[1px]  p-[10px]"
              }
              return ffield
            })
          }
          return field;
        })
        return {...prev,schema:updatedSchema}
      })
    }

    const openCallModal = () => {
      setSchema((prev:any) => {
        const updatedSchema = prev.schema?.map((sec:any) => {
          if(sec.name === "tag-modal"){
            sec.visible = true;
          }
          return sec;
        })
        return {...prev,schema:updatedSchema}
      })
    }
    const closeCallModal = () => {
      setSchema((prev:any) => {
        const updatedSchema = prev.schema?.map((sec:any) => {
          if(sec.name === "tag-modal"){
            sec.visible = false;
          }
          return sec;
        })
        return {...prev,schema:updatedSchema}
      })
    }

    const createCallTag = async(payload:any) => {
      try {
        // let payload;
        // schema?.schema?.map((sec:any) => {
        //   if(sec.name === "tag-modal"){
        //     sec?.fields?.map((f:any) => {
        //       if(f.name === "modal-body"){
        //         f?.fields?.map((ff:any) => {
        //           if(ff.name === "tagname"){
        //             payload = ff.value;
        //           }
        //         })
        //       }
        //     })
        //   }
        // })
        const res = await Utils.makeApiCall("/call-tags", "POST", {tag_name: payload});
        if(res.status){
          await handleTagOptions();
          closeCallModal()
        } else {
          toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
          console.log("Error while sending the data.");
        }
      } catch(err) {
          toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
          console.log("Error while sending the data.", err);
      }
    }

    const handleCallTags = async(e:any) => {
      if (tagDebounceTime.current) {
        clearTimeout(tagDebounceTime.current);
      }
      tagDebounceTime.current = setTimeout(async () => {
        try {
          let flag = false;
          const payload: any[] = [];
    
          for (const val of e?.value || []) {
            if (val?.label === val?.value) {
              console.log(e);
              flag = true;
              const res = await Utils.makeApiCall("/call-tags", "POST", { tag_name: val?.label });
              if (res.status) {
                payload.push(res?.data?._id);
                await handleTagOptions();
                
              } else {
                continue;
              }
            } else {
              payload.push(val?.value);
            }
          }
          const updateRes = await Utils.makeApiCall(`/updateCallTag?call_id=${e.call_id}`, "PUT", payload);
          if (!updateRes.status) {
            toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
            console.log("Error while sending the data.");
          } else {
            if (flag) {
              await API_GET_CALLS_Data({
                page: page,
                limit: pageSize,
                startDate: dateRange[0],
                endDate: dateRange[1],
                searctext: searchText,
                callType:calltype,
                status:filters.status
              });
            }
          }
        } catch (err) {
          toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
          console.log("Error while sending the data.", err);
        }
      }, 0);
    };    

    const handleSelectedRecord=(e:any)=>{
      console.log(e);
      if(e.name === "modal_close" || e.name === "cancel_feature_config"){
        closeCallModal();
      }
      if(e.name === "submit_feature_config") {
        // createCallTag()
      }
      if(e.name === "call-tags"){
        handleCallTags(e);
      }
      if (e.name === "btn_download") {
        downloadToExcel();
      }
      if(e.name === "search"){
        dispatch(setSearchText(e.value))
        setSchema((prevState: any) => {
          const updatedSchema = prevState.schema.map((s: any) => {
            if (s.element === "table-element") {
              s.value = [{ name: 'pagination', page: 1, pageSize }];
            }
            return s;
          });
          return { ...prevState, schema: updatedSchema };
        });
      }
      if (e.name === 'date') {
        setSchema((prevState: any) => {
          const updatedSchema = prevState.schema.map((s: any) => {
            if (s.element === "table-element") {
              s.value = [{ name: 'pagination', page: 1, pageSize }];
            }
            return s;
          });
          return { ...prevState, schema: updatedSchema };
        });
        if (e.value === '') {
          
            dispatch(setDateRange(['', '']))
            API_GET_CALLS_Data({ startDate: "", endDate: "",searctext:searchText,callType:calltype,status:filters.status })
        } else {
            dispatch(setDateRange(e.value))
            API_GET_CALLS_Data({ startDate: e.value[0], endDate: e.value[1],searctext:searchText,callType:calltype,status:filters.status });
        }
      }
      if(e.name === "btn_refresh"){
        setTablePagination();
        // handleCardsClick("total_calls");
        API_GET_CALLS_Scores();
        API_GET_REFRESH_CALLS_Data({
          // page: pagination.pagenum,
          // limit: pagination.limit,
          startDate: dateRange[0],
          endDate: dateRange[1],
          searctext: "",
          callType:""
          
        })
      }
      if (e.name === "table") {
        
      Array.isArray(e.value)?e.value.map((item: any)=>{
        if (item.name==="pagination" &&( pagination.page!==item.page || pagination.pageSize!==item.pageSize)){
          
          dispatch(setPagination(item))
          
          API_GET_CALLS_Data({
            page: item.page,
            limit: item.pageSize,
            startDate: dateRange[0],
            endDate: dateRange[1],
            searctext: searchText,
            callType:calltype,
            status:filters.status
          });
        }
        if (item.name==="filters"&& filters.status!==item.value.status){
          
          const {status}=item.value 
          
          dispatch(setFilters(item.value||{}))
          API_GET_CALLS_Data({
            startDate: dateRange[0],
            endDate: dateRange[1],
            searctext: searchText,
            callType:calltype,
            status:status,
            page:1
          });
          setSchema((prevState: any) => {
            const updatedSchema = prevState.schema.map((s: any) => {
              if (s.element === "table-element") {
                s.value = [{ name: 'pagination', page: 1, pageSize }];
              }
              return s;
            });
            return { ...prevState, schema: updatedSchema };
          });
        }
      }):""
        if (e?.["value"]?.name === "view") {
          const id =  e["value"]?.["value"]?.["_id"] || e["value"]?.["_id"];
          navigate(`/calls/view/${id}`)
        }
      }
    
      if (e.name === "segments") {
        if (e.value === "TD") {
            dispatch(setDateRange([
                moment().format('DD-MM-YYYY'),
                moment().format('DD-MM-YYYY'),
            ]));
            // dispatch(setPagination({ name: 'pagination', page: 1, pageSize }))
            API_GET_CALLS_Data({
                startDate: moment().format('DD-MM-YYYY'),
                endDate: moment().format('DD-MM-YYYY'),
                searctext: searchText,
                callType:calltype,
                status:filters.status

            })
            setSchema((prev: any) => {
                const updatedSchema = prev.schema.map((field: any) => {
                    if (field.name === "filters") {
                        field.fields = field.fields.map((ffield: any) => {
                            if (ffield.name === "date_and_search") {
                                ffield.fields = ffield.fields.map((ff: any) => {
                                    if (ff.name === "date") {
                                        ff.value = [
                                            moment().format('DD-MM-YYYY'),
                                            moment().format('DD-MM-YYYY'),
                                        ];
                                    }
                                    return ff;
                                });
                            }
                            return ffield;
                        });
                    }
                    if(field.element==="table-element"){
                      field.value = [{ name: 'pagination', page: 1, pageSize }];
                  }
                    return field;
                });
                return { ...prev, schema: updatedSchema };
            });
        }
        if (e.value === "TM") {
            dispatch(setDateRange([
                moment().subtract(30, 'days').format('DD-MM-YYYY'),
                moment().format('DD-MM-YYYY'),
            ]));
            // dispatch(setPagination({ name: 'pagination', page: 1, pageSize }))
            API_GET_CALLS_Data({
                startDate: moment().subtract(30, 'days').format('DD-MM-YYYY'),
                endDate: moment().format('DD-MM-YYYY'),
                searctext: searchText,
                callType:calltype,
                status:filters.status
            })
            setSchema((prev: any) => {
                const updatedSchema = prev.schema.map((field: any) => {
                    if (field.name === "filters") {
                        field.fields = field.fields.map((ffield: any) => {
                            if (ffield.name === "date_and_search") {
                                ffield.fields = ffield.fields.map((ff: any) => {
                                    if (ff.name === "date") {
                                        ff.value = [
                                            moment().subtract(30, 'days').format('DD-MM-YYYY'),
                                            moment().format('DD-MM-YYYY'),
                                        ];
                                    }
                                    return ff;
                                });
                            }
                            return ffield;
                        });
                    }
                    if(field.element==="table-element"){
                      field.value = [{ name: 'pagination', page: 1, pageSize }];
                  }
                    return field;
                });
                return { ...prev, schema: updatedSchema };
            });
        }
        if (e.value === "TW") {
            dispatch(setDateRange([
                moment().subtract(7, 'days').format('DD-MM-YYYY'),
                moment().format('DD-MM-YYYY'),
            ]));
            // dispatch(setPagination({ name: 'pagination', page: 1, pageSize }))
            API_GET_CALLS_Data({
                startDate: moment().subtract(7, 'days').format('DD-MM-YYYY'),
                endDate: moment().format('DD-MM-YYYY'),
                searctext: searchText,
                callType:calltype,
                status:filters.status,
            })
            setSchema((prev: any) => {
                const updatedSchema = prev.schema.map((field: any) => {
                    if (field.name === "filters") {
                        field.fields = field.fields.map((ffield: any) => {
                            if (ffield.name === "date_and_search") {
                                ffield.fields = ffield.fields.map((ff: any) => {
                                    if (ff.name === "date") {
                                        ff.value = [
                                            moment().subtract(7, 'days').format('DD-MM-YYYY'),
                                            moment().format('DD-MM-YYYY'),
                                        ];
                                    }
                                    return ff;
                                });
                            }
                            return ffield;
                        });
                    }
                    if(field.element==="table-element"){
                      field.value = [{ name: 'pagination', page: 1, pageSize }];
                  }
                    return field;
                });
                return { ...prev, schema: updatedSchema };
            });
        }
      }
      if(e?.value?.name==="view"){
        navigate(`/calls/view/${e?.value.value?._id}`)
      }
      if(e.name==="audio"){
        API_GET_AUDIO_TEXT(e?.value)
      }

      if(e.name==="modal_close"){
        setSchema((prev:any)=>{
          return {...prev,schema:prev.schema.map((field:any)=>{
            if(field.name==="view_modal_container"){
              field.visible=false;
              field.fields=field.fields.map((ffield:any)=>{
                if(ffield.name==="modal_body"){
                  ffield.fields=ffield.fields.map((fffield:any)=>{
                    if(fffield.name==="audio_transcription"){
                      fffield.fields = ""
                    }
                    if(fffield.name==="audio"){
                      fffield.label=<audio controls controlsList="nodownload" className='w-[100%]'><source src="" type="audio/mpeg" /> <track kind="captions" src="your-captions-file.vtt" srcLang="en" label="English captions" /> Your browser does not support the audio element.</audio>
                    }
                    return fffield;
                  })
                }
                return ffield;
              })
            }
            return field;
          })}
          return {...prev}
        })
      }
    }


  return (
    <div className="bg-[#EFF1F9]">
      <ElementExecutor
        data={schema}
        selectedRecord={(e: any) => {
          handleSelectedRecord(e);
        }}
      />
    </div>
  )
}

export default Calls;
