import { ElementExecutor } from "@apexcura/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { CONSTANTS } from "../../utils/app-constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { setActiveTab } from "../../redux/campaigns/campaignSlice";
import { setActiveMenu, setTopbar } from "../../redux/uiSchema/uiSchemaSlice";
import { Item } from "../../utils/types";
import Storage from "../../utils/local-storage";
import { getPagePermissions } from "../../redux/auth/authSlice";
import { Tooltip, Avatar, Popover } from "antd";
import { setLoading } from "../../redux/appState/appStateSlice";
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;
const { BASE_URL } = CONSTANTS;
const Campaigns = () => {
  const activeTab = useSelector((state: RootState)=> state.campaign.activeTab);  
  const [schema, setSchema] = useState<any>({
    className: "bg-white rounded-lg",
    schema: [
      {
        name: "tabs",
        element: "tabs",
        containerClassName: "p-0 m-0 px-4",
        className: "flex items-center gap-1",
        options: [
          {
            key: "whatsapp",
            label: "WhatsApp",
            icon: `${BASE_URL}/api/public/assets/images/whatsapp-white-border.svg`,
          },
          // {
          //   key: "sms",
          //   label: "SMS",
          // icon: `${BASE_URL}/api/public/assets/images/sms.svg`
          // },
        ],
        value: activeTab
      },
      { 
        name: "recipients_table_modal",
        containerClassName:"!w-[90%]  ",
        className: 'rounded-lg !w-full !h-[90vh]',
        element: "modal",
        visible: false,
        pagination: true,
        value: { name: 'pagination', page: 1, pageSize: 5 },
        fields: [
          {
            name: "modal_body",
            className:
              " flex flex-col justify-between items-top",
            fields: [
                {
                    name: "modal_head",
                    className: "flex justify-between items-center p-4 bg-primary/5",
                    fields: [
                        {
                            name:'modal_title_key',
                            className:'font-semibold text-lg text-[#3341BB]',
                            element:'div',
                            label:'Recipients'
                        },
                        {
                            name: "recipients_table_modal_close",
                            element: "button",
                            label: "X",
                            className:
                              "!w-[35px] !h-[35px] rounded-full bg-primary text-xl text-white flex justify-center items-center",
                        },
                    ]
                },
              
              {
                name: "recipients_table",
                className: 'p-4',
                element: 'table',
                variant: 'plain',
                size: 'small',
                pagination: true,
                value: { name: 'pagination', page: 1, pageSize: 10 },
                thead: [
                    {
                      name: "patient_name",
                      label: "Name",
                      key: "patient_name",
                      ellipsis:true
                    },
                    {
                      name: "phone_number",
                      label: "Mobile",
                      key: "phone_number",
                    },
                    {
                        name: "age",
                        label: "Age",
                        key: "age",
                      },

                    {
                      name: "gender",
                      label: "Gender",
                      key: "gender",
                    },
                                  
                  ],
                tbody: [],
            },
            
            ],
          },
          {
            name: "modal_body_container",
            className: "flex flex-col overflow-auto bg-white p-4",
            fields: [],
          },
          
        ],
      },
      {
        name: "add_form",
        fields: [
          {
            name: "add_form_sub",
            className:
              "w-full bg-white rounded-lg py-[10px] px-[20px] flex flex-col gap-3",
            fields: [
              {
                name: "filters",
                fields: [
                  {
                    name:"date_and_search",
                    className:"flex gap-2 justify-center items-center ",
                    fields:[
                      {
                        name: "search",
                        label: "",
                        placeholder: "Search by Camp Name",
                        isSearch:true,
                        element: "input-text",
                        value: null,
                        containerClassName: 'w-60 flex flex-col rounded-3xl overflow-hidden',
                        className: 'w-full rounded-3xl p-2 px-3 font-medium bg-white overflow-hidden',
                        icon:"search",
                        iconsClassName:"w-[15px] mr-1 flex justify-center items-center",
                      },
                      {
                        name:"segments",
                        element:"segmented",
                        segmentedOptions:[
                          {
                              label:(<Tooltip title="Today">1D</Tooltip>),
                              value:"TD"
                          },
                          // {
                          //     label:(<Tooltip title="Yesterday">YD</Tooltip>),
                          //     value:"YD"
                          // },
                          {
                              label:(<Tooltip title="This Week">1W</Tooltip>),
                              value:"TW"
                          },
                          {
                              label:(<Tooltip title="This Month">1M</Tooltip>),
                              value:"TM"
                          },
                        ],
                        visible:true,
                        value:"TW"
                      },
                      {
                        name: 'date',
                        label: '',
                        placeholder: 'Select a date',
                        element: 'daterangepicker',
                        value: [moment().subtract(7, 'days').format('DD-MM-YYYY'), moment().format("DD-MM-YYYY")],
                        className: 'flex-1 w-[240px]',
                      },
                      
                    ]
                  },
                 {
                  name:"download_and_Add",
                  className:"flex justify-center items-center gap-2",
                  fields:[
                    {
                      name: "btn_download",
                      label: "Download",
                      element: "button",
                      isSVGStylesOverride: true,
                      icon: "download",
                      visible: false,
                      className: "secondary-button",
                      action: "download_list",
                      iconsClassName: "size-4",
                    },
                    {
                      name: "btn_add",
                      label: "Add",
                      element: "button",
                      icon: "add",
                      visible: false,
                      className: "add-button",
                      iconsClassName: "size-4",
                    },
                  ]
                 }
                ],
                className: "flex justify-between items-center gap-2",
              },
              {
                className: "mt-2",
                element: "table",
                name: "campaigns_table",
                variant: "plain",
                pagination: true,
                size: "small",
                rowClick: true,
                count: 0,
                visible: true,
                loading: true,
                thead: [
                  {
                    name: "campaign_name",
                    label: "Camp Name",
                    key: "campaign_name",
                    fixed: "left",
                    render: (e:any) => <p className={`capitalize`}>{e}</p>
                  },
                  {
                    name: "schedulers",
                    label: "Schedules",
                    sortable:false,
                    key: "schedulers",
                    // render: (e:any, record: any) => record["schedules"]?.length || 0 
                    render: (e: any, record: any) => {

                      const schedules = record?.schedules
                      if (schedules?.length > 0) {
                        const colors = ["#FFB531", "#009FFD", "#84A98C", "#73EEDC", "#59C3C3"]
                        return(
                          <Avatar.Group
                              max={{
                                count: 5,
                                style: { color: '#f56a00', backgroundColor: '#fde3cf' },
                              }}
                            >
                            {
                              schedules.map((schedule: any, index: any) => {
                                let tempname = schedule?.template_name || "N/A"
                                tempname = tempname.split('_').map((word: any) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');  
                                  return (
                                    <Tooltip key={index} title={`${tempname}`} placement="top">
                                        <Avatar style={{ backgroundColor: `${colors[index] ? colors[index] : '#f56a00'}` }} size={"small"}>{schedule?.template_name?.slice(0,2)?.toUpperCase() || "Sc"}</Avatar>
                                    </Tooltip>
                                  )
                              })
                            }
                              
                            </Avatar.Group>

                        )

                      } else{
                        return 0
                      }
                      
                    }
                  },

                  {
                    name: "recipients_list",
                    label: "Recipients",
                    sortable:false,
                    key: "recipients_count",
                    render: (e: any, record: any) => {
                     
                      const recipients = record?.recipient_details || []
                      const cid = record?._id || ""
                      const tempId = record?.schedules?.[0]?.template_id || ""
                      const schedule_id = record?.schedules?.[0]?.schedule_id || ""
                      
                      
                      if (recipients?.length > 0) {
                        const colors = ["#FFB531", "#009FFD", "#84A98C", "#73EEDC", "#59C3C3"]
                        return(
                          <Avatar.Group
                              max={{
                                count: 6,
                                style: { color: '#f56a00', backgroundColor: '#fde3cf' },
                              }}
                            >
                            {
                              recipients.map((recipient: any, index: any) => {
                                if(index < 5){ 
                                  return (
                                    <Popover key={index} content={(
                                      <div className="flex flex-col gap-2">
                                        <span className="flex gap-1 items-center">
                                          <div className={`bg-[#f56a00] h-[30px] w-[30px] rounded-full flex items-center justify-center text-white`} >{recipient?.patient_name?.slice(0,2)?.toUpperCase() || 'N/A'}</div>
                                          <div className="font-medium text-lg" >{recipient?.patient_name || 'N/A'}</div>
                                        </span>
                                        <div className="flex gap-4">
                                          <span className="flex gap-1">
                                            <p className="font-medium">Age: </p>
                                            <p>{recipient?.age || "N/A"}</p>
                                          </span>
                                          <span className="flex gap-1">
                                            <p className="font-medium">Gender: </p>
                                            <p>{recipient?.gender || "N/A"}</p>
                                          </span>
                                        </div>
                                        <span className="flex gap-1">
                                          <p className="font-medium">Mobile: </p>
                                          <p>{recipient?.patient_number}</p>
                                        </span>
                                      </div>
                                    )} title="Recipient" placement="bottom">
                                        <Avatar style={{ backgroundColor: `${colors[index] ? colors[index] : '#f56a00'}` }} size={"small"}>{recipient?.patient_name?.slice(0,2)?.toUpperCase() || "N/A"}</Avatar>
                                    </Popover>
                                  )
                                }
                              })
                            }

                            {recipients?.length > 5 &&
                                
                              <Tooltip title="View all" placement="bottom">
                                  <Avatar onClick={(e)=> {
                                    e?.stopPropagation();
                                    handleRecipientsTableModal(tempId, cid, schedule_id, true)
                                    }} className="cursor-pointer"  style={{color: '#f56a00', backgroundColor:  '#fde3cf' }} size={"small"}>+{recipients?.length - 5}</Avatar>
                              </Tooltip>
                            }
                            
                              
                            </Avatar.Group>

                        )

                      } else{
                        return 'N/A'
                      }
                      
                    }

             
                  },

                  {
                    name: "status",
                    label: "Status",
                    key: "status",
                    render: (e: any, record: any) => { 
                      if (record?.status === "Completed"){
                        let text = record?.status || "N/A"
                        text = text[0]?.toUpperCase() + text?.slice(1)
                        return <div className="text-[#22c55e] bg-[#f4fcf7] w-fit py-1 px-4 rounded-md font-medium" >{text}</div>
                      }
                      else{
                        let text = record?.status || "N/A"
                        text = text[0]?.toUpperCase() + text?.slice(1)
                        return <div className="text-[#29aae1] bg-[#e9f6fc] w-fit py-1 px-4 rounded-md font-medium">{text}</div>
                      }

                      } 
                  },
                  

            
                  { name: "createdAt", label: "Created on", key: "createdAt" }
                ],
                tbody: []
              },
              // {
              //   className:
              //     'fixed inset-0 flex items-center justify-center z-50 blur-background transition-all duration-1000 ease-in-out',
              //   name: 'rp_modal_container',
              //   visible: false,
              //   fields: [
              //     {
              //       name: 'table_modal',
              //       className:
              //         'flex w-[60%] border shadow-lg rounded-lg flex-col bg-white overflow-hidden',
              //       fields: [
              //         {
              //           name: 'modal_title',
              //           className:
              //             'w-full bg-[#F2F2F2] p-2 pl-4 flex items-center justify-between',
              //           fields: [
              //             {
              //               name: 'modal_title_key',
              //               element: 'div',
              //               label: 'Recipient List',
              //               className:
              //                 'font-semibold text-lg text-primary',
              //             },
              //             {
              //               name: 'modal_close',
              //               element: 'button',
              //               label: 'X',
              //               className:
              //                 'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
              //             },
              //           ],
              //         },
              //         {
              //           name: 'modal_header',
              //           className:
              //             'w-full shadow-sm p-2 px-4',
              //           fields: [],
              //         },
              //       ],
              //     },
              //   ],
              // },
              // {
              //   className:
              //     'fixed inset-0 flex items-center justify-center z-50 blur-background transition-all duration-1000 ease-in-out',
              //   name: 'view_modal_container',
              //   visible: false,
              //   fields: [
              //     {
              //       name: 'table_modal',
              //       className:
              //         'flex w-[70%] border shadow-lg rounded-lg flex-col bg-white overflow-hidden',
              //       fields: [
              //         {
              //           name: 'modal_title',
              //           className:
              //             'w-full bg-[#F2F2F2] p-2 pl-4 flex items-center justify-between',
              //           fields: [
              //             {
              //               name: 'modal_title_key',
              //               element: 'div',
              //               label: 'Campaign details',
              //               className:
              //                 'font-semibold text-lg text-primary',
              //             },
              //             {
              //               name: 'modal_close',
              //               element: 'button',
              //               label: 'X',
              //               className:
              //                 'w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary text-xl text-white',
              //             },
              //           ],
              //         },
              //         {
              //           name: 'modal_header',
              //           className:
              //             'grid grid-cols-3 gap-2 shadow-sm p-2 px-4',
              //           cols: [
              //             {
              //               name: 'campaign_name',
              //               label: 'Campaign Name',
              //             },
              //             {
              //               name: "hospital_branch_name",
              //               label: "Branch"
              //             },
              //             // {
              //             //   name: 'approval_status',
              //             //   label: 'Status',
              //             // },
              //             {
              //               name: 'source',
              //               label: 'Source',
              //             },
              //             {
              //               name: 'count_sent',
              //               label: 'Sent Count',
              //             },
              //             {
              //               name: 'reach',
              //               label: 'Delivered Count',
              //             },
              //             {
              //               name: 'link_clicks',
              //               label: 'Read Count',
              //             }
              //           ],
              //           fields: [],
              //         },
              //         {
              //           name: 'modal_body',
              //           className:
              //             'flex flex-col max-h-[400px] p-4 overflow-auto gap-2',
              //           fields: [],
              //         },
              //       ],
              //     },
              //   ],
              // },
            ],
          },
        ],
      },
    ],
  });
  const [dateRange, setDateRange] = useState<[string, string]>([moment().subtract(7, 'days').format('DD-MM-YYYY'), moment().format("DD-MM-YYYY")]);
  const [searchText, setSearchText] = useState("");
  // const [tabs, setTabs] = useState("whatsapp");

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const org_id = Storage.getItem("details")?.["org_id"];

  const pagePermissions = useSelector(getPagePermissions)

  useEffect(() => {
    if (location.state?.source === "Campaigns") {
    dispatch(setTopbar(location.state?.source))
    dispatch(setActiveMenu("/campaigns"))
    }
  }, [location.state?.source]);

  // useEffect(() => {
  //   console.log(searchText)
  //   API_GET_Data({ source: activeTab, page: 1, limit: 10, startDate: dateRange[0], endDate: dateRange[1] });
  // }, []);

  const API_GET_Data = async ({
    source = "",
    page = 1,
    limit = 10,
    startDate = "",
    endDate = "",
    searctext = ""
  } = {}) => {
    handleLoading(true);
    try {
      const res = await Utils.makeApiCall(`/campaign?org_id=${org_id}&source=${source}&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&search=${searctext}`, "GET");

      if (res.status) {
        //toast.success("Campaigns data is fetched successfully",{ position: "top-center", autoClose: 2000 })
        const add = pagePermissions.find((r:any) => r.route === location.pathname)?.add || false;
        const view = pagePermissions.find((r:any) => r.route === location.pathname)?.view || false;
        const download = pagePermissions.find((r:any) => r.route === location.pathname)?.download || false;
        setSchema((prev: any) => {
          prev.schema.map((f: any) => {
            if (f.name === "add_form") {
              f.fields.map((ff: any) => {
                ff.fields.map((fff: any) => {
                  if (fff.element === "table") {
                    fff.count = res.total;
                    fff["tbody"] = res.data.map((acc: any) => {
                      
                      const createdAt = acc["createdAt"]
                        ? moment(acc["createdAt"]).format("DD-MM-YYYY HH:mm")
                        : "";
                      return { ...acc, createdAt };
                    });
                    const hasactions = fff["thead"].some((ef:any)=>ef.name === "actions");
                    if (view && !hasactions){
                      // fff['thead'].push({
                      //   name: 'actions',
                      //   label: '',
                      //   key: 'actions',
                      //   sortable:false,
                      //   render: (e: any, record: any) => {
                      //     return (
                      //       <div
                      //         onKeyDown={() =>
                      //           handleSelect({ name: "view_record", value: record })
                      //         }
                      //         tabIndex={0}
                      //         role="button"
                      //         onClick={() =>
                      //           handleSelect({ name: "view_record", value: record })
                      //         }
                      //       >
                      //         <Tooltip title="View">
                      //         <img
                      //           src={
                      //             `${BASE_URL}/api/public/assets/images/eye.svg`
                      //           }
                      //           alt="View the record"
                      //         />
                      //          </Tooltip>
                      //       </div>
                      //     );
                      //   },
                      // })
                    }
                  } else if (fff.name === "filters") {
                    fff.fields.map((sf:any)=>{
                      if (sf.name === "download_and_Add"){
                        sf.fields.map((sff:any)=>{
                          if (sff.name === "btn_add"){
                            sff.visible = add
                          } else if (sff.name === "btn_download"){
                            sff.visible = download
                          }
                        })
                      }
                    })
                  }
                  return fff;
                });
                return ff;
              });
            }
            if (f.element === "tabs"){
              f["value"] = source
            }
            return f;
          });
          return { ...prev };
        });
      } else {
        toast.error(res.message ? res.message : FAILURE_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
      }
    } catch (error) {
      toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
      console.error("Failed to fetch schema", error);
    }
    handleLoading(false);
  };

  const API_GET_Details = async (cid: any, record: any) => {
    try {
      const res = await Utils.makeApiCall(
        `/campaign/${cid}/${org_id}`,
        'GET'
      )
      // console.log(res.data);

      if (res.status) {
        handleModaldata({
          headerData: res.data || {},
          visible: true,
          modal: "view_record"
        })
      } else {
        toast.error(res.message, {
          position: 'top-center',
          autoClose: 2000,
        })
      }
    } catch (error) {
      console.error('Failed to fetch schema', error)
    }
  }

  const handleLoading = (val: boolean) => {
    setSchema((prev: any) => {
      const ps = prev.schema;
      ps.map((f: any) => {
        if (f.name === "add_form") {
          f.fields.map((ff: any) => {
            ff.fields.map((fff: any) => {
              if (fff.element === "table") {
                fff["loading"] = val;
              }
              return fff;
            });
            return ff;
          });
        }
        return f;
      });
      return { ...prev, schema: ps };
    });
  };
  // console.log(schema);


  useEffect(() => {

    const id = setTimeout(() => {
      API_GET_Data({ source: activeTab, startDate: dateRange[0], endDate: dateRange[1], searctext: searchText });
    }, 500);

    return () => {
      clearTimeout(id);
    }
  }, [searchText])

  useEffect(() => {
      API_GET_Data({ source: activeTab, startDate: dateRange[0], endDate: dateRange[1], searctext: searchText });
      if(dateRange[0]==="" && dateRange[1]===""){
        change_segments_value(null);
        return;
      }
      const first = moment(dateRange[0], 'DD-MM-YYYY');
      const second = moment(dateRange[1], 'DD-MM-YYYY');
      const diffInDays = second.diff(first, 'days');
      const today = moment().startOf('day');

      if (first.isSame(today, 'day') && second.isSame(today, 'day')) {
          change_segments_value("TD")
      } else if (diffInDays === 7 && second.isSame(today, 'day')) {
          change_segments_value("TW")
      } else if (diffInDays === 30 && second.isSame(today, 'day')) {
          change_segments_value("TM")
      }else{
          change_segments_value(null)
      }
  }, [dateRange])

  const change_segments_value = (val: string | null) => {
    setSchema((prev: any) => {
      const updatedSchema = prev.schema.map((field: any) => {
        if (field.name === "add_form") {
          field.fields = field.fields.map((ff: any) => {
            if (ff.name === "add_form_sub") {
              ff.fields = ff.fields.map((fff: any) => {
                if (fff.name === "filters") {
                  fff.fields = fff.fields.map((ffff: any) => {
                    if (ffff.name === "date_and_search") {
                      ffff.fields = ffff.fields.map((fffff: any) => {
                        if (fffff.name === "segments") {
                          return { ...fffff, value: val };
                        }
                        return fffff;
                      });
                    }
                    return ffff;
                  });
                }
                return fff;
              });
            }
            return ff;
          });
        }
        return field;
      });
      return { ...prev, schema: updatedSchema };
    });
  };

  const downloadToExcel = async () => {
    try {
      const tableSchema = schema.schema[1].fields[0].fields.find((s: any) => s.element === "table");
      const { thead } = tableSchema;
      dispatch(setLoading(true))

      const response = await Utils.makeApiCall(`/campaign?org_id=${org_id}&source=${activeTab}&page=1&limit=10&startDate=${dateRange[0]}&endDate=${dateRange[1]}&download=true&search=${searchText}`, "GET");
      const data = response.data;
      dispatch(setLoading(false))

      if (!data || data.length === 0) {
        toast.error("No data available to download", { position: "top-center", autoClose: AUTO_CLOSE });
        return;
      }

      Utils.exportToExcel(thead, data, `${activeTab}_campaigns`);
    } catch (error) {
      console.error("Error downloading Excel:", error);
      toast.error("Failed to download data as Excel", { position: "top-center", autoClose: AUTO_CLOSE });
    }
  };

  const handleModaldata = ({
    visible = true,
    headerData,
    modal,
  }: any) => {
    setSchema((prev: any) => {
      const ps = prev.schema
      ps.map((p: any) => {
        if (p.name === "add_form"){
          p.fields.map((pp:any)=>{
            if (pp.name === "add_form_sub"){
              pp.fields.map((s:any)=> {
                if (["view_modal_container"].includes(s.name)) {
                  s['visible'] = visible
                  if (s?.fields?.length) {
                    s.fields.map((f: any) => {
                      if (f.name === 'table_modal') {
                        if (f?.fields?.length) {
                          f.fields.map((ff: any) => {
                            if (ff.name === 'modal_header') {
                              if (modal === "view_record"){
                                if (ff?.cols?.length) {
                                  ff['fields'] = ff.cols.map(
                                    (item: Item) => {
                                      return {
                                        name: item.name,
                                        className:
                                          'flex justify-start gap-2',
                                        fields: [
                                          {
                                            name: `${item.name}_key`,
                                            element:
                                              'div',
                                            label: item.label,
                                            className:
                                              'font-thin',
                                          },
                                          {
                                            name: `${item.name}_val`,
                                            element:
                                              'div',
                                            label: `${headerData ? headerData[item.name] || '--' : '--'}`,
                                            className:
                                              'font-semibold',
                                          },
                                        ],
                                      }
                                    }
                                  )
                                }
                              } else {
                                ff['fields'] = [];
                              }
                            }
                            if (ff.name === 'modal_body') {
                              if (modal === "view_record"){
                                ff['fields'] = [
                                  {
                                    name: "rp_list",
                                    className:
                                      'flex flex-col justify-start gap-2',
                                    fields: [
                                      {
                                        name: `rp_list_key`,
                                        element:
                                          'div',
                                        label: "Recipient Group",
                                        className: 'font-medium p-1 px-2 bg-gray-100 rounded-md',
                                      },
                                      {
                                        name: `rp_list_val`,
                                        className: `grid grid-cols-3 gap-1 ml-2`,
                                        fields: headerData?.["recipients_list"] ? headerData["recipients_list"].map((r:any, ind:number)=>{
                                          return {
                                            name: r.id,
                                            className: "w-full",
                                            fields: [
                                              {
                                                name: `${r.id}_key`,
                                                element:
                                                  'div',
                                                label: `${ind+1}. ${r ? r["patient_name"] || '--' : '--'} (${r ? r["phone_number"] || '--' : '--'})`,
                                                className: 'text-sm',
                                              }
                                            ]
                                          }
                                        }) : []
                                      },
                                    ],
                                  },
                                  {
                                    name: "rglist",
                                    className:
                                      'flex flex-col justify-start gap-2',
                                    fields: [
                                      {
                                        name: `rglist_key`,
                                        element:
                                          'div',
                                        label: "Schedules",
                                        className: 'font-medium p-1 px-2 bg-gray-100 rounded-md',
                                      },
                                      {
                                        name: `rglist_val`,
                                        className: 'grid grid-cols-3 gap-2 ml-2',
                                        fields: headerData?.["schedules"] ? headerData["schedules"].map((r:any)=>{
                                          return {
                                            name: r.template_id,
                                            className: "w-full border p-2 rounded-md",
                                            fields: [
                                              {
                                                name: `${r.template_id}_key`,
                                                element:
                                                  'div',
                                                label: `${r ? r["template_name"] || '--' : '--'}`,
                                                className: 'font-medium',
                                              },
                                              {
                                                name: `${r.template_id}_val`,
                                                element:
                                                  'div',
                                                label: `${r ? `@${moment(r["scheduled_at"]).format("DD-MM-YYYY HH:mm")}` || '--' : '--'}`,
                                                className: 'text-sm',
                                              },
                                            ]
                                          }
                                        }) : []
                                      },
                                    ],
                                  },
                                ]
                              }
                            }
                            return ff
                          })
                        }
                        return f
                      }
                      return f
                    })
                  }
                }
                return s
              })
            }
            return pp;
          })
        }
        return p;
      })
      return { ...prev, schema: ps }
    })
  }

  const handleSelect = (e: any) => {

    if (e.name === "btn_add") {
      navigate("/campaigns/add");
    }
    if (e.name === "tabs") {

      API_GET_Data({ source: e.value.key, page: 1, limit: 10, startDate: dateRange[0], endDate: dateRange[1], searctext: searchText });
      // setTabs(e.value.key);
      dispatch(setActiveTab(e.value.key));

    }
    if (e.name === "search") {
      setSchema((prev: any) => {
        const updatedSchema = prev.schema.map((field: any) => {
          if (field.name === "add_form") {
            return {
              ...field,
              fields: field.fields.map((ff: any) => {
                if (ff.name === "add_form_sub") {
                  return {
                    ...ff,
                    fields: ff.fields.map((fff: any) => {
                      if (fff.name === "campaigns_table") {
                        return {
                          ...fff,
                          value: { name: 'pagination', page: 1, pageSize: 10 }
                        };
                      }
                      
                      return fff;
                    })
                  };
                }
                return ff;
              })
            };
          }
          return field;
        });
        return { ...prev, schema: updatedSchema };
      });
      setSearchText(e.value);

    }
    if (e.name === "btn_download") {
      downloadToExcel();
    }
    if (e.name === "date") {
      if (e.value === "") {

        setDateRange(["", ""]);
        setSchema((prev: any) => {
          const updatedSchema = prev.schema.map((field: any) => {
            if (field.name === "add_form") {
              return {
                ...field,
                fields: field.fields.map((ff: any) => {
                  if (ff.name === "add_form_sub") {
                    return {
                      ...ff,
                      fields: ff.fields.map((fff: any) => {
                        if (fff.name === "campaigns_table") {
                          return {
                            ...fff,
                            value: { name: 'pagination', page: 1, pageSize: 10 }
                          };
                        }
                        
                        return fff;
                      })
                    };
                  }
                  return ff;
                })
              };
            }
            return field;
          });
          return { ...prev, schema: updatedSchema };
        });
      } else {
        setSchema((prev: any) => {
          const updatedSchema = prev.schema.map((field: any) => {
            if (field.name === "add_form") {
              return {
                ...field,
                fields: field.fields.map((ff: any) => {
                  if (ff.name === "add_form_sub") {
                    return {
                      ...ff,
                      fields: ff.fields.map((fff: any) => {
                        if (fff.name === "campaigns_table") {
                          return {
                            ...fff,
                            value: { name: 'pagination', page: 1, pageSize: 10 }
                          };
                        }
                        
                        return fff;
                      })
                    };
                  }
                  return ff;
                })
              };
            }
            return field;
          });
          return { ...prev, schema: updatedSchema };
        });
        setDateRange(e.value);
      }
    }
    if (e.name === "segments") {
      if (e.value === "TD") {
        setDateRange([
          moment().format('DD-MM-YYYY'),
          moment().format('DD-MM-YYYY'),
        ]);
        API_GET_Data({
          startDate: moment().format('DD-MM-YYYY'),
          endDate: moment().format('DD-MM-YYYY'),
          searctext: searchText,
          page: 1,
          limit: 10,
        });
        setSchema((prev: any) => {
          const updatedSchema = prev.schema.map((field: any) => {
            if (field.name === "add_form") {
              field.fields.map((ff: any) => {
                if (ff.name === "add_form_sub") {
                  ff.fields.map((fff: any) => {
                    if (fff.name === "campaigns_table") {
                      fff.value = { name: 'pagination', page: 1, pageSize: 10 };
                    }
                    if (fff.name === "filters") {
                      fff.fields.map((ffff: any) => {
                        if (ffff.name === "date_and_search") {
                          ffff.fields.map((fffff: any) => {
                            if (fffff.name === "date") {
                              fffff.value = [
                                moment().format('DD-MM-YYYY'),
                                moment().format('DD-MM-YYYY'),
                              ];
                            }
                            return fffff;
                          });
                        }
                        return ffff;
                      });
                    }
                    return fff;
                  });
                }
                return ff;
              });
            }
            return field;
          });
          return { ...prev, schema: updatedSchema };
        });
      }
      if (e.value === "TM") {
        setDateRange([
          moment().subtract(30, 'days').format('DD-MM-YYYY'),
          moment().format('DD-MM-YYYY'),
        ]);
        API_GET_Data({
          startDate: moment().subtract(30, 'days').format('DD-MM-YYYY'),
          endDate: moment().format('DD-MM-YYYY'),
          searctext: searchText,
          page: 1,
          limit: 10,
        });
        setSchema((prev: any) => {
          const updatedSchema = prev.schema.map((field: any) => {
            if (field.name === "add_form") {
              field.fields.map((ff: any) => {
                if (ff.name === "add_form_sub") {
                  ff.fields.map((fff: any) => {
                    if (fff.name === "campaigns_table") {
                      fff.value = { name: 'pagination', page: 1, pageSize: 10 };
                    }
                    if (fff.name === "filters") {
                      fff.fields.map((ffff: any) => {
                        if (ffff.name === "date_and_search") {
                          ffff.fields.map((fffff: any) => {
                            if (fffff.name === "date") {
                              fffff.value = [
                                moment().subtract(30, 'days').format('DD-MM-YYYY'),
                                moment().format('DD-MM-YYYY'),
                              ];
                            }
                            return fffff;
                          });
                        }
                        return ffff;
                      });
                    }
                    return fff;
                  });
                }
                return ff;
              });
            }
            return field;
          });
          return { ...prev, schema: updatedSchema };
        });
      }
      if (e.value === "TW") {
        setDateRange([
          moment().subtract(7, 'days').format('DD-MM-YYYY'),
          moment().format('DD-MM-YYYY'),
        ]);
        API_GET_Data({
          startDate: moment().subtract(7, 'days').format('DD-MM-YYYY'),
          endDate: moment().format('DD-MM-YYYY'),
          searctext: searchText,
          page: 1,
          limit: 10,
        });
        setSchema((prev: any) => {
          const updatedSchema = prev.schema.map((field: any) => {
            if (field.name === "add_form") {
              field.fields.map((ff: any) => {
                if (ff.name === "add_form_sub") {
                  ff.fields.map((fff: any) => {
                    if (fff.name === "campaigns_table") {
                      fff.value = { name: 'pagination', page: 1, pageSize: 10 };
                    }
                    if (fff.name === "filters") {
                      fff.fields.map((ffff: any) => {
                        if (ffff.name === "date_and_search") {
                          ffff.fields.map((fffff: any) => {
                            if (fffff.name === "date") {
                              fffff.value = [
                                moment().subtract(7, 'days').format('DD-MM-YYYY'),
                                moment().format('DD-MM-YYYY'),
                              ];
                            }
                            return fffff;
                          });
                        }
                        return ffff;
                      });
                    }
                    return fff;
                  });
                }
                return ff;
              });
            }
            return field;
          });
          return { ...prev, schema: updatedSchema };
        });
      }
    }
    if (e.name === "campaigns_table") {
      if (e.value?.name === "pagination") {
        const { page, pageSize } = e.value;
        API_GET_Data({
          source: activeTab,
          page: page,
          limit: pageSize,
          startDate: dateRange[0],
          endDate: dateRange[1],
          searctext: searchText
        });
      }
      if (e?.["value"]?.name === "view") {
        const id =  e["value"]?.["value"]?.["_id"] || e["value"]?.["_id"];
        navigate(`/campaigns/view/${id}`)
      }
    }
    if (["view_count"].includes(e.name)) {
      handleModaldata({ headerData: e["value"], visible: true, modal: e.name })
    }
    if (["view_record"].includes(e.name)) {
      navigate(`/campaigns/view/${e["value"]?.["_id"]}`)
      // API_GET_Details(e["value"]?.["_id"], e["value"])
    }
    if (e.name == 'modal_close') {
      handleModaldata({ headerData: {}, visible: false })
    }

    if(e.name === "open_recipients_table_modal"){
      // console.log(e.value);
    }

    if( e.name === "recipients_table_modal_close"){
      handleRecipientsTableModal("", "", "", false)
    }

  };


  const handleRecipientsTableModal = async(tempId: any, cid: any, schedule_id: any, visible: boolean) =>{
    if(visible){
      dispatch(setLoading(true))
        try{
            const res = await Utils.makeApiCall(
                `/recipients/${cid}/${tempId}/${schedule_id}`,
                'GET'
            )   
            if (res.status) {
                let data = res.data;
               
                data = data?.recipients
                setSchema((prev: any)=>{
                    prev?.schema?.map((f:any)=>{
                        if(f?.name === "recipients_table_modal"){
                            f.visible = visible || false 
        
                            if(f?.fields?.length){
                                f?.fields.map((ff: any)=>{
                                    if(ff?.name === "modal_body"){
                                        if(ff?.fields?.length){
                                            ff?.fields.map((fff: any)=>{
                                               if(fff?.name === "recipients_table"){
                                                fff['tbody'] = data?.map((acc: any)=>{
                                                  return { ...acc}; 
                                                })
                                               } 
                                            })
                                        }
                                    }
                                })
                            }
                        }
        
                    })
        
                    return {...prev}
                })
            }
        } catch(err){
            console.error(err);
        }
        dispatch(setLoading(false))

    }else{
        setSchema((prev: any)=>{
            prev?.schema?.map((f:any)=>{
                if(f?.name === "recipients_table_modal"){
                    f.visible = visible || false 
                }

            })

            return {...prev}
        })
    }
   

    
}

  return (
    <div>
      <ElementExecutor
        data={schema}
        selectedRecord={(e: any) => {
          handleSelect(e);
        }}
      />
    </div>
  );
};

export default Campaigns;
