import { ElementExecutor } from "@apexcura/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Utils from ".././../utils";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { setActiveTab } from "../../redux/campaigns/campaignSlice";
import Storage from "../../utils/local-storage";
import useExitPopUp from "../../hooks/useExitPopUp";
import { CONSTANTS } from "../../utils/app-constants";
import { setLoading } from "../../redux/appState/appStateSlice";
const { BASE_URL } = CONSTANTS
const addFilter =() => {
  const branch_id = Storage.getItem("details")?.["branch_id"]
  return {
  name: "receipient_filter_1",
  className: "grid grid-cols-9 gap-8 pb-6",
  fields: [
    // {
    //   name: "filter_type_1",
    //   label: "Filter By",
    //   element: "single-select",
    //   value: "",
    //   className: "",
    //   options: [
    //     { label: "Patient", value: "by_patient" },
    //     { label: "Doctor", value: "by_doctor" },
    //     { label: "Department", value: "by_department" },
    //   ],
    //   required: true,
    //   labelClassName: "text-sm",
    //   containerClassName: "flex flex-col"
    // },
    {
      name: 'patient_filters',
      className: "col-span-3 w-full flex flex-col gap-3",
      fields: [
        {
          name: "filter_display_type_1",
          label: "Patient",
          element: "single-checkbox",
          checked: true,
          value: true,
          className: "text-lg font-medium pb-2",
        },
        {
          name: "patient_filters_container_1",
          visible: true,
          visible_for: "filter_display_type_1",
          className: "grid grid-cols-2 gap-x-3 gap-y-6",
          fields: [
            {
              name: "age_1",
              label: "Age (From - To)",
              variant: "borderless",
              element: "dropdown-group",
              firstName: "age_min",
              secondName: "age_max",
              placeholder: "From",
              parent: "filter_display_type_1",
              firstEndpoint: "/dummyData/age",
              secondEndpoint: "/dummyData/age",
              firstOptions: [],
              secondOptions: [],
              value: "",
              className: "w-full",
              subContainerClassName: "flex border rounded-lg",
              labelClassName: "text-sm pb-1",
              containerClassName: "",
              required: true,
            },
            {
              name: "gender_1",
              label: "Gender",
              element: "single-select",
              endpoint: "/dummyData/gender",
              options: [],
              value: [],
              className: "",
              parent: "filter_display_type_1",
              containerClassName: "flex flex-col",
              required: true,
              labelClassName: "text-sm pb-1",
            },
            {
              name: "condition_1",
              label: "Medical Condition",
              element: "single-select",
              mode: "multiple",
              value: [],
              parent: "filter_display_type_1",
              className: "",
              endpoint: "/dummyData/treatment_type",
              options: [],
              containerClassName: "flex flex-col col-span-2",
              labelClassName: "text-sm pb-1",
            },

          ]
        }
      ]
    },
    {
      name: "doctor_filters_container",
      className: "col-span-2 flex flex-col gap-3",
      fields:[
        {
          name: "filter_display_type_2",
          label: "Doctor",
          element: "single-checkbox",
          className: "text-lg font-medium pb-2",
        },
        {
          name: "doctors_2",
          label: "Doctors",
          element: "single-select",
          visible: false,
          visible_for: "filter_display_type_2",
          parent: "filter_display_type_2",
          mode: "multiple",
          value: [],
          className: "",
          endpoint: `/allDoctors?branchId=${branch_id}`,
          options: [],
          containerClassName: "flex flex-col col-span-4",
          labelClassName: "text-sm pb-1",
          required: true,
        },
      ]
    },
    {
      name: "department_filters_container",
      className: "col-span-2 flex flex-col gap-3",
      fields:[
        {
          name: "filter_display_type_3",
          label: "Department",
          element: "single-checkbox",
          value: '',
          className: "text-lg font-medium pb-2",
        },
        {
          name: "dept_3",
          label: "Departments",
          element: "single-select",
          visible: false,
          visible_for: "filter_display_type_3",
          parent: "filter_display_type_3",
          mode: "multiple",
          value: [],
          className: "",
          endpoint: `/departmentsDropdown?branch_id=${branch_id}`,
          options: [],
          containerClassName: "flex flex-col col-span-4",
          labelClassName: "text-sm pb-1",
          required: true,
        },
      ]
    },
    {
      name: "contactlist_filters_container",
      className: "col-span-2 flex flex-col gap-3",
      fields:[
        {
          name: "filter_display_type_4",
          label: "Contacts List",
          element: "single-checkbox",
          value: '',
          className: "text-lg font-medium pb-2",
        },
        {
          name: "contact_list_4",
          label: "Contacts List",
          element: "single-select",
          visible: false,
          visible_for: "filter_display_type_4",
          parent: "filter_display_type_4",
          value: [],
          className: "",
          endpoint: "/contactList?list_type=options",
          options: [],
          containerClassName: "flex flex-col col-span-4",
          labelClassName: "text-sm pb-1",
          required: true,
        },
      ]
    },
    
    
    
    // {
    //   name: "actions_1",
    //   className: "flex gap-2 items-end",
    //   fields: [
    //     // {
    //     //   name: "operator_1",
    //     //   label: "And/Or",
    //     //   element: "single-select",
    //     //   value: "",
    //     //   className: "",
    //     //   options: [
    //     //     { label: "And", value: "and" },
    //     //     { label: "Or", value: "or" },
    //     //   ],
    //     //   labelClassName: "text-sm",
    //     //   containerClassName: "flex flex-col col-span-2",
    //     // },
    //     // {
    //     //   name: "add_1",
    //     //   label: "+ Add",
    //     //   element: "button",
    //     //   visible: true,
    //     //   className: "add-button"
    //     // },
    //     {
    //       name: "remove_filter_1",
    //       label: "X",
    //       element: "button",
    //       visible: false,
    //       className: "p-2 px-3 font-medium text-xs rounded-md bg-red-100 text-red-500 hover:bg-red-200"
    //     },
    //   ]
    // },
  ],
}
}
const addTemplate = () => {
  return ({
    name: "template_card_1",
    className: "flex relative flex-col items-center gap-2 border p-4 bg-[#F8F8FF] rounded-md",
    fields: [
      {
        name: "remove_template_1",
        label: "X",
        element: "button",
        visible: false,
        className: "absolute top-1 right-1 p-1 px-2 font-medium text-xs rounded-md bg-[#afb7ba] hover:bg-[#5F6C72] text-[#fff]"
      },
      {
        name: "template_name",
        label: "Schedule 1",
        element: "div",
        className: "text-sm text-center text-[#2E3BAA] bg-[#ECECFF] py-1 px-4 rounded-md w-fit font-medium text-gray-700",
      },
      {
        name: 'template_input_container',
        label: '',
        className: 'grid grid-cols-2 gap-3',
        fields: [
          {
            name: "template_date_1",
            label: "Schedule Time",
            element: "datetimepicker",
            isDateTime:true,
            enabled_dates: "from_today",
            visible: true,
            required: true,
            value: "",
            className: "w-full",
            labelClassName: "text-sm pb-1"
          },
          {
            name: "template_id_1",
            label: "Template",
            element: "single-select",
            endpoint: "/templates?status=APPROVED",
            options: [],
            visible: true,
            required: true,
            value: "",
            containerClassName: "flex flex-col",
            labelClassName: "text-sm pb-1",
            className: "",
          },
        ]
      },
      
      {
        name: "template_preview_1",
        visible: true,
        className: "w-full h-[25vh] overflow-y-scroll border p-2 bg-gray-100 flex flex-col gap-3 border rounded-md",
        fields: [
          {
            name: "template_title_1",
            element: "div",
            label: "",
            className: "font-semibold"
          },
          {
            name: "template_body_1",
            element: "div",
            label: "",
            className: "text-sm"
          },
          {
            name: "template_footer_1",
            element: "div",
            label: "",
            className: "text-xs text-gray-400"
          },
        ],
      }
    ]
  })
}
const commonSchema = () => ([
  {
    name: "name_branch_details",
    label: "",
    className: "border-b border-b-[1.5px] col-span-full grid grid-cols-12 items-center p-4 pb-8",
    fields: [
      {
        name: "campaign_details",
        label: "Campaign Details",
        element: "div",
        className: " pr-2 font-medium text-xl rounded-lg col-span-3"
      },
      {
        name: "campaign_details_form",
        className: "w-full grid grid-cols-2  items-center gap-8 col-span-9 ",
        fields: [
          {
            name: "campaign_name",
            label: "Title:",
            element: "input-text",
            value: "",
            placeholder: "Eg: Child vaccination",
            className: "w-full",
            containerClassName: "col-span-1 flex items-center gap-3 ",
            labelClassName: "text-sm",
            required: true,
          },
          // {
          //   name: "hospital_branch",
          //   label: "Hospital Branch",
          //   element: "single-select",
          //   endpoint: "/dummyData/hospital_branch",
          //   options: [],
          //   visible: true,
          //   required: true,
          //   value: "",
          //   containerClassName: "col-span-1 flex items-center gap-3",
          //   labelClassName: "text-sm",
          //   className: "w-[60%]",
          // },
        ]
      },
    ],
  },
  {
    name: "receipient_group",
    className: "grid grid-cols-4 p-4 gap-4 border-b-[1.5px]",
    fields: [
      {
        name: "campaign_details",
        label: "",
        className: "col-span-full flex items-center gap-6",
        fields: [
          {
            name: "campaign_details",
            label: "Receipient Group",
            element: "div",
            className: "font-medium text-xl",
          },
          {
            name: "campaign_filter_count_div",
            label: "",
            className: " flex items-center gap-2 w-fit px-3 py-2 rounded-md bg-[#EBF5FF] ",
            fields:[
              {
                name: "campaign_filter_count",
                label: "0",
                element: "div",
                className: "text-[#3341BB] font-medium",
              },
              {
                name: "campaign_filter_count_div",
                label: "recipients found",
                element: "div",
                className: "",
              },
            ]
          },
        ]
      },
      // {
      //   name: "frequency",
      //   label: "Frequency",
      //   element: "single-select",
      //   endpoint: "/staticData/campaignDateRanges",
      //   visible: true,
      //   options: [],
      //   value: [],
      //   className: "",
      //   containerClassName: "flex flex-col self-start",
      //   required: true,
      //   labelClassName: "text-sm",
      // },
      {
        name: "filters",
        className:
          // "border-r-4 col-span-2 pr-4 flex flex-col gap-2 border-[#F2F2F2]",
          "col-span-full",
        fields: [
          addFilter()
        ],
      },
      // {
      //     name: "add_filter",
      //     label: "Add",
      //     icon: "add",
      //     element: "button",
      //     visible: true,
      //     className: "w-fit add-button self-start",
      //     iconsClassName: "size-4",
      // },
    ],
  },
]);
const smsSchema = () => ({
  name: "sms_schema",
  className: "p-6 flex flex-col gap-2  bg-[#fff]",
  fields: [
    // {
    //   name: "heading",
    //   label: "New SMS Campaign",
    //   element: "div",
    //   className: "w-full col-span-full text-lg font-semibold",
    // },
    ...commonSchema(),
    {
      name: "preview_section",
      label: "",
      fields: [
        {
          name: "sms_text",
          label: "SMS Text",
          element: "input-textarea",
          value: "",
          className: "mb-4 h-[30vh]",
          labelClassName: "text-sm",
          required: true,
        },
        {
          name: "btns",
          className: "flex justify-end gap-2",
          fields: [
            {
              name: "btn_discard",
              label: "Discard",
              element: "button",
              icon: "discard",
              className:
                "secondary-button",
              action: "discard",
              iconsClassName: "size-5",
            },
            // {
            //   name: "btn_save",
            //   label: "Save",
            //   element: "button",
            //   icon: "save",
            //   className:
            //     "secondary-button",
            //   action: "save",
            //   iconsClassName: "size-4",
            // },
            {
              name: "btn_send",
              label: "Send SMS",
              element: "button",
              className:
                "add-button",
              action: "send_sms",
            },
          ],
        },
      ],
      className: "bg-white col-span-3 flex flex-col gap-2 p-4 rounded-lg",
    },
  ],
});
const whatsappSchema = () => ({
  name: "whatsapp_schema",
  className: "p-6 py-4 flex flex-col gap-4 bg-[#fff]",
  fields: [
    // {
    //   name: "heading",
    //   label: "Whatsapp Campaign",
    //   element: "div",
    //   className: "w-full text-lg font-semibold",
    // },
    ...commonSchema(),
    {
      name: "templates_form",
      fields: [
        {
          name: "schedules_head",
          className: " flex items-start justify-between",
          fields: [
            {
              name: "campaign_details",
              label: "Schedulers",
              element: "div",
              className: "font-medium text-xl rounded-lg col-span-3"
            },
            {
              name: "add_schedule",
              label: "Add",
              icon: "add",
              element: "button",
              visible: true,
              className: "add-button self-start",
              iconsClassName: "size-4",
            },
          ]

        },
        
        {
          name: "templates_container",
          className: "grid grid-cols-2 gap-4 pr-4 max-h-[60vh] overflow-y-scroll",
          fields: [
            addTemplate()
          ]
        },

        {
          name: "campaign_budget_preview",
          label: "",
          className: "self-end flex items-center gap-2 w-fit px-3 py-2 mt-4 rounded-md bg-[#EBF5FF] ",
          fields:[
            {
              name: "campaign_budget_label",
              label: "Estimated Budget: ",
              element: "div",
              className: "",
            },
            {
              name: "campaign_budget_value",
              label: "₹0.00",
              element: "div",
              className: "text-[#3341BB] font-medium",
            },
            
          ]
        },
        
        {
          name: "btns",
          className: "flex justify-end gap-2 mt-4",
          fields: [
            {
              name: "btn_discard",
              label: "Discard",
              element: "button",
              icon: "discard",
              className:
                "secondary-button",
              action: "discard",
              iconsClassName: "size-5",
            },
            {
              name: "btn_send",
              label: "Send Whatsapp",
              element: "button",
              className:
                "add-button",
              action: "send_sms",
            },
          ],
        },
      ],
      className: "bg-white p-4 rounded-lg flex flex-col gap-2 relative",
    },
  ],
});
const AddCampaign = () => {
  const activeTab = useSelector((state: RootState)=> state.campaign.activeTab);

  const [schema, setSchema] = useState<any>({
    className: "bg-white rounded-lg",
    schema: [],
  });
  const [filtersList, setFiltersList] = useState<any>([]);  
  const [contactList, setContactList] = useState<any[]>([]);
  const navigate = useNavigate();
  const [data1, setData1] = useState<any>();
  const [file, setFile] = useState<any>();
  
  const dispatch = useDispatch();
  const org_id = Storage.getItem("details")?.["org_id"];
  const branch_id = Storage.getItem("details")?.["branch_id"]
  
  const schemas:any = {
    whatsapp: [{...whatsappSchema()}],
    sms: [{...smsSchema()}]
  }
  useEffect(() => {
    dispatch(setLoading(true))
    const schemaArr = [
      {
        name: "tabs",
        element: "tabs",
        containerClassName: "p-0 m-0 px-4",
        className: "flex items-center gap-1",
        options: [
          {
            key: "whatsapp",
            label: "WhatsApp",
            icon: `${BASE_URL}/api/public/assets/images/whatsapp-white-border.svg`,
          },
          // {
          //   key: "sms",
          //   label: "SMS",
          //   icon: `${BASE_URL}/api/public/assets/images/sms.svg`
          // },
        ],
        value: activeTab
      },
      {
        name: "add_form",
        fields: schemas[activeTab] || [],
      },
    ]
    const processFields = (obj: any) => {
      if (Array.isArray(obj)) {
        obj.forEach(processFields);
      } else if (obj && typeof obj === 'object') {
        if (obj.endpoint || obj.firstEndpoint) {
          dispatch(setLoading(true))
          API_GET_Options({ name: obj.name, url: obj.endpoint || obj.firstEndpoint });
        }
        Object.values(obj).forEach(processFields);
      }
    };
    schemaArr.forEach(processFields);
    setSchema((prev: any) => {
      return { ...prev, schema: schemaArr }
    })
    dispatch(setLoading(false))
  }, [])

  const API_GET_Options = async ({ name = "", url = "" }) => {
    // console.log(name, url);
    try {
      const res = await Utils.makeApiCall(url, "GET");
      if (res.status) {
        setSchema((prev: any) => {
          const updateFields: any = (obj: any) => {
            if (Array.isArray(obj)) return obj.map(updateFields);
            if (obj && typeof obj === "object") {
              if (obj.name === name) {
                let options = [];
                if (name === "contact_list_4") {
                  options = res.data.map((op: any) => ({ value: op.value, label: op.label,count:op.count }));
                } else if (name.includes("doctors_2")) {
                  // console.log(res.data, url, name);
                  options = res.data.map((op: any) => ({ value: op._id, label: op.name }));
                } else if (name.includes("dept")) {
                  options = res.data.map((op: any) => ({ value: op._id, label: op.dept_name }));
                  // console.log(options);
                } else if (name.includes("template")) {
                  
                  options = res?.data?.templates?.map((op: any) => ({
                    value: op.template_id, label: op.name.split('_').map((word: any) => word.charAt(0).toUpperCase() + word.slice(1)).join(' '), 
                    name: op.name, language: op.language, category: op.category}));
                } else {
                  options = res.data.map((op: any) => ({ value: op.id, label: op.name }));
                }
                if (name.includes("age_")) {
                  obj.firstOptions = options;
                  obj.secondOptions = options;
                } else {
                  obj.options = options;
                }
              }
              Object.values(obj).map(updateFields);
            }
          };

          updateFields(prev.schema);
          return { ...prev };
        });
      } else {
        toast.error(res.message, { position: "top-center", autoClose: 2000 });
      }
    } catch (error) {
      console.error("Failed to fetch schema", error);
    }

    dispatch(setLoading(false))
  };
  // console.log(schema)
  const API_GET_TemplateDetails = async ({ id= "", name = "" }) => {
    dispatch(setLoading(true))
    try {
      if (id){
        const res = await Utils.makeApiCall(`/templates?id=${id}`, "GET");
        if (res.status) {
          handlePreview(name, res?.data?.templates?.[0])
        } else {
          toast.error(res.message, { position: "top-center", autoClose: 2000 });
        }
      } else{
        handlePreview(name, null)
      }
    } catch (error) {
      console.error("Failed to fetch schema", error);
    }

    dispatch(setLoading(false))
  };

  const API_POST_Campaign = async () => {
    const payload = handleGetData();
    // const errors = !Object.values(payload).every((k:any) => k && k.length > 0)
    // if (errors) {
    //   toast.error("Invalid payload", { position: "top-center", autoClose: 800 })
    // } else {
      const validationResponse: any = validateData(schema.schema);
      // const validationResponse: any = null;
      if (validationResponse) {
        toast.error(`${validationResponse} cannot be empty.`, { position: "top-center", autoClose: 800 })
      } else {
        try {
          
          dispatch(setLoading(true))
          const response = await Utils.makeApiCall("/campaign", "POST", payload);
          
          if (response.status) {
            navigate("/campaigns");
          }
          else {
            // console.log(response);
            
            toast.error(response.message, { position: "top-center", autoClose: 2000 })
          }
        } catch (e) {
          console.log("Error sending the data", e);
        } finally {
          dispatch(setLoading(false))
        }
      }
    // }
  }

  const handlePreview = (name:string,data:any) => {
      
    setSchema((prev: any) => {
      const updateFields: any = (obj: any) => {
        if (Array.isArray(obj)) return obj.map(updateFields);
        if (obj && typeof obj === "object") {
          if (obj.name === `template_preview_${name.split("_")[2]}`) {
            obj.visible = data ? true : false;
          }
          if (obj.name === `template_title_${name.split("_")[2]}`) {
            // console.log(obj.name === `template_title_${name.split("_")[2]}`);
            
            const header = data?.components?.find((e:any)=> e["type"] === "HEADER")
            const text = header?.text
            const examples = header?.example?.header_text
            
            obj.label = examples && examples.length > 0 ? Utils.replacePlaceholders(text,examples) : text
          }
          if (obj.name === `template_body_${name.split("_")[2]}`) {
            const body = data?.components?.find((e:any)=> e["type"] === "BODY");
            const text = body?.text;
            const examples = body?.example?.body_text[0]
            obj.label = examples && examples.length > 0 ? Utils.replacePlaceholders(text,examples) : text
          }
          if (obj.name === `template_footer_${name.split("_")[2]}`){
            const body = data?.components?.find((e:any)=> e["type"] === "FOOTER");
            obj.label = body?.text;
          }
          Object.values(obj).map(updateFields);
        }
      };

      updateFields(prev.schema);
      return { ...prev };
    });
  }

  const handleGetData = (): any => {
    const data:any = [];
    const preparePayload = (fields:any) => {
      return fields?.map((f:any)=>{
        if (f?.fields?.length){
          preparePayload(f.fields);
        }
        if (f["visible"]!==false){
          if (f.element === "single-select"){            
            if ((f.mode === "multiple" && f?.value?.length) || (f?.value?.value)){
              data.push(f);
            }
          } else if (f?.value){
            data.push(f);
          }
        }
      })
    }
    schema.schema.forEach((sec:any)=>{
      if(sec.name === "add_form"){
        preparePayload(sec.fields);
      }
    })
    
    const pobj = {
      campaign_name: '',
      org_id,
      source: activeTab,
      hospital_branch: '',
      recipient_groups: [],
      schedules: [],
      contact_list: null
    }
    const payload = data.reduce((acc:any, curr:any)=>{
      
      if (curr.name === "campaign_name"){
        acc[curr.name] = curr.value;
      } else if (["hospital_branch", "frequency"].includes(curr.name)){
        if (curr?.element === "single-select" && !curr?.mode){
          acc[`${curr.name}_name`] = curr?.value?.label
        }
        acc[curr.name] = curr.value.value
      } 
      // else if (curr.name.includes("filter_type_") && curr?.value?.value){
      //   const id = curr.name.split("_")[2];
      //   const filters = data.filter((d:any)=> (d?.visible_for === curr?.value?.value));        
      //   if (curr?.value?.value === "by_patient"){
      //     acc["recipient_groups"].push({
      //       type: curr?.value?.value,
      //       filters: {
      //           age_min: filters.find((e:any)=> e.name.includes(`age_${id}`))?.value?.firstValue?.value,
      //           age_max: filters.find((e:any)=> e.name.includes(`age_${id}`))?.value?.secondValue?.value,
      //           gender: filters.find((e:any)=> e.name.includes(`gender_${id}`))?.value?.value,
      //           medical_condition: filters.find((e:any)=> e.name.includes(`condition_${id}`))?.value?.map((g:any)=> g.value),
      //       },
      //       // condition: filters.find((e:any)=> e.name.includes(`operator_${id}`))?.value?.value || "",
      //     })
      //   }
      //   else if (curr?.value?.value === "by_doctor"){          
      //     acc["recipient_groups"].push({
      //       type: curr?.value?.value,
      //       filters: {
      //           doctors: filters.find((e:any)=> e.name.includes(`doctors_${id}`))?.value?.map((g:any)=> g.value),
      //       },
      //       // condition: filters.find((e:any)=> e.name.includes(`operator_${id}`))?.value?.value || "",
      //     })
      //   }
      //   else if (curr?.value?.value === "by_department"){          
      //     acc["recipient_groups"].push({
      //       type: curr?.value?.value,
      //       filters: {
      //           departments: filters.find((e:any)=> e.name.includes(`dept_${id}`))?.value?.map((g:any)=> g.value),
      //       },
      //     })
      //   }
      // } 
      else if (curr.name.includes("filter_display_type_") && curr.value){
        const id = curr.name.split("_")[3];
        const filters = data.filter((d:any)=> (d?.parent === curr?.name)); 
        if(!filters?.length){
          return acc;
        }
        if (id === "1"){
          acc["recipient_groups"].push({
            type: "by_patient",
            filters: {
                age_min: filters.find((e:any)=> e.name.includes(`age_${id}`))?.value?.firstValue?.value,
                age_max: filters.find((e:any)=> e.name.includes(`age_${id}`))?.value?.secondValue?.value,
                gender: filters.find((e:any)=> e.name.includes(`gender_${id}`))?.value?.value,
                medical_condition: filters.find((e:any)=> e.name.includes(`condition_${id}`))?.value?.map((g:any)=> g.value),
            },
            // condition: filters.find((e:any)=> e.name.includes(`operator_${id}`))?.value?.value || "",
          })  
        }
        else if (id === "2"){          
          acc["recipient_groups"].push({
            type: "by_doctor",
            filters: {
                doctors: filters.find((e:any)=> e.name.includes(`doctors_${id}`))?.value?.map((g:any)=> g.value),
            },
            // condition: filters.find((e:any)=> e.name.includes(`operator_${id}`))?.value?.value || "",
          })
        }
        else if (id === "3"){          
          acc["recipient_groups"].push({
            type: "by_department",
            filters: {
                departments: filters.find((e:any)=> e.name.includes(`dept_${id}`))?.value?.map((g:any)=> g.value),
            },
          })
        }
        else if (id === "4"){          
          acc["contact_list"] = filters.find((e:any)=> e.name.includes(`contact_list_${id}`))?.value?.value;
        }
      } 
      else if (curr.name.includes("template_id_") && curr?.value?.value){
        const id = curr.name.split("_")[2];
        const date = data.find((e:any)=> e.name.includes(`template_date_${id}`))?.value
       
         
        acc["schedules"].push({
          template_id: curr?.value?.value,
          template_name: curr?.value?.name,
          language: curr?.value?.language || "en_US",
          scheduled_at: date ? moment(date, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm") : ""
        })
      }
      return acc;
    }, pobj)
    return payload;
  }


  const validateData = (fields: any[]): string | false => {
  
    const validateField = (field: any): string | false => {
      if (field?.visible !== false) {
        if (field?.required) {
          if (field.name.includes("age") && (!field?.value?.firstValue?.value || !field?.value?.secondValue?.value)) {
            return field.label;
          }
          if ((typeof field.value === 'string' && field.value.trim() === '') ||
              field.value === '' ||
              (Array.isArray(field.value) && field.value.length === 0) ||
              field.value === null ||
              field.value === undefined) {
            return field.label;
          }
        }
        if (field.fields) {
          return validateData(field.fields);
        }
      }
      return false;
    };
  
    return fields.reduce((acc, field) => acc || validateField(field), false);
  };
 


  const handleName = (fields: any, ind:number) => {
    return fields.map((field: any) => {
      if (field.name.includes(`remove_`)){
        const lastId = parseInt(field.name.split("_")[2]);
        
        if (lastId <= ind){
          field["visible"] = true;
        }
      }
      field["name"] = field?.name?.replace(/_\d+$/, `_${ind}`);
      if ((["single-select", "dropdown-group"].includes(field.element)) && (field?.["endpoint"] || field?.["firstEndpoint"])){        
        API_GET_Options({ name: field.name, url: field.endpoint || field.firstEndpoint });
      }
      // if (field.name === "template_name"){
      //   field.label = `Schedule ${ind}`
      // }
      if (field.fields) {
        field.fields = handleName(field.fields, ind);
      }
      return field;
    });
  };

  const [detectChange, setDetectChange] = useState(false)

  // Exit popup's custom hook
  useExitPopUp(detectChange)



  // Recipients and budget payload

  

  
  const getRecipientsBudgetPayload = (): any => {
    const data:any = [];
    const preparePayload = (fields:any) => {
      return fields?.map((f:any)=>{
        if (f?.fields?.length){
          preparePayload(f.fields);
        }
        if (f["visible"]!==false){
          if (f.element === "single-select"){            
            if ((f.mode === "multiple" && f?.value?.length) || (f?.value?.value)){
              data.push(f);
            }
          } else if (f?.value){
            data.push(f);
          }
        }
      })
    }
    schema.schema.forEach((sec:any)=>{
      if(sec.name === "add_form"){
        preparePayload(sec.fields);
      }
    })
    
    const pobj = {
      recipient: {
        age_min:null,
        age_max:null,
        gender:null
      },
      doctor:[],
      departments:[],
      categories:[],
      contactListId: null,
    }
    const payload = data.reduce((acc:any, curr:any)=>{

      if (curr.name.includes("filter_display_type_") && curr.value){
        const id = curr.name.split("_")[3];
        const filters = data.filter((d:any)=> (d?.parent === curr?.name)); 
        if(!filters?.length){
          return acc;
        }
        if (id === "1"){
          acc['recipient'].age_min = filters.find((e:any)=> e.name.includes(`age_${id}`))?.value?.firstValue?.value,
          acc['recipient'].age_max = filters.find((e:any)=> e.name.includes(`age_${id}`))?.value?.secondValue?.value,
          acc['recipient'].gender = filters.find((e:any)=> e.name.includes(`gender_${id}`))?.value?.value,
          acc['recipient'].medical_condition = filters.find((e:any)=> e.name.includes(`condition_${id}`))?.value?.map((g:any)=> g.value)
        }
        else if (id === "2"){          
          acc["doctor"].push(
              filters.find((e:any)=> e.name.includes(`doctors_${id}`))?.value?.map((g:any)=> g.value),
            )
        }
        else if (id === "3"){          
          acc["departments"].push(
                filters.find((e:any)=> e.name.includes(`dept_${id}`))?.value?.map((g:any)=> g.value),
           )
        }
        else if (id === "4"){          
          acc["contactListId"] =
                filters.find((e:any)=> e.name.includes(`contact_list_${id}`))?.value?.value;
           
        }
      } 
      else if (curr.name.includes("template_id_") && curr?.value?.value){       
         if(curr?.value?.category){
           acc["categories"].push(curr?.value?.category)
         }
      }
      return acc;
    }, pobj)
    return payload;
  }

  const handleCampaignRecipientsBudget = async() =>{
    const payload =  getRecipientsBudgetPayload()

    try {
      dispatch(setLoading(true))
      const response = await Utils.makeApiCall("/campaign-budget", "POST", payload);
      
      if (response) {
        setSchema((prev: any)=>{
          prev?.schema?.map((f: any)=>{
            // console.log(prev);
            if(f.name === "add_form"){
              f?.fields?.map((ff: any)=>{
                if(ff.name === "whatsapp_schema"){
                  ff?.fields?.map((g: any)=>{
                      if(g.name === "receipient_group"){
                        g?.fields?.map((gg: any)=>{
                          if(gg.name === "campaign_details"){
                            gg?.fields?.map((h: any)=>{
                              if(h.name === "campaign_filter_count_div"){
                                h?.fields?.map((hh: any)=>{
                                  
                                  if(hh.name === "campaign_filter_count"){
                                    hh.label = response.recipients_count
                                    
                                  }
                                })
                              }
                            })
                          }
                        })
                      }

                      if(g.name === "templates_form"){
                        g?.fields?.map((gg: any)=>{
                          if(gg.name === "campaign_budget_preview"){
                            gg?.fields?.map((h: any)=>{
                              if(h.name === "campaign_budget_value"){
                                  h.label = response.budget
                              }
                            })
                          }
                        })
                      }
                  })
                }
              })
            }
          })

          return {...prev}
        })
        
      }
      else {
        // console.log(response);
        
        toast.error(response.message, { position: "top-center", autoClose: 2000 })
      }
    } catch (e) {
      console.log("Error sending the data", e);
    } finally {
      dispatch(setLoading(false))
    }
    
  } 


  const [isTempRemoving, setIsTempRemoving] = useState(false)

  const handleChangeContactList = (e: any) => {
    setSchema((prev: any) => {
      prev.schema.map((f: any) => {
        if (f.name === "add_form") {
          if (!e.value){
            handleSelect({
              name: "filter_display_type_1",
              element: "single-checkbox",
              visible: true,
              value: true,
            })
            handleSelect({
              name: "filter_display_type_2",
              element: "single-checkbox",
              visible: false,
              value: false,
            })
            handleSelect({
              name: "filter_display_type_3",
              element: "single-checkbox",
              visible: false,
              value: false,
            })
          }
          updateCheckBoxVisibility(f.fields, {
            name: "patient_filters",
            value: !e.value,
          })
          updateCheckBoxVisibility(f.fields, {
            name: "doctor_filters_container",
            value: !e.value,
          })
          updateCheckBoxVisibility(f.fields, {
            name: "department_filters_container",
            value: !e.value,
          })
          // handleAddMore(f.fields, e);
        }
        return f
      })
      return { ...prev }
    })
  }

  const updateCheckBoxVisibility = (fields: any, e: any): any => {
    return fields?.map((ff: any) => {
      ff.fields = updateCheckBoxVisibility(ff.fields, e);
      
      if(["patient_filters","doctor_filters_container","department_filters_container"].includes(ff.name) && ff.name===e.name){
        ff.visible=e.value;
      }
      
      return ff;
    });
  };

  const handleSelect = async (e: any) => {

    // console.log(e)
    if (e.name === "btn_send") {
      setDetectChange(false)
      API_POST_Campaign();
    }

    if (e.name === "btn_discard") {
      navigate("/campaigns");
      return;
    }
    if (e.name === "tabs") {
      dispatch(setActiveTab(e.value.key));
    }
    if (e.name.includes("template_id")){
      API_GET_TemplateDetails({name: e.name, id: e.value.value})

      handleCampaignRecipientsBudget()
    }
    if (e.name === "add_filter"){
      setSchema((prev:any) => {
        const updatedSchema = prev.schema.map((sec:any) => {
          if (sec.name === "add_form") {
            const updatedFields = sec.fields.map((f:any) => {
              if (f.name === "whatsapp_schema") {
                const updatedWhatsappSchemaFields = f.fields.map((ff:any) => {
                  if (ff.name === "receipient_group") {
                    const updatedReceipientGroupFields = ff.fields.map((fff:any) => {
                      if (fff.name === "filters") {
                        const lastFilter = fff.fields[fff.fields.length - 1];
                        const lastId = lastFilter ? parseInt(lastFilter.name.split("_")[2]) : 0;
                        if (lastId && e?.name?.includes("add_filter")) {
                          const newIndex = lastId + 1;
                          const newFilter = {
                            ...addFilter(),
                            name: `receipient_filter_${newIndex}`,
                            fields: handleName(addFilter().fields ,newIndex)
                          };
                          const updatedFilters = [...fff.fields, newFilter];
                          // console.log(updatedFilters);
                          
                          return { ...fff, fields: updatedFilters };
                        }
                      }
                      return { ...fff };
                    });
      
                    return { ...ff, fields: updatedReceipientGroupFields };
                  }
                  return { ...ff };
                });
      
                return { ...f, fields: updatedWhatsappSchemaFields };
              }
              return { ...f };
            });
      
            return { ...sec, fields: updatedFields };
          }
          return { ...sec };
        });
      
        return { ...prev, schema: updatedSchema };
      });
    }
    if(e.name==="filter_display_type_4"){
      handleChangeContactList(e)
      if(!e.value){
        setSchema((prevSchema: any) => {
          const updatedSchema = Utils.updateSchemaRecursively(prevSchema, (field: any) => {
            if (["filter_display_type_2","filter_display_type_3"].includes(field.name) ) {
              field.value=false;
            }
            if(["age_1","gender_1","condition_1","dept_3","doctors_2"].includes(field.name) ) {
              field.value=null;
            }
            if(field.name==="campaign_filter_count"){
              field.label="0"
            }
            if(field.name==="campaign_budget_value"){
              field.label="₹0.00"
            }
            return field;
          });
        
          return updatedSchema;
        });
      } else {
        setSchema((prevSchema: any) => {
          const updatedSchema = Utils.updateSchemaRecursively(prevSchema, (field: any) => {
            if(field.name==="contact_list_4"){
              field.value=null
            }
            if(field.name==="campaign_filter_count"){
              field.label="0"
            }
            if(field.name==="campaign_budget_value"){
              field.label="₹0.00"
            }
            return field;
          });
        
          return updatedSchema;
        });
      }
      
    }
    if (e.name.includes("remove_")){
      setIsTempRemoving(true)
      setSchema((prev:any) => {
        const updatedSchema = prev.schema.map((sec:any) => {
          if (sec.name === "add_form") {
            const updatedFields = sec.fields.map((f:any) => {
              if (f.name === "whatsapp_schema") {
                const updatedWhatsappSchemaFields = f.fields.map((ff:any) => {
                  const ffnames: any = {
                    filter: "receipient_group",
                    template: "templates_form"
                  }
                  const ffname = ffnames[e.name.split("_")[1]]
                  if (ff.name === ffname) {
                    const updatedReceipientGroupFields = ff.fields.map((fff:any) => {
                      const ffnames: any = {
                        filter: "filters",
                        template: "templates_container"
                      }
                      const fffname = ffnames[e.name.split("_")[1]]
                      if (fff.name === fffname) {
                        const id = e ? parseInt(e.name.split("_")[2]) : 0;                        
                        if (id) {
                          const updatedFilters = fff.fields.filter((rf:any) => !rf.name.endsWith(`_${id}`)).map((f:any, index:number)=>{
                            if(f?.fields){
                              f.fields.map((ff:any)=>{
                                if(ff.name === "template_name"){
                                  ff.label = `Schedule ${index+1}`
                                }
                                return ff;
                              })
                            }
                            return f;
                          })
                          return { ...fff, fields: updatedFilters };
                        }
                      }
                      return { ...fff };
                    });
      
                    return { ...ff, fields: updatedReceipientGroupFields };
                  }
                  return { ...ff };
                });
      
                return { ...f, fields: updatedWhatsappSchemaFields };
              }
              return { ...f };
            });
      
            return { ...sec, fields: updatedFields };
          }
          return { ...sec };
        });
      
        return { ...prev, schema: updatedSchema };
      });
    }
    if (e.name === "add_schedule"){
      setSchema((prev:any) => {
        const updatedSchema = prev.schema.map((sec:any) => {
          if (sec.name === "add_form") {
            const updatedFields = sec.fields.map((f:any) => {
              if (f.name === "whatsapp_schema") {
                const updatedWhatsappSchemaFields = f.fields.map((ff:any) => {
                  if (ff.name === "templates_form") {
                    const updatedReceipientGroupFields = ff.fields.map((fff:any) => {
                      if (fff.name === "templates_container") {
                        const lastFilter = fff.fields[fff.fields.length - 1];
                        const lastId = lastFilter ? parseInt(lastFilter.name.split("_")[2]) : 0;
                        if (lastId && e?.name?.includes("add_schedule")) {
                          const newIndex = lastId + 1;
                          const newFilter = {
                            ...addTemplate(),
                            name: `template_card_${newIndex}`,
                            fields: handleName(addTemplate().fields ,newIndex)
                          };
                          const updatedFilters = [...fff.fields, newFilter].map((f:any, index:number)=>{
                            if(f?.fields){
                              f.fields.map((ff:any)=>{
                                if(ff.name === "template_name"){
                                  ff.label = `Schedule ${index+1}`
                                }
                                return ff;
                              })
                            }
                            return f;
                          })
                          return { ...fff, fields: updatedFilters };
                        }
                      }
                      return { ...fff };
                    });
      
                    return { ...ff, fields: updatedReceipientGroupFields };
                  }
                  return { ...ff };
                });
      
                return { ...f, fields: updatedWhatsappSchemaFields };
              }
              return { ...f };
            });
      
            return { ...sec, fields: updatedFields };
          }
          return { ...sec };
        });
      
        return { ...prev, schema: updatedSchema };
      });
      
    }
    setSchema((prev: any) => {
      prev.schema.map((f: any) => {
        if (f.name === "add_form" && e.name === "tabs") {
          f.fields = schemas[e.value.key] || []
        }
        if (f.name === "add_form") {
          updateVisibility(f.fields, e);
          // handleAddMore(f.fields, e);
        }
        return f;
      });
      return { ...prev };
    });



    if (
      // e.name.includes("filter_display_type_") ||
      e.name.includes("age_") ||
      e.name.includes("gender_") ||
      e.name.includes("condition_") ||
      e.name.includes("doctors_") ||
      e.name.includes("dept_") ||
      e.name.includes("template_id_") ||
      e.name.includes("contact_list_")
    ) {
      handleCampaignRecipientsBudget();
    }
  };

  useEffect(()=>{
    if(isTempRemoving){
      // console.log("jdfk");
      
      handleCampaignRecipientsBudget()
      setIsTempRemoving(false)
    }
  }, [schema])

  

  const updateVisibility = (fields: any, e: any): any => {
    // console.log(fields,e)
    return fields?.map((ff: any) => {
      ff.fields = updateVisibility(ff.fields, e);
      // if (ff.name === e.name){
      //   if (ff.mode === "multiple"){
      //     ff.value = e?.value || [];
      //   } else {
      //     ff.value = e?.value || null;
      //   }
      // }
      if (ff.visible_if_true && e.name === ff.visible_if_true) {
        ff.visible = e.value;
      }
      if (ff.visible_for && e.name.includes("filter_type_")) {
        const lastId = e ? parseInt(e.name.split("_")[2]) : 0;
        if (ff?.name?.endsWith(`_${lastId}`)){
          ff.visible = e?.value?.value === ff.visible_for;
          if (ff.visible){
            if ((ff.element === "single-select") && (ff?.["endpoint"] || ff?.["firstEndpoint"])){              
              API_GET_Options({ name: ff.name, url: ff.endpoint || ff.firstEndpoint });
            }
          }
        }
      }
      if (ff.visible_for && e.name.includes("filter_display_type_")) {

        // const lastId = e ? parseInt(e.name.split("_")[3]) : 0;
        if (ff?.visible_for === e?.name){
          ff.visible = e?.value;
          if (ff.visible){
            if ((ff.element === "single-select") && (ff?.["endpoint"] || ff?.["firstEndpoint"])){
              const details = localStorage.getItem("details");
              if(details){
                const org = JSON.parse(details);
                const orgId = org?._id;
                // console.log(orgId, ff.name);
                const url = (ff.name === "doctors_2" || ff.name === "dept_3" || ff.name==="contact_list_4") ? ff.endpoint : `${ff.endpoint}/${orgId}`
                API_GET_Options({ name: ff.name, url: url || ff.firstEndpoint });
              }

            }
            else if (ff.fields){
              ff.fields.map((fff:any)=>{
                if ((["dropdown-group", "single-select"].includes(fff.element)) && (fff?.["endpoint"] || fff?.["firstEndpoint"])){
                  API_GET_Options({ name: fff.name, url: fff.endpoint || fff.firstEndpoint });
                }
              })
            }
          }
        }
      }
      return ff;
    });
  };

  const handleAddMore = (fields: any[], e: any) => {
    const ele = e;
  
    fields?.map((ff: any) => {
      if (ff.fields) {
        ff.fields = handleAddMore(ff.fields, ele);
      }
      if (ff.name === "filters") {
        const lastFilter = ff.fields[ff.fields.length - 1];
        const lastId = lastFilter && lastFilter?.name ? lastFilter.name.split("_")[2] : 0;
        
        if (lastId && ele?.name?.includes("add_filter")) {
          const newIndex = parseInt(lastId) + 1;
          const newFilter = {
            ...addFilter(),
            name: `receipient_filter_${newIndex}`,
            fields: handleName(addFilter().fields ,newIndex),
            className: "grid grid-cols-5 gap-4 items-end",
          };
          ff.fields.push(newFilter)
          // setFiltersList((prevv: any)=> ([...prevv, newFilter]))
        }
      }
      return {...ff};
    });
    return fields;
  };    
  
  return (
    <div className="">
      
        <ElementExecutor
          // key={JSON.stringify(schema)}
          data={schema}
          selectedRecord={(e: any) => {
            handleSelect(e);
            if(["filter_display_type_1", "age_1", "gender_1", "condition_1", "filter_display_type_2", "doctors_2", "filter_display_type_3", "dept_3", "template_date_1", "template_id_1", "campaign_name", "add_schedule", ].includes(e?.name) ){
              setDetectChange(true)
            }
          }}
        />
      
    </div>
  );
};

export default AddCampaign;