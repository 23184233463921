import { ElementExecutor } from '@apexcura/core'
import React, { useEffect, useState } from 'react'
import Utils from '../../utils'
import { CONSTANTS } from "../../utils/app-constants";
import { toast } from 'react-toastify';
import moment from 'moment'
import { useNavigate } from 'react-router-dom';
import LoadingState from '../LoadingState';
import {Avatar} from "antd" 
const { BASE_URL} = CONSTANTS;

const VISIBLE_SLOTS=32
const { AUTO_CLOSE } = CONSTANTS

const schemaObj = ()=>{
    
    return{
        className:'flex flex-col gap-5 max-h- [90vh]',
        schema:[
            {
                name:'filter-section',
                className:'grid grid-cols-4 justify-between',
                fields:[
                    {
                        name:'date-section',
                        className:'grid grid-cols-10 justify-between w-[80%]',
                        fields:[
                            {
                                name:"prev-date-button",
                                element:"image",
                                visible:false,
                                img:`${BASE_URL}/api/public/assets/images/previous.svg`,
                                alr:"word_cloud_image",
                                imgClassName:'self-end pb-2'
                            }, 
                            {
                                name:'date-select',
                                element:'datepicker',
                                label:"Date",
                                enabled_dates: "from_today",
                                containerClassName:'col-span-8 w-full pr-2',
                                className:'w-full'
                            },
                            {
                                name:"next-date-button",
                                element:"image",
                                img:`${BASE_URL}/api/public/assets/images/next.svg`,
                                alr:"word_cloud_image",
                                imgClassName:"self-end pb-2"
                            },
                        ]
                    },
                    {
                        name:'branch-select',
                        element:'single-select',
                        label:"Branch",
                        containerClassName:'w-[80%]',
                        className:'w-[100%]',
                        options:[],
                    },
                    {
                        name:'doctor-filter',
                        element:'single-select',
                        label:"Doctor",
                        containerClassName:'w-[80%]',
                        className:'w-[100%]',
                        options:[
                            {
                                label:"Hi",
                                value:'Hi'
                            }
                        ],
                        optionRender:(option:any)=>{
                            if(option.label === 'All') return <>{option.label}</>
                            const items = option.label.split("-");
                            const color = Utils.stringToColour(items[0])
                            const initials = items[0].split(' ')
                            const first = initials.length>1?initials[1][0]:''
                            const last = initials.length>2?initials[2][0]:''
                            return <div className = 'flex gap-2 items-center'>
                              <Avatar style={{ backgroundColor: color, color: '#ffffff', height:'35px', width:'35px' }}>{first}{last}</Avatar>
                              {/* <div className='flex flex-col'>
                                <div>{items[0]}</div>
                                <div>{items[1]}</div>
                              </div> */}
                              <div>{items[0]}</div>
                            </div>
                        }
                    },
                    {
                        name:'department-filter',
                        element:'single-select',
                        label:"Department",
                        containerClassName:'w-[80%]',
                        className:'w-[100%]',
                        options:[
                        ]
                    }
                ]
            },
            {
                name:'page-body',
                className:'flex flex-row gap-2',
                fields:[
                    {
                        name:'appointment-section',
                        className:'flex flex-col gap-2 max-h-[75vh] bg-white overflow-y-scroll w-full',
                        fields:[
        
                        ]
                    },
                    {
                        name:'add-patient-section',
                        className:'flex flex-col justify-between gap-2 max-h-[75vh]',
                        visible:false,
                        fields:[
                            {
                                name:'patient-details',
                                className:'flex flex-col rounded-md bg-white rounded-md shadow-lg',
                                fields:[
                                    {
                                        className:'bg-[#D0D5FF] flex items-center justify-center p-2 rounded-md',
                                        element:'div',
                                        label:"Patient Details"
                                    },
                                    {
                                        name:'mobile-input',
                                        className:"w-full",
                                        label:"Mobile",
                                        containerClassName:'w-[100%] flex flex-col p-2',
                                        element:"auto-complete",
                                        items:[]
                                    },
                                    {
                                        name:'name-input',
                                        containerClassName:'pl-2 w-[100%]',
                                        element:'input-text',
                                        label:'Name'
                                    },
                                    {
                                        name:'dob-age-gender',
                                        className:'flex flex-row gap-2 p-2 pb-4',
                                        fields:[
                                            {
                                                name:'dob-input',
                                                element:'datepicker',
                                                className:'w-[100%]',
                                                label:"DOB"
                                            },
                                            // {
                                            //     name:'age-input',
                                            //     element:'input-text',
                                            //     label:"Age"
                                            // },
                                            {
                                                name:'gender-input',
                                                element:'single-select',
                                                label:"Gender",
                                                containerClassName:'w-[80%]',
                                                className:'w-[100%]',
                                                options:[
                                                    {
                                                        label:"Male",
                                                        value:"Male"
                                                    },
                                                    {
                                                        label:"Female",
                                                        value:"Female"
                                                    },
                                                    {
                                                        label:"Others",
                                                        value:"Others"
                                                    }
                                                ],
                                            },
                                            {
                                                name:'reset-button',
                                                element:'button',
                                                label:"Reset",
                                                className:'bg-white text-primary font-semibold underline underline-offset-2 flex items-end justify-center p-2'
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                name:'appointment-details',
                                className:'flex flex-col rounded-md bg-white gap-2 rounded-md shadow-lg',
                                fields: [
                                    {
                                        className:'bg-[#D0D5FF] flex items-center justify-center p-2 rounded-md',
                                        element:'div',
                                        label:"Appointment Details"
                                    },
                                    {
                                      name:'appointment-details-grid',
                                      className:'flex flex-col gap-6 p-2',
                                      fields:[
                                          {
                                              name:'slot-details',
                                              className:'grid grid-cols-4',
                                              fields:[
                                                  {
                                                      element:'div',
                                                      label:'Date: ',
                                                      className:'text-[#69757B]'
                                                  },
                                                  {
                                                      element:'div',
                                                      label:'',
                                                      name:'current-slot-date',
                                                      className:'col-span-3 text-[#37474F]'
                                                  },
                                                  {
                                                      element:'div',
                                                      label:'Time: ',
                                                      className:'text-[#69757B]'
                                                  },
                                                  {
                                                      element:'div',
                                                      name:'current-slot-timings',
                                                      label:'',
                                                      className:'col-span-3 text-[#37474F]'
                                                  },
                                              ]
                                          },
                                          {
                                              name:'doctor-details',
                                              className:'grid grid-cols-4',
                                              fields:[
                                                  {
                                                      element:'div',
                                                      label:'Doctor: ',
                                                      className:'text-[#69757B]'
                                                  },
                                                  {
                                                      element:'div',
                                                      label:'',
                                                      name:'doctor-name',
                                                      className:'col-span-3 text-[#37474F]'
                                                  },
                                                  {
                                                      element:'div',
                                                      label:'Dept: ',
                                                      className:'text-[#69757B]'
                                                  },
                                                  {
                                                      element:'div',
                                                      name:'department-name',
                                                      label:'',
                                                      className:'col-span-3 text-[#37474F]'
                                                  },
                                              ]
                                          }
                                      ]
                                    },
                                    {
                                      className:'flex items-center justify-center gap-2 justify-between p-2',
                                      fields:[
                                          {
                                              element:'button',
                                              name:'confirm-appointment',
                                              label:"Confirm Appointment",
                                              className:"px-2 py-2 bg-primary text-white rounded-md w-full",
                                          }
                                      ]
                                    },
                                  ]
                            }
                        ]
                    },
                ]
            }
            // {
            //     name:'book-appointment-button',
            //     element:'button',
            //     className:'bg-primary text-white rounded-md self-end p-2',
            //     label:'Book Appointment'
            // }
        ]
    }
}

export const AddAppointments = () => {
    const [loading,setLoading] = useState(false)
    const [schema,setSchema] = useState<any>(schemaObj())
    const [doctorsList,setDoctorsList] = useState<any[]>([])
    const [departmentsList,setDepartmentsList] = useState<any>([])
    const [patientId,setPatientId] = useState<string>('')
    const [mobile,setMobile] = useState<string>('')
    const [name,setName] = useState<string>('')
    const [dob,setDob] = useState<string>('')
    const [gender,setGender] = useState<string>('')
    const [branchId,setBranchId] = useState<string>('')
    const [patientsList,setPaitentsList] = useState<any>([])
    const [currentSlot,setCurrentSlot] = useState<any>()
    const [currentDoctors,setCurrentDoctors] = useState<any[]>([])
    const [currentDate,setCurrentDate] = useState(new Date().toLocaleDateString('en-GB').split('/').join('-'))

    const navigate=useNavigate()

    const today = new Date().toLocaleDateString('en-GB').replace(/\//g, '-')

    useEffect(()=>{
        if(!branchId) return
        (async()=>{
            await Get_Departments_List()    
            
            const patients = await Get_Patients_List()
            setPaitentsList(patients)        
        })()
    },[branchId])

    useEffect(()=>{
        (async()=>{
            const branches = await Get_User_Branches()
            setBranchId(branches[0].branch_id)
            setSchema((prev:any)=>{
                return{
                    ...prev,
                    schema:prev.schema.map((field:any)=>{
                        if(field.name === 'filter-section'){
                            return{
                                ...field,
                                fields:field.fields.map((f:any)=>{
                                    if(f.name === 'branch-select'){
                                        return{
                                            ...f,
                                            options:branches.map((branch:any)=>{
                                                return{
                                                    label:branch.branch_name,
                                                    value:branch.branch_id
                                                }
                                            }),
                                            value:{
                                                label:branches[0].branch_name,
                                                value:branches[0].branch_id
                                            }
                                        }
                                    }
                                    return f
                                })
                            }
                        }
                        return field
                    })
                }
            })
        })()
    },[])

    useEffect(()=>{
        if(!currentSlot){
            setSchema((prev:any)=>{
                return{
                    ...prev,
                    schema:prev.schema.map((e:any)=>{
                        if(e.name === 'page-body'){
                            return{
                                ...e,
                                fields:e.fields.map((field:any)=>{
                                    if(field.name === 'add-patient-section'){
                                        return {
                                            ...field,
                                            visible:false
                                        }
                                    }
                                    return field
                                })
                            }
                        }
                        return e
                    })
                }
            })
            return
        }
        const doctor = currentDoctors.find((d:any)=>d.id === currentSlot.doctorId)
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((e:any)=>{
                    if(e.name === 'page-body'){
                        return{
                            ...e,
                            fields:e.fields.map((field:any)=>{
                                if(field.name === 'appointment-section'){
                                    return{
                                        ...field,
                                        fields : field.fields.map((ff:any)=>{
                                            if(ff.name === 'slots-container'){
                                                return{
                                                    ...ff,
                                                    fields:ff.fields.map((f:any)=>{
                                                        if(f.name === 'slot-button-grid'){
                                                            return{
                                                                ...f,
                                                                className:'flex flex-row gap-2 flex-wrap bg-white min-h-[30px] justify-items-center',
                                                                fields:f.fields.map((ff:any)=>{
                                                                    if(ff.doctorId === currentSlot.doctorId && 
                                                                        ff.startTime === currentSlot.startTime && 
                                                                        ff.endTime === currentSlot.endTime &&
                                                                        ff.date === currentSlot.date){
                                                                        ff.className = 'slot-button-active'
                                                                    }else{
                                                                        if(ff.className!=='hidden' && ff.name!=='expand-slots-button'){
                                                                            ff.className = 'slot-button'
                                                                        }
                                                                    }
                                                                    return ff
                                                                })
                                                            }
                                                        }
                                                        return f
                                                    })
                                                }
                                            }
                                            return ff
                                        })
                                    }
                                }
                                if(field.name === 'add-patient-section'){
                                    return {
                                        ...field,
                                        visible:true,
                                        fields:field.fields.map((f:any)=>{
                                            if(f.name === 'patient-details'){
                                                return {
                                                    ...f,
                                                    fields:f.fields.map((ff:any)=>{
                                                        if(ff.name === 'mobile-input'){
                                                            if(!patientsList) return
                                                            console.log(patientsList)
                                                            const optionsList = patientsList.map((option:any)=>{
                                                                return{
                                                                    key:option.id,
                                                                    contact:`${option.phoneNumber}`,
                                                                    label:option.name
                                                                }
                                                            })
                                                            console.log(optionsList)
                                                            return{
                                                                ...ff,
                                                                items:optionsList
                                                            }
                                                            // return ff    
                                                        }
                                                        return ff
                                                    })
                                                }
                                            }
                                            if(f.name === 'appointment-details'){
                                                return{
                                                    ...f,
                                                    fields:f.fields.map((ff:any)=>{
                                                        if(ff.name === 'appointment-details-grid'){
                                                            return{
                                                                ...ff,
                                                                fields:ff.fields.map((fff:any)=>{
                                                                    if(fff.name === 'slot-details'){
                                                                        return{
                                                                            ...fff,
                                                                            fields:fff.fields.map((ffff:any)=>{
                                                                                if(ffff.name === 'current-slot-date'){
                                                                                    ffff.label = currentSlot.date
                                                                                }
                                                                                if(ffff.name === 'current-slot-timings'){
                                                                                    ffff.label = `${currentSlot.startTime} - ${currentSlot.endTime} (${currentSlot.duration} mins)`
                                                                                }
                                                                                return ffff
                                                                            })
                                                                        }
                                                                    }
                                                                    if(fff.name === 'doctor-details'){
                                                                        return{
                                                                            ...fff,
                                                                            fields:fff.fields.map((ffff:any)=>{
                                                                                if(ffff.name === 'doctor-name'){
                                                                                    ffff.label = doctor.name
                                                                                }
                                                                                if(ffff.name === 'department-name'){
                                                                                    ffff.label = `${doctor.department.dept_name}`
                                                                                }
                                                                                return ffff
                                                                            })
                                                                        }
                                                                    }
                                                                    return fff
                                                                })
                                                            }
                                                        }
                                                        return ff
                                                    })
                                                }
                                            }
                                            return f
                                        })
                                    }
                                }
                                return field
                            })
                        }
                    }
                    return e
                })
            }
        })
    },[currentSlot])

    useEffect(()=>{
        if(!departmentsList) return
        (async()=>{
            if(departmentsList.length == 1){
                const docs = await Get_Doctors_List(departmentsList[0].id)
                setCurrentDoctors(docs)
            }else{
                const docs = await Get_Doctors_List('')
                setCurrentDoctors(docs)
            }
        })()
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((field:any)=>{
                    if(field.name === 'filter-section'){
                        return{
                            ...field,
                            fields:field.fields.map((f:any)=>{
                                if(f.name === 'department-filter'){
                                    if(departmentsList.length == 1){
                                        f.options = departmentsList.map((doctor:any)=>{
                                            return {
                                                label:doctor.name,
                                                value:doctor.id
                                            }
                                        })
                                            f.value = {
                                                label:departmentsList[0].name,
                                                value:departmentsList[0].id
                                            }
                                    }else{
                                        f.options = [{
                                            label:"All",
                                            value:"ALL"
                                        },...departmentsList.map((doctor:any)=>{
                                            return {
                                                label:doctor.name,
                                                value:doctor.id
                                            }
                                        })]
                                            f.value = {
                                                label:'All',
                                                value:"ALL"
                                            }
                                    }

                                }
                                if(f.name === 'date-section'){
                                    return{
                                        ...f,
                                        fields:f.fields.map((ff:any)=>{
                                            if(ff.name === 'date-select'){
                                                ff.value = new Date().toLocaleDateString('en-GB').replace(/\//g, '-')
                                            }
                                            return ff
                                        })
                                    }
                                }
                                // if(f.name === 'date-select'){
                                //     f.value = new Date().toLocaleDateString('en-GB').replace(/\//g, '-')
                                // }
                                return f;
                            })
                        }
                    }
                    
                    return field
                })
            }
        })
    },[departmentsList])

    useEffect(()=>{
        if(!doctorsList) return
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((field:any)=>{
                    if(field.name === 'filter-section'){
                        return{
                            ...field,
                            fields:field.fields.map((f:any)=>{
                                if(f.name === 'doctor-filter'){
                                    if(doctorsList.length == 1){
                                        f.options = doctorsList.map((doctor:any)=>{
                                            return {
                                                label:doctor.name,
                                                value:doctor.id
                                            }
                                        })
                                            f.value = {
                                                label:doctorsList[0].name,
                                                value:doctorsList[0].id
                                            }
                                    }else{
                                        f.options = [{
                                            label:'All',
                                            value:"ALL"
                                        },...doctorsList.map((doctor:any)=>{
                                            return {
                                                label:doctor.name,
                                                value:doctor.id
                                            }
                                        })]
                                            f.value = {
                                                label:'All',
                                                value:"ALL"
                                            }
                                    }
                                    
                                }
                                return f;
                            })
                        }
                    }
                    return field
                })
            }
        })
    },[doctorsList])

    useEffect(()=>{
        if(mobile.length<3) return
    },[mobile])

    useEffect(()=>{
        (async()=>await updateSlots())()
        console.log(currentDate)
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((field:any)=>{
                    if(field.name === 'filter-section'){
                        return{
                            ...field,
                            fields:field.fields.map((f:any)=>{
                                if(f.name === 'date-section'){
                                    return{
                                        ...f,
                                        fields:f.fields.map((ff:any)=>{
                                            if(ff.name === 'date-select'){
                                                ff.value = currentDate
                                            }
                                            if(ff.name === 'prev-date-button'){
                                                if(moment(currentDate, 'DD-MM-YYYY').isAfter(moment(today, 'DD-MM-YYYY'))){
                                                    ff.visible = true
                                                }else{
                                                    ff.visible = false
                                                }
                                            }
                                            return ff
                                        })
                                    }
                                }
                                // if(f.name === 'date-select'){
                                //     f.value = new Date().toLocaleDateString('en-GB').replace(/\//g, '-')
                                // }
                                return f;
                            })
                        }
                    }
                    
                    return field
                })
            }
        })
    },[currentDoctors,currentDate])

    const updateSlots = async()=>{
        setLoading(true)
        let fields:any[] = await Promise.all(currentDoctors.map(async(doctor:any)=>{
            let num = 0
            const slots = await Get_Available_Slots(doctor.id)
            return{
                className:'flex flex-col p-4 border-b-2 border-gray-200 rounded-sm',
                name:'slots-container',
                doctorId:doctor.id,
                numSlots: slots.availableSlots.length,
                fields:[
                    {
                        className:'grid grid-cols-3 pl-2 pr-4 pb-2 items-center',
                        fields:[
                            {
                                element:'div',
                                label:<span><span className='text-[#00796B]'>{doctor.name}</span><span className='text-[#4B5A61] text-lg'> - {doctor.department?.dept_name}</span></span>,
                                className:'text-xl'
                            },
                            {
                                element:'div',
                                label:<span><span>Available Slots: </span><span className='font-semibold'>{slots.length}/{slots.totalSlots}</span></span>,
                                className:'text-md justify-self-center'
                            },
                            {
                                element:'div',
                                label:<span><span>Slot Size: </span><span className='font-semibold'>{doctor.slotSize} minutes</span></span> ,
                                className:'text-md justify-self-end'
                            }
                        ]
                    },
                    slots.availableSlots.length!=0?{
                        name:'slot-button-grid',
                        className:'flex flex-row gap-2 flex-wrap bg-white min-h-[30px] justify-items-center',
                        fields:[...slots.availableSlots.map((duration:any)=>{
                            const defaultStyle = 'slot-button'
                            const style = currentDoctors.length==1?defaultStyle:num<VISIBLE_SLOTS?defaultStyle:'hidden'
                            num++
                            return {
                                name:'slot-button',
                                element:'button',
                                label:duration.startTime,
                                doctorId:doctor.id,
                                className:style,
                                date:slots.date,
                                duration:doctor.slotSize,
                                startTime:duration.startTime,
                                endTime:duration.endTime
                            }
                        }),currentDoctors.length!==1 && slots.availableSlots.length>VISIBLE_SLOTS?{
                            name:'expand-slots-button',
                            element:'button',
                            doctorId:doctor.id,
                            label:'Show More',
                            className:'font-semibold text-primary justify-self-end underline underline-offset-2',
                            status:'view'
                        }:{}]
                    }:{
                        element:'div',
                        className:'p-4',
                        label:'No available slots'
                    }
                ]

            }
            
        }))
        fields = fields.sort((a:any,b:any)=>{
            return a.numSlots>b.numSlots?-1:1
        })
        setLoading(false)
        setSchema((prev:any)=>{
            return{
                ...prev,
                schema:prev.schema.map((e:any)=>{
                    if(e.name === 'page-body'){
                        return{
                            ...e,
                            fields:e.fields.map((field:any)=>{
                                if(field.name === 'appointment-section'){
                                    return{
                                        ...field,
                                        fields:fields
                                    }
                                }
                                return field
                            })
                        }
                    }
                    return e
                })
            }
        })
    }
    
    const handleClosePopup = async()=>{
        setSchema((prev:any)=>{
            const filteredSchema = prev.schema.filter((field: any) => field.name !== "appointment_popup");
            return{
            ...prev,
            schema:filteredSchema
            }
        })
    }

    const isValidAppointment = ()=>{
        if(!currentSlot){
            toast.error('Please select a valid slot', { position: "top-center", autoClose: AUTO_CLOSE });
            return false
        }
        if(!patientId){
            if(!mobile){
                toast.error('Please enter valid mobile number or select a valid patient', { position: "top-center", autoClose: AUTO_CLOSE });
                return false
            }
            if(!name){
                toast.error('Please enter valid patient name or select a valid patient', { position: "top-center", autoClose: AUTO_CLOSE });
                return false
            }
        }

        return true
    }

    const Get_Departments_List = async()=>{
        const response = await Utils.makeApiCall(`/departmentsDropdown?branch_id=${branchId}`,'GET')
        const list = response.data.map((entry:any)=>{
            return{
                id:entry._id,
                name:entry.dept_name
            }
        })
        setDepartmentsList(list)
        console.log(response)
    }

    const Get_Doctors_List = async(departmentId:string)=>{
        const response = await Utils.makeApiCall(`/doctors?departmentId=${departmentId}&branchId=${branchId}`,'GET')
        const list = response.data.map((entry:any)=>{
            return{
                id:entry._id,
                name:entry.name,
                department:entry.department_id,
                slotSize:entry.slot_size,
            }   
        })
        setDoctorsList(list)
        return list
    }

    const Get_Patients_List = async()=>{
        const response = await Utils.makeApiCall(`/patient?branchId=${branchId}&download=true`,'GET')
        const list = response.data?.map((entry:any)=>{
            return{
                id:entry._id,
                name:entry.name[0]?.text,
                phoneNumber:entry?.telecom[0]?.value,
                gender:entry.gender,
                birthDate:entry.birthDate || entry.date_of_birth
            }
        })
        return list
    }

    const Get_Available_Slots = async(doctorId:string)=>{
        const response = await Utils.makeApiCall(`/appointments/getAvailableSlots?doctorId=${doctorId}&date=${currentDate}`,'GET')
        return response
    }

    const Get_User_Branches = async()=>{
        const response = await Utils.makeApiCall(`/getUserBranches`,'GET')
        return response.data
    }

    const Post_Appointment_Add = async()=>{
        const response = await Utils.makeApiCall(`/appointments`,'POST',{
            doctorId:currentSlot.doctorId,
            startTime:currentSlot.startTime,
            endTime:currentSlot.endTime,
            patientId:patientId,
            name:name,
            mobile:mobile,
            dob:dob,
            gender:gender,
            branchId:branchId,
            date:currentSlot.date
        })
        return response
    }

    const handleSelectedRecord = async(e:any)=>{
        console.log(e)
        if(e.name === 'doctor-filter'){
            if(e.value.value === 'ALL'){
                setCurrentDoctors(doctorsList)
            }else{
                setCurrentDoctors(doctorsList.filter((doc)=>{
                    return doc.id === e.value.value
                }))
            }
            setCurrentSlot(null)
        }
        if(e.name === 'branch-select'){
            setBranchId(e.value.value)
            setCurrentSlot(null)
        }
        if(e.name === 'department-filter'){
            if(e.value.value === 'ALL'){ 
                const docs = await Get_Doctors_List('')
                setCurrentDoctors(docs)
            }
            else{
                const docs = await Get_Doctors_List(e.value.value)
                setCurrentDoctors(docs)
                setSchema((prev:any)=>{
                    return{
                        ...prev,
                        schema:prev.schema.map((field:any)=>{
                            if(field.name === 'filter-section'){
                                return{
                                    ...field,
                                    fields:field.fields.map((f:any)=>{
                                        if(f.name === 'doctor-filter'){
                                            return{
                                                ...f,
                                                value:{
                                                    label:'',
                                                    value:''
                                                }
                                            }
                                        }
                                        return f
                                    })
                                }
                            }
                            return field
                        })
                    }
                })
            }
            setCurrentSlot(null)
        }
        if(e.name === 'date-select'){
            setCurrentDate(e.value)
            if(!e.value){
                setCurrentDate(new Date().toLocaleDateString('en-GB').split('/').join('-'))
            }
            setCurrentSlot(null)
        }
        if(e.name === 'next-date-button'){
            setCurrentDate(moment(currentDate, 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY'))
        }
        if(e.name === 'prev-date-button'){
            setCurrentDate(moment(currentDate, 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY'))
        }
        if(e.name === 'slot-button'){
            setCurrentSlot({
                doctorId:e.doctorId,
                date:e.date,
                startTime:e.startTime,
                endTime:e.endTime,
                duration:e.duration
            })
        }
        if(e.name === 'modal_close' || e.name === 'cancel-appointment'){
            handleClosePopup()
        }
        if(e.name === 'confirm-appointment'){
            if(!isValidAppointment()) {
                return
            }
            const res = await Post_Appointment_Add()
            if(!res.error){
                // handleClosePopup()
                // updateSlots()
                // resetPatientForm()
                navigate('/appointments')
                toast.success(res.message, { position: "top-center", autoClose: AUTO_CLOSE });
            }
        }
        if(e.name === 'mobile-input'){
            const patient = patientsList.find((patient:any)=>patient.id === e.value.key)
            if(patient){
                setPatientId(e.value.key)
                setName('')
                setGender('')
                setDob('')
                setMobile('')
                setSchema((prev:any)=>{
                    return{
                        ...prev,
                        schema:prev.schema.map((e:any)=>{
                            if(e.name === 'page-body'){
                                return{
                                    ...e,
                                    fields:e.fields.map((field:any)=>{
                                        if(field.name === 'add-patient-section'){
                                            return {
                                                ...field,
                                                fields:field.fields.map((f:any)=>{
                                                    if(f.name === 'patient-details'){
                                                        return {
                                                            ...f,
                                                            fields:f.fields.map((ff:any)=>{
                                                                if(ff.name === 'name-input'){
                                                                    return{
                                                                        ...ff,
                                                                        disabled:true,
                                                                        value:patient.name
                                                                    }
                                                                }
                                                                if(ff.name === 'dob-age-gender'){
                                                                    return{
                                                                        ...ff,
                                                                        fields:ff.fields.map((fff:any)=>{
                                                                            if(fff.name === 'gender-input'){
                                                                                return{
                                                                                    ...fff,
                                                                                    disabled:true,
                                                                                    value:patient.gender
                                                                                } 
                                                                            }
                                                                            if(fff.name === 'dob-input'){
                                                                                return{
                                                                                    ...fff,
                                                                                    disabled:true,
                                                                                    value:patient.birthDate
                                                                                } 
                                                                            }
                                                                            return fff
                                                                        })
                                                                    }
                                                                }
                                                                
                                                                return ff
                                                            })
                                                        }
                                                    }
                                                    return f
                                                })
                                            }
                                        }
                                        return field
                                    })
                                }
                            }
                            return e
                        })
                    }
                })
            }else{
                setPatientId('')
                setMobile(e.value.key)
            }
        }
        if(e.name === 'name-input'){
            setPatientId('')
            setName(e.value)
        }
        if(e.name === 'dob-input'){
            setDob(e.value)
        }
        if(e.name === 'gender-input'){
            setGender(e.value.value)
        }
        if(e.name === 'reset-button'){
            setPatientId('')
            setName('')
            setGender('')
            setDob('')
            setMobile('')
            setSchema((prev:any)=>{
                return{
                    ...prev,
                    schema:prev.schema.map((e:any)=>{
                        if(e.name === 'page-body'){
                            return{
                                ...e,
                                fields:e.fields.map((field:any)=>{
                                    if(field.name === 'add-patient-section'){
                                        return {
                                            ...field,
                                            fields:field.fields.map((f:any)=>{
                                                if(f.name === 'patient-details'){
                                                    return {
                                                        ...f,
                                                        fields:f.fields.map((ff:any)=>{
                                                            if(ff.name === 'name-input'){
                                                                return{
                                                                    ...ff,
                                                                    disabled:false,
                                                                    value:''
                                                                }
                                                            }
                                                            if(ff.name === 'mobile-input'){
                                                                return{
                                                                    ...ff,
                                                                    value:{key:'',value:''}
                                                                }
                                                            }
                                                            if(ff.name === 'dob-age-gender'){
                                                                return{
                                                                    ...ff,
                                                                    fields:ff.fields.map((fff:any)=>{
                                                                        if(fff.name === 'gender-input'){
                                                                            return{
                                                                                ...fff,
                                                                                disabled:false,
                                                                                value:''
                                                                            } 
                                                                        }
                                                                        if(fff.name === 'dob-input'){
                                                                            return{
                                                                                ...fff,
                                                                                disabled:false,
                                                                                value:''
                                                                            } 
                                                                        }
                                                                        return fff
                                                                    })
                                                                }
                                                            }
                                                            
                                                            return ff
                                                        })
                                                    }
                                                }
                                                return f
                                            })
                                        }
                                    }
                                    return field
                                })
                            }
                        }
                        return e
                    })
                }
            })
        }
        if(e.name === 'add-new-patient-button'){
            navigate('/patients/add')
        }
        if(e.name === 'expand-slots-button'){
            const status = e.status
            setSchema((prev:any)=>{
                return{
                    ...prev,
                    schema:prev.schema.map((ee:any)=>{
                        if(ee.name === 'page-body'){
                            return{
                                ...ee,
                                fields:ee.fields.map((field:any)=>{
                                    if(field.name === 'appointment-section'){
                                        return{
                                            ...field,
                                            fields : field.fields.map((ff:any)=>{
                                                if(ff.name === 'slots-container' && ff.doctorId === e.doctorId){
                                                    return{
                                                        ...ff,
                                                        fields:ff.fields.map((f:any)=>{
                                                            if(f.name === 'slot-button-grid' && e.status === 'view'){
                                                                return{
                                                                    ...f,
                                                                    fields:f.fields.map((ff:any)=>{
                                                                        if(ff.className === 'hidden'){
                                                                            ff.className = 'slot-button'
                                                                        }
                                                                        if(ff.name === 'expand-slots-button'){
                                                                            ff.status = 'hide'
                                                                            ff.label = 'Hide'
                                                                        }
                                                                        return ff
                                                                    })
                                                                }
                                                            }
                                                            if(f.name === 'slot-button-grid' && e.status === 'hide'){
                                                                let num = 0;
                                                                return{
                                                                    ...f,
                                                                    fields:f.fields.map((ff:any)=>{
                                                                        if(num<VISIBLE_SLOTS) {
                                                                            num++ 
                                                                            return ff
                                                                        }
                                                                        
                                                                        if(ff.name === 'expand-slots-button'){
                                                                            ff.status = 'view'
                                                                            ff.label = 'Show More'
                                                                        }else{
                                                                            ff.className = 'hidden'
                                                                        }
                                                                        num++
                                                                    
                                                                        return ff
                                                                    })
                                                                }
                                                            }
                                                            return f
                                                        })
                                                    }
                                                }
                                                return ff
                                            })
                                        }
                                    }
                                    return field
                                })
                            }
                        }
                        return ee
                    })
                }
            })
        }
    }
    return (
        <div>
            <LoadingState isLoading={loading} />
            <ElementExecutor data={schema} selectedRecord={handleSelectedRecord}/>
        </div>
    )
}