import React, { useEffect, useState } from 'react'
import {Avatar,Popover,Tooltip} from "antd";
import moment from 'moment';
import { ElementExecutor } from '@apexcura/core';
import { CONSTANTS } from '../../utils/app-constants';
import Utils from '../../utils';
import { toast } from 'react-toastify';
import Storage from '../../utils/local-storage';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/appState/appStateSlice';
const { BASE_URL, FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;

const CallsReport = () => {

    const [schema,setSchema] = useState<any>({
        className:
          'w-full bg-white rounded-lg py-[10px] px-[20px] flex flex-col gap-3 divide-y',
        schema: [
          {
            name: 'filters',
            fields: [
              {
                name:"date_and_search",
                className:"flex gap-2 justify-center items-center ",
                fields:[
                  {
                      name:"segments",
                      element:"segmented",
                      segmentedOptions:[
                        {
                            label:(<Tooltip title="Today">1D</Tooltip>),
                            value:"TD"
                        },
                        // {
                        //     label:(<Tooltip title="Yesterday">YD</Tooltip>),
                        //     value:"YD"
                        // },
                        {
                            label:(<Tooltip title="This Week">1W</Tooltip>),
                            value:"TW"
                        },
                        {
                            label:(<Tooltip title="This Month">1M</Tooltip>),
                            value:"TM"
                        },
                      ],
                      visible:true,
                      value:"TW"
                  },
                  {
                    name: 'date',
                    label: '',
                    placeholder: 'Select a date',
                    element: 'daterangepicker',
                    value: [moment().subtract(7, 'days').format('DD-MM-YYYY'), moment().format("DD-MM-YYYY")],
                    className: 'flex-1 w-[240px]',
                  },
                  
                ]
              },
              
            ],
            className: 'flex items-center justify-between gap-2',
          },
          {
            name:"graphs_and_images",
            className:"grid grid-cols-12 gap-2",
            fields:[
              {
                name:"word_cloud_table",
                className:"flex gap-2 flex-col col-span-7  rounded-md",
                fields:[
                    {
                        name:"word_cloud",
                        label:"Wordcloud of words used in calls",
                        element:"div",
                        className:"text-primary mt-2 font-semibold text-lg "
                    },
                    {
                      name: 'word_cloud_data',
                      element: 'wordcloud',
                      className: 'bg-white border border-gray-100 rounded-md px-2',
                      height: 350,
                      showTooltip:true,
                      visible: true,
                      minFontSize:10,
                      maxFontsize:40,
                      data:[]
                    
                  },
                ]
              },
              {
                name: 'suggestions3',
                className: 'bg-white rounded-md col-span-5 overflow-hidden flex flex-col gap-2 items-center ',
                fields: [
                    {
                        name: 'suggestions3_text',
                        className:"mt-2",
                        fields: [
                            {
                                name: "calls_data_label",
                                element: "span",
                                label: "Call Center Performance Overview",
                                className: " text-primary  font-semibold text-lg ",
                            },
                            {
                                name: 'calls_data_eye_icon',
                                element: 'div',
                                label: <Popover content="Breaks down the types of calls received, including answered, special requests and hangup calls." overlayStyle={{width:'350px' }}><img src={`${BASE_URL}/api/public/assets/images/info.svg`} alt='Info_icon' className="size-4 cursor-pointer"/></Popover>,
                                visible: false,
                            },
                        ]
                    },
                    {
                        name: 'calls_data',
                        element: 'variable-piechart',
                        className: 'bg-white rounded-md px-2',
                        centerLabel1: 'Medium',
                        innerSize:'40%',
                        legendAlign:"center",
                        legendVerticalAlign:"bottom",
                        showTooltip: false,
                        showLegend:true,
                        distance:"-40%",
                        fontSize :"12px",
                        // width: 380,
                        height: 350,
                        centerLabel2:
                            'Optimise response tim algos',
                        visible: true,
                        data: [
                            
                        ],
                    },
                ]
    
              },
            ]
          },
          {
            name:"effeciency_table",
            className:"flex gap-2 flex-col   rounded-md",
            fields:[
                {
                    name:"effeciency",
                    label:"Efficiency",
                    element:"div",
                    className:"text-primary mt-2 font-semibold text-lg w-full"
                },
                {
                    className: 'bg-white',
                    name: "effeciency_table",
                    element: 'table',
                    loading: false,
                    variant: 'plain',
                    count:0,
                    size: 'small',
                    pagination: true, 
                    view: true,
                    visible: true,
                    thead: [
                        {
                            name:"agent", label:"Agent",key:"agent"
                        },
                      { name: 'total', label: 'Total Calls', key: 'total',
                      },
                      {
                        name: 'answered',
                        label: 'Answered ',
                        key: 'answered',
                      },
                      {name: 'missed', label: 'Missed ', key: 'missed',},
                      { name: 'return_calls', label: 'Returned ', key: 'return_calls'},
                      { name: 'effeciency', label: 'Effeciency', key: 'effeciency',},
                      {
                        name: 'min_duration',
                        label: 'Min Duration',
                        key: 'min_duration',
                      },
                      {
                        name: 'max_duration',
                        label: 'Max Duration',
                        key: 'max_duration',
                      },
                      {
                        name: 'avg_duration',
                        label: 'Avg Duration',
                        key: 'avg_duration',
                      }
                    ],
                    tbody: [
                        {
                            agent:"9701281777",
                            total:"100",
                            missed:"40",
                            answered:"60",
                            return_calls:"20",
                            effeciency:"60%",
                            min_duration:"10sec",
                            max_duration:"2min",
                            avg_duration:"1min 5sec"

                        },
                        {
                            agent:"9700092134",
                            total:"200",
                            missed:"80",
                            answered:"120",
                            return_calls:"30",
                            effeciency:"60%",
                            min_duration:"2min",
                            max_duration:"5min",
                            avg_duration:"3min 30sec"

                        },
                        {
                            agent:"6789067890",
                            total:"100",
                            missed:"40",
                            answered:"60",
                            return_calls:"20",
                            effeciency:"60%",
                            min_duration:"1min",
                            max_duration:"2min",
                            avg_duration:"1min"

                        },
                        {
                            agent:"9078690876",
                            total:"100",
                            missed:"40",
                            answered:"60",
                            return_calls:"20",
                            effeciency:"60%",
                            min_duration:"15sec",
                            max_duration:"2min 5sec",
                            avg_duration:"1min 10 sec"

                        }
                    ],
                },
            ]
          },
          {
            name:"compliance_table",
            className:"flex gap-2 flex-col  rounded-md p-2",
            fields:[
                {
                    name:"compliance",
                    label:"Compliance",
                    element:"div",
                    className:"text-primary mt-2 font-semibold text-lg w-full "
                },
                {
                    className: 'bg-white',
                    name: "compliance_table",
                    element: 'table',
                    loading: false,
                    variant: 'plain',
                    count:0,
                    size: 'small',
                    pagination: true, 
                    view: true,
                    visible: true,
                    thead: [
                        {
                            name:"agent", label:"Agent",key:"agent"
                        },
                      {
                        name: 'answered',
                        label: 'Answered ',
                        key: 'answered',
                      },
                      { name: 'effeciency', label: 'Effeciency', key: 'effeciency',},
                      {
                        name: 'avg_quality_score',
                        label: 'Avg Quality Score',
                        key: 'avg_quality_score',
                      },
                      {
                        name: 'avg_sentiment_score',
                        label: 'Avg Sentiment Score',
                        key: 'avg_sentiment_score',
                      }
                    ],
                    tbody: [
                        {
                            agent:"9701281777",
                            total:"100",
                            missed:"40",
                            answered:"60",
                            return_calls:"20",
                            effeciency:"60%",
                            min_duration:"10sec",
                            max_duration:"2min",
                            avg_duration:"1min 5sec",
                            avg_quality_score:"55",
                            avg_sentiment_score:"0.564"

                        },
                        {
                            agent:"9700092134",
                            total:"200",
                            missed:"80",
                            answered:"120",
                            return_calls:"30",
                            effeciency:"60%",
                            min_duration:"2min",
                            max_duration:"5min",
                            avg_duration:"3min 30sec",
                            avg_quality_score:"34",
                            avg_sentiment_score:"1"

                        },
                        {
                            agent:"6789067890",
                            total:"100",
                            missed:"40",
                            answered:"60",
                            return_calls:"20",
                            effeciency:"60%",
                            min_duration:"1min",
                            max_duration:"2min",
                            avg_duration:"1min",
                            avg_quality_score:"10",
                            avg_sentiment_score:"0.567"

                        },
                        {
                            agent:"9078690876",
                            total:"100",
                            missed:"40",
                            answered:"60",
                            return_calls:"20",
                            effeciency:"60%",
                            min_duration:"15sec",
                            max_duration:"2min 5sec",
                            avg_duration:"1min 10 sec",
                            avg_quality_score:"55",
                            avg_sentiment_score:"1"

                        }
                    ],
                },
            ]
          },
          // {
          //   name:"outcomes_table",
          //   className:"flex gap-2 flex-col rounded-md",
          //   fields:[
          //       {
          //           name:"outcomes",
          //           label:"Outcomes",
          //           element:"div",
          //           className:"text-primary mt-2 font-semibold text-lg w-full "
          //       },
          //       {
          //           className: '',
          //           name: "outcomes_table",
          //           element: 'table',
          //           loading: false,
          //           variant: 'plain',
          //           count:0,
          //           size: 'small',
          //           pagination: true, 
          //           view: true,
          //           visible: false,
          //           thead: [
          //             { name: 'name', label: 'Name', key: 'name',
          //             },
          //             {
          //               name: 'phone_number',
          //               label: 'Mobile',
          //               key: 'phone_number',
          //             },
          //             {name: 'type', label: 'Type', key: 'type', render: (e:any) => <p className={`capitalize`}>{e}</p>},
          //             { name: 'source', label: 'Source', key: 'source', render: (e:any) => <p className={`capitalize`}>{e}</p> },
          //             { name: 'status', label: 'Status', key: 'status',},
          //             {
          //               name: 'createdAt',
          //               label: 'Arrived on',
          //               key: 'createdAt',
          //             }
          //           ],
          //           tbody: [],
          //           modalContent: {
          //             className: "flex flex-col gap-2 overflow-auto max-h-[400px] p-[10px]",
          //             fields: [],
          //           },
          //       },
          //       {
          //           name:"outcomes",
          //           fields: [
          //             {
          //               name:"outcomes",
          //               label:"Coming Soon",
          //               element:"div",
          //               className:"font-semibold text-gray-400"
          //             }
          //           ],
          //           className:"w-full h-40 bg-gray-50 rounded-lg flex items-center justify-center"
          //       }
          //   ]
          // }
        ],
    });
    const details = Storage.getItem("details");
    const [dateRange, setDateRange] = useState<[string, string]>([
      moment().subtract(7, 'days').format('DD-MM-YYYY'),
      moment().format('DD-MM-YYYY'),
    ])
    const dispatch=useDispatch();

    useEffect(()=>{
      if(dateRange[0]==="" && dateRange[1]===""){
        change_segments_value(null);
    }
    const first = moment(dateRange[0], 'DD-MM-YYYY');
    const second = moment(dateRange[1], 'DD-MM-YYYY');
    const diffInDays = second.diff(first, 'days');
    const today = moment().startOf('day');

    if (first.isSame(today, 'day') && second.isSame(today, 'day')) {
        change_segments_value("TD")
    } else if (diffInDays === 7 && second.isSame(today, 'day')) {
        change_segments_value("TW")
    } else if (diffInDays === 30 && second.isSame(today, 'day')) {
        change_segments_value("TM")
    }else{
        change_segments_value(null)
    }
      API_GET_CALLS_Data({startDate: dateRange[0], endDate: dateRange[1]})
      API_GET_WORDCLOUD_Data({startDate: dateRange[0], endDate: dateRange[1]})
    },[dateRange])

    const change_segments_value=(val:string | null)=>{
      setSchema((prev:any)=>{
          const updatedSchema=prev.schema.map((field:any)=>{
              if(field.name==="filters"){
                field.fields=field.fields.map((ffield:any)=>{
                  if(ffield.name==="date_and_search"){
                      ffield.fields=ffield.fields.map((fffield:any)=>{
                          if(fffield.name==="segments"){
                              fffield.value=val;
                          }
                          return fffield;
                      })
                  }
                  return ffield;
                })
              }
              return field;
          })
          return {...prev,schema:updatedSchema}
      })
    }

    const API_GET_CALLS_Data=async ({ source = "calls", startDate = "", endDate = "" })=>{
      dispatch(setLoading(true))
      try {
          const res: any = await Utils.makeApiCall(`/analytics?org_id=${details?.["org_id"]}&type=${source}&startDate=${startDate}&endDate=${endDate}`, "GET");
          if (res.status) {
              change_calls_data(res.data);

          } else {
              toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
          }
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
          console.error('Failed to fetch schema', error);
      }
      dispatch(setLoading(false))
    }

    const API_GET_WORDCLOUD_Data=async ({ startDate = "", endDate = "" })=>{
      dispatch(setLoading(true))
      try {
          const res: any = await Utils.makeApiCall(`/getWordCloud?start_date=${moment(startDate, "DD-MM-YYYY").format("YYYY-MM-DD")}&end_date=${moment(endDate, "DD-MM-YYYY").format("YYYY-MM-DD")}`, "GET");
          if (res.status) {
              change_wordclouds_data(res.data);

          } else {
              toast.error(res.message ? res.message : FAILURE_MSG, { position: 'top-center', autoClose: AUTO_CLOSE })
          }
      } catch (error) {
          toast.error(CATCH_ERROR_MSG, { position: 'top-center', autoClose: AUTO_CLOSE });
          console.error('Failed to fetch schema', error);
      }
      dispatch(setLoading(false))
    }

    const change_calls_data = (data: any) => {
      setSchema((prevSchema: any) => {
        const updatedSchema = [...prevSchema.schema];
        const graphsAndImagesIndex = updatedSchema.findIndex(item => item.name === 'graphs_and_images');
        
        if (graphsAndImagesIndex !== -1) {
          const pieAndGraphIndex = updatedSchema[graphsAndImagesIndex].fields.findIndex((field: { name: string; }) => field.name === 'suggestions3');
          
          if (pieAndGraphIndex !== -1) {
            const callsDataIndex = updatedSchema[graphsAndImagesIndex].fields[pieAndGraphIndex].fields.findIndex((field: { name: string; }) => field.name === 'calls_data');
            
            if (callsDataIndex !== -1) {
              updatedSchema[graphsAndImagesIndex].fields[pieAndGraphIndex].fields[callsDataIndex].data = data;
            }
          }
        }
    
        return { ...prevSchema, schema: updatedSchema };
      });
    };

    const change_wordclouds_data = (data: any) => {
      setSchema((prevSchema: any) => {
        const updatedSchema = [...prevSchema.schema];
        const graphsAndImagesIndex = updatedSchema.findIndex(item => item.name === 'graphs_and_images');
        
        if (graphsAndImagesIndex !== -1) {
          const wordCloudTableIndex = updatedSchema[graphsAndImagesIndex].fields.findIndex(
            (field: { name: string; }) => field.name === 'word_cloud_table'
          );
          
          if (wordCloudTableIndex !== -1) {
            const wordCloudIndex = updatedSchema[graphsAndImagesIndex].fields[wordCloudTableIndex].fields.findIndex(
              (field: { name: string; }) => field.name === 'word_cloud_data'
            );
            
            if (wordCloudIndex !== -1) {
              // Update the word cloud data
              updatedSchema[graphsAndImagesIndex].fields[wordCloudTableIndex].fields[wordCloudIndex].data = data;
            }
          }
        }
    
        return { ...prevSchema, schema: updatedSchema };
      });
    };

    const handleSelectedRecord=(e:any)=>{
      if (e.name === "segments") {
        if (e.value === "TD") {
            setDateRange([
                moment().format('DD-MM-YYYY'),
                moment().format('DD-MM-YYYY'),
            ]);
            setSchema((prev: any) => {
                const updatedSchema = prev.schema.map((field: any) => {
                    if (field.name === "filters") {
                        field.fields = field.fields.map((ffield: any) => {
                            if (ffield.name === "date_and_search") {
                                ffield.fields = ffield.fields.map((ff: any) => {
                                    if (ff.name === "date") {
                                        ff.value = [
                                            moment().format('DD-MM-YYYY'),
                                            moment().format('DD-MM-YYYY'),
                                        ];
                                    }
                                    return ff;
                                });
                            }
                            return ffield;
                        });
                    }
                    if(field.element==="table"){
                      field.value={
                          name: 'pagination',
                          page: 1,
                          pageSize: 10,
                      }
                  }
                    return field;
                });
                return { ...prev, schema: updatedSchema };
            });
        }
        if (e.value === "TM") {
            setDateRange([
                moment().subtract(30, 'days').format('DD-MM-YYYY'),
                moment().format('DD-MM-YYYY'),
            ]);
            setSchema((prev: any) => {
                const updatedSchema = prev.schema.map((field: any) => {
                    if (field.name === "filters") {
                        field.fields = field.fields.map((ffield: any) => {
                            if (ffield.name === "date_and_search") {
                                ffield.fields = ffield.fields.map((ff: any) => {
                                    if (ff.name === "date") {
                                        ff.value = [
                                            moment().subtract(30, 'days').format('DD-MM-YYYY'),
                                            moment().format('DD-MM-YYYY'),
                                        ];
                                    }
                                    return ff;
                                });
                            }
                            return ffield;
                        });
                    }
                    if(field.element==="table"){
                      field.value={
                          name: 'pagination',
                          page: 1,
                          pageSize: 10,
                      }
                  }
                    return field;
                });
                return { ...prev, schema: updatedSchema };
            });
        }
        if (e.value === "TW") {
            setDateRange([
                moment().subtract(7, 'days').format('DD-MM-YYYY'),
                moment().format('DD-MM-YYYY'),
            ]);
            setSchema((prev: any) => {
                const updatedSchema = prev.schema.map((field: any) => {
                    if (field.name === "filters") {
                        field.fields = field.fields.map((ffield: any) => {
                            if (ffield.name === "date_and_search") {
                                ffield.fields = ffield.fields.map((ff: any) => {
                                    if (ff.name === "date") {
                                        ff.value = [
                                            moment().subtract(7, 'days').format('DD-MM-YYYY'),
                                            moment().format('DD-MM-YYYY'),
                                        ];
                                    }
                                    return ff;
                                });
                            }
                            return ffield;
                        });
                    }
                    if(field.element==="table"){
                      field.value={
                          name: 'pagination',
                          page: 1,
                          pageSize: 10,
                      }
                  }
                    return field;
                });
                return { ...prev, schema: updatedSchema };
            });
        }
      }
      if (e.name === "date") {
        if (e.value === "") {
          setDateRange(["", ""]);
        } else {
          setDateRange(e.value);
        }
      }
    }

  return (
    <div>
      <ElementExecutor
        data={schema}
        selectedRecord={(e:any)=>handleSelectedRecord(e)}
      />
    </div>
  )
}

export default CallsReport
