"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableCopyComponent = void 0;
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
const Button_1 = require("./Button");
const SwitchElement_1 = require("./SwitchElement");
const icons_1 = require("@ant-design/icons");
const TableCopyComponent = (props) => {
    const { thead, tbody } = props;
    const [dataSource, setDataSource] = (0, react_1.useState)([]);
    const [filters, setFilters] = (0, react_1.useState)(() => {
        const filterItem = Array.isArray(props.value)
            ? props.value.find((item) => item.name === 'filters')
            : null;
        console.log('filyerItem==========', filterItem);
        return {
            source: filterItem?.value.source || [],
            status: filterItem?.value.status || [],
        };
    });
    const [pagination, setPagination] = (0, react_1.useState)(() => {
        const paginationItem = Array.isArray(props.value)
            ? props.value.find((item) => item.name === 'pagination')
            : null;
        return {
            page: paginationItem?.page || 1,
            pageSize: paginationItem?.pageSize || 10,
        };
    });
    const [expandedRowKeys, setExpandedRowKeys] = (0, react_1.useState)([]);
    (0, react_1.useEffect)(() => {
        if (Array.isArray(props.value)) {
            const paginationItem = props.value.find((item) => item.name === 'pagination');
            const filtersItem = props.value.find((item) => item.name === 'filters');
            if (paginationItem) {
                setPagination({
                    page: paginationItem.page || 1,
                    pageSize: paginationItem.pageSize || 10,
                });
            }
            if (filtersItem) {
                setFilters({
                    source: filtersItem.value.source || [],
                    status: filtersItem.value.status || [],
                });
            }
        }
    }, [props.value]);
    console.log('----------', props.value);
    console.log('pagination----------', pagination);
    console.log('filters----------', filters);
    (0, react_1.useEffect)(() => {
        if (tbody) {
            setDataSource(tbody.map((row, index) => {
                const pagnationIdx = ((pagination.page || 1) - 1) * (pagination.pageSize || 10);
                return {
                    ...row,
                    key: row.id || pagnationIdx + index + 1,
                    index: pagnationIdx + index + 1,
                    children: row.children && row.children.length > 0
                        ? row.children
                        : undefined,
                };
            }));
        }
    }, [tbody, pagination]);
    (0, react_1.useEffect)(() => {
        // Automatically expand the first record with children
        const firstRecordWithChildren = dataSource.find((record) => record.children && record.children.length > 0);
        if (firstRecordWithChildren?.key !== undefined) {
            setExpandedRowKeys([firstRecordWithChildren.key]); // Use the key of the record, not a hardcoded value
        }
    }, [dataSource]);
    const handleDelete = (record) => {
        props.onChange && props.onChange({ name: record.key, value: record });
    };
    const handleSwitch = (record) => {
        const newDataSource = dataSource.map((item) => {
            if (item.id === record.id) {
                const updatedRecord = { ...item, value: !item.value };
                props.onChange &&
                    props.onChange({ name: record.key, value: updatedRecord });
                return updatedRecord;
            }
            return item;
        });
        setDataSource(newDataSource);
    };
    const handleTableChange = (_pagination, newFilters, _sorter, extra) => {
        void _sorter;
        if (extra.action === 'paginate') {
            setPagination({
                page: _pagination.current ?? 1,
                pageSize: _pagination.pageSize ?? 10,
            });
            if (props.onChange) {
                props.onChange([
                    {
                        name: 'pagination',
                        page: _pagination.current ?? 1,
                        pageSize: _pagination.pageSize ?? 10,
                    },
                    { name: 'filters', value: filters },
                ]);
            }
        }
        else if (extra.action === 'filter') {
            if (props.onChange) {
                setFilters({
                    source: newFilters.source,
                    status: newFilters.status,
                });
                setPagination({
                    page: 1,
                    pageSize: _pagination.pageSize ?? 10,
                });
                props.onChange([
                    { name: 'filters', value: newFilters },
                    {
                        name: 'pagination',
                        page: pagination.page,
                        pageSize: pagination.pageSize,
                    },
                ]);
                // props.onChange({
                //   name: 'pagination',
                //   page: 1,
                //   pageSize: _pagination.pageSize,
                // });
            }
        }
    };
    let columns = [];
    if (thead) {
        columns = [
            ...(props.showSerialNumber || props.showSerialNumber === undefined
                ? [
                    {
                        title: '#',
                        dataIndex: 'index',
                        key: 'index',
                        fixed: 'left',
                    },
                ]
                : []),
            ...thead.map((col, index) => ({
                title: col.label,
                dataIndex: col.name,
                key: col.key,
                fixed: col.fixed,
                ellipsis: col.ellipsis,
                width: props.colWidth || 'auto',
                render: index === 0 && props.expandable
                    ? (text, record) => (react_1.default.createElement("span", { onClick: (e) => {
                            if (record.children && record.children.length > 0) {
                                e.stopPropagation();
                                setExpandedRowKeys((prev) => prev.includes(record.key)
                                    ? prev.filter((key) => key !== record.key)
                                    : [...prev, record.key]);
                            }
                        }, style: record.children?.length
                            ? {
                                cursor: 'pointer',
                                fontWeight: '600',
                                marginLeft: '8px',
                                textTransform: 'capitalize',
                            }
                            : { cursor: 'default' } }, text))
                    : col.render,
                filters: col.filters?.map((filter) => ({
                    text: filter.text,
                    value: filter.value,
                })),
                filterMultiple: true,
                defaultFilteredValue: col.name === 'source'
                    ? filters.source
                    : col.name === 'status'
                        ? filters.status
                        : undefined,
                sorter: col.sortable ||
                    (col.sortable === undefined && {
                        compare: (a, b) => {
                            const aValue = a[col.key];
                            const bValue = b[col.key];
                            if (typeof aValue === 'number' && typeof bValue === 'number') {
                                return aValue - bValue;
                            }
                            else if (typeof aValue === 'string' &&
                                typeof bValue === 'string') {
                                return aValue.localeCompare(bValue);
                            }
                            else {
                                return 0;
                            }
                        },
                    }),
            })),
        ];
        if (props.isDelete) {
            columns?.push({
                title: 'Delete',
                dataIndex: 'Delete',
                key: 'Delete',
                render: (_, record) => {
                    return (react_1.default.createElement(antd_1.Popconfirm, { title: "Delete the task", description: "Are you sure to delete this task?", icon: react_1.default.createElement(icons_1.QuestionCircleOutlined, { style: { color: 'red' } }), onConfirm: () => handleDelete(record), okText: "Delete", cancelText: "Cancel" },
                        react_1.default.createElement(antd_1.Tooltip, { title: "Delete" },
                            react_1.default.createElement("p", null, "Del"),
                            react_1.default.createElement(Button_1.ButtonElement, { icon: "discard" }))));
                },
            });
        }
        if (props.isStatus) {
            columns?.splice(-1, 0, {
                title: 'Status',
                dataIndex: 'Status',
                key: 'Status',
                render: (_, record) => {
                    return (react_1.default.createElement(SwitchElement_1.SwitchElement, { value: record.value, onChange: () => {
                            handleSwitch(record);
                        } }));
                },
            });
        }
    }
    // const onChangePage = (page: number, pageSize: number) => {
    //   setPagination({ page, pageSize });
    //   if (props.onChange) {
    //     props.onChange({ name: 'pagination', page, pageSize });
    //   }
    // };
    const rowSelectionConfig = props.rowSelection
        ? {
            onChange: (selectedRows) => {
                if (props.onChange) {
                    props.onChange(selectedRows);
                }
            },
        }
        : undefined;
    const handleRowClick = (record) => {
        if (props.onChange) {
            props.onChange({ name: 'view', value: { ...record } }); // Pass the global index
        }
    };
    return (react_1.default.createElement("div", { style: { width: '100%', overflowX: 'auto' } },
        react_1.default.createElement(antd_1.Table, { style: { userSelect: 'none' }, loading: props.loading, className: props.className, scroll: { x: 'max-content' }, 
            // scroll={{ y: 500 }}
            pagination: props.pagination
                ? {
                    current: pagination.page,
                    pageSize: pagination.pageSize,
                    showTotal: (total) => `Total: ${total} items`,
                    total: props.count,
                    showSizeChanger: props.count ? props.count > 10 : 0 > 10,
                    // onChange: () => {
                    //   clickedKeyRef.current = 'pagination';
                    // },
                    // onChange: onChangePage,
                }
                : false, rowSelection: rowSelectionConfig, dataSource: dataSource, columns: columns, size: props.size, bordered: true, expandable: {
                expandedRowKeys,
                onExpand: (expanded, record) => {
                    setExpandedRowKeys((prevExpandedRowKeys) => {
                        if (expanded) {
                            // Add the key to expandedRowKeys if expanding
                            return [...prevExpandedRowKeys, record.key];
                        }
                        else {
                            // Remove the key from expandedRowKeys if collapsing
                            return prevExpandedRowKeys.filter((key) => key !== record.key);
                        }
                    });
                },
                rowExpandable: (record) => !!(record.children && record.children.length > 0),
                // defaultExpandedRowKeys,
                expandIcon: ({ expanded, onExpand, record, }) => record.children && record.children.length > 0 ? (expanded ? (react_1.default.createElement(icons_1.DownOutlined, { onClick: (e) => onExpand(record, e) })) : (react_1.default.createElement(icons_1.RightOutlined, { onClick: (e) => onExpand(record, e) }))) : null,
            }, onChange: handleTableChange, onRow: props.rowClick
                ? (record) => ({
                    onClick: () => handleRowClick(record),
                })
                : undefined, rowClassName: () => (props.rowClick ? 'cursor-pointer' : '') })));
};
exports.TableCopyComponent = TableCopyComponent;
