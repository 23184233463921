import {createSlice} from "@reduxjs/toolkit"
import moment from "moment"
import { ICallsState } from "./types";

const initialState:ICallsState={
    pagination:{
        page:1,
        pageSize:10,
    },
    dateRange:[moment().subtract(7, 'days').format('DD-MM-YYYY'), moment().format("DD-MM-YYYY")],
    searchText:"",
    filters:{status:[],source:[]}
}

const callSlice=createSlice({
    name:"calls",
    initialState,
    reducers:{
        setPagination:(state,action)=>{
            state.pagination=action.payload;
        },
        setDateRange:(state,action)=>{
            state.dateRange=action.payload
        },
        setSearchText:(state,action)=>{
            state.searchText=action.payload;
        },
        setFilters:(state,action)=>{
            state.filters=action.payload;
        },
        
    }

})


export const {setPagination,setDateRange,setSearchText,setFilters}=callSlice.actions;
export default callSlice.reducer;