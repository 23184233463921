import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILeadInitState } from "./types";

const initialState: ILeadInitState = {
    activeTab: "comments"
} 
const leadSlice = createSlice({
    name: "lead",
    initialState,
    reducers: {
        setActiveTab: (state, action: PayloadAction<string>) =>{
            state.activeTab = action.payload;
        }
    }
})
export const { setActiveTab } = leadSlice.actions;
export default leadSlice.reducer;