import { combineReducers } from '@reduxjs/toolkit';
import uiSchemaReducer from './uiSchema/uiSchemaSlice';
import campaignReducer from './campaigns/campaignSlice';
import leadReducer from './leads/leadSlice';
import authReducer from './auth/authSlice';
import appStateReducer from "./appState/appStateSlice";
import compareUnitsReducer from './compareUnits/compareUnitsSlice'
import patientReducer from "./patients/patientSlice";
import chatReducer from "./patientChats/chatSlice"
import leadFiltersReducer from "./leads/leadFilterSlice"
import callsReducer from "./calls/callSlice"
const rootReducer = combineReducers({
  appState: appStateReducer,
  uiSchema: uiSchemaReducer,
  campaign: campaignReducer,
  auth: authReducer,
  lead: leadReducer,
  compareUnits:compareUnitsReducer,
  patient:patientReducer,
  patientChats:chatReducer,
  leadFilters:leadFiltersReducer,
  calls:callsReducer
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
