"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RadioElement = void 0;
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
const RadioElement = (props) => {
    const [isDisabled, setIsDisabled] = (0, react_1.useState)(props.disabled);
    const [selectedValue, setSelectedValue] = (0, react_1.useState)(props.value);
    const [val, setVal] = (0, react_1.useState)(props.value);
    // console.log('valuefrom props', props.value);
    const optionType = props.optionType === 'button' ? 'button' : undefined;
    (0, react_1.useEffect)(() => {
        setIsDisabled(props.disabled);
    }, [props.disabled]);
    const handleChange = (option) => {
        setSelectedValue(option);
        setVal(option.value);
        if (props.isNotChatbot === true) {
            setIsDisabled(false);
        }
        else {
            setIsDisabled(true);
        }
        if (props.onChange) {
            props.onChange(option);
        }
    };
    const getButtonStyle = (option) => {
        return selectedValue === option ? props.activeClassName : props.className;
    };
    return (react_1.default.createElement("div", { className: props.containerClassName },
        props.label && (react_1.default.createElement("p", { className: props.labelClassName },
            props.label,
            ' ',
            props.required ? react_1.default.createElement("span", { style: { color: 'red' } }, "*") : '')),
        react_1.default.createElement(antd_1.Radio.Group, { optionType: optionType, className: props.radioGroupClassName, value: val }, props.options &&
            props.options.map((option) => {
                return (react_1.default.createElement(antd_1.Radio, { onChange: () => {
                        handleChange(option);
                    }, key: option.value, value: option.value, disabled: isDisabled && selectedValue !== option.value, className: getButtonStyle(option), style: {
                        pointerEvents: isDisabled && selectedValue !== option.value
                            ? 'none'
                            : 'auto',
                    } }, option.label));
            }))));
};
exports.RadioElement = RadioElement;
// import React, { useEffect, useState } from 'react';
// import { Radio } from 'antd';
// import { ElementType } from '../Types/types';
// export const RadioElement = (props: ElementType) => {
//   const [isDisabled, setIsDisabled] = useState(props.disabled);
//   const [selectedValue, setSelectedValue] = useState(props.value);
//   const [val, setVal] = useState(props.value);
//   const optionType: 'button' | undefined =
//     props.optionType === 'button' ? 'button' : undefined;
//   useEffect(() => {
//     setIsDisabled(props.disabled);
//     setSelectedValue(props.value);
//   }, [props.disabled, props.value]);
//   const handleChange = (option: string | undefined) => {
//     setSelectedValue(option);
//     setVal(option && option);
//     if (props.isNotChatbot === true) {
//       setIsDisabled(false);
//     } else {
//       setIsDisabled(true);
//     }
//     if (props.onChange) {
//       props.onChange(option);
//     }
//   };
//   const getButtonStyle = (option: {
//     key?: string | undefined;
//     label?: string | undefined;
//     value?: string | undefined;
//   }) => {
//     return selectedValue === option ? props.activeClassName : props.className;
//   };
//   return (
//     <div className={props.containerClassName}>
//       {props.label && (
//         <p className={props.labelClassName}>
//           {props.label}{' '}
//           {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
//         </p>
//       )}
//       <Radio.Group
//         optionType={optionType}
//         className={props.radioGroupClassName}
//         value={val}
//       >
//         {props.options &&
//           props.options.map((option) => {
//             return (
//               <Radio
//                 onChange={() => {
//                   handleChange(option.value);
//                 }}
//                 key={option.value}
//                 value={option.value}
//                 disabled={isDisabled && selectedValue !== option.value}
//                 className={getButtonStyle(option)}
//                 style={{
//                   pointerEvents:
//                     isDisabled && selectedValue !== option.value
//                       ? 'none'
//                       : 'auto',
//                 }}
//               >
//                 {option.label}
//               </Radio>
//             );
//           })}
//       </Radio.Group>
//     </div>
//   );
// };
