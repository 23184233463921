import React from "react"

const groupedStyles = {
  GREEN: {
    labels: ["answered", "Answered"] as const,
    style: "bg-[#4DA723]/5 text-[#4DA723]",
  },
  RED: {
    labels: ["Ambulance Request", "missed", "Missed"] as const,
    style: "bg-[#F9A07A]/5 text-[#F37748]",
  },
  SKYBLUE: {
    labels: [
      "callback",
      "call_attempt",
      "Call Back Request",
      "Chatbot",
    ] as const,
    style: "bg-[#29AAE1]/5 text-[#29AAE1]",
  },
  ORANGE: {
    labels: ["Client Hungup", "Lead"] as const,
    style: "bg-[#F59F0D]/5 text-[#F59F0D]",
  },
  YELLOW: {
    labels: ["In-Progress"] as const,
    style: "bg-[#FFB531]/5 text-[#FFB531]",
  },
  FUCHSIA: {
    labels: ["Qualified"] as const,
    style: "bg-fuchsia-50 text-fuchsia-500",
  }
} as const

type TagName =
  (typeof groupedStyles)[keyof typeof groupedStyles]["labels"][number]

interface Type {
  get: (name: TagName) => string
}

const LabelTags: Type = {
  get: (name) => {
    for (const group of Object.values(groupedStyles)) {
      if ((group.labels as readonly string[]).includes(name)) {
        return group.style
      }
    }
    return "bg-[#F2F2F2]/50 text-default-300"
  },
}

export default LabelTags
