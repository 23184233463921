import { ElementExecutor } from "@apexcura/core";
import React, { useEffect, useState } from "react";
import Utils from "../../utils";
import moment from "moment";
import { toast } from "react-toastify";
import LoadingState from "../LoadingState";
import { CONSTANTS } from "../../utils/app-constants";
import Schema from "../../utils/schema";
import { useLocation, useNavigate } from "react-router-dom";
import Storage from "../../utils/local-storage";
import { useDispatch, useSelector } from "react-redux";
import { getPagePermissions } from "../../redux/auth/authSlice";
import { setActiveMenu, setTopbar } from "../../redux/uiSchema/uiSchemaSlice";
import axios from "axios";
import { Tooltip,Avatar } from "antd";
import {read, utils} from 'xlsx';
import * as XLSX from 'xlsx';
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;


const RejectReasonStyles:any = {
  "Duplicate": "bg-cyan-500/5 text-cyan-500",
  "Data Missing": "bg-red-500/5 text-red-500",
};


const uploadForm = () =>{
    return {
        name: "file_upload_body",
        className: "flex flex-col gap-2 w-full py-6",
        fields: [
          {
            className:"flex flex-col gap-2 w-full",
            name:"upload",
            fields:[
              {
                name:"",
                className:"flex items-center justify-between",
                fields: [
                  {
                    name:"",
                    element:"div",
                    label:"Upload Departments File",
                    className:"text-blue-800 font-semibold text-2xl "
                  },
                  {
                    name: "",
                    className: "flex gap-2 items-center",
                    fields: [
                      {
                        name: 'download_sample_xlsx',
                        label: 'Sample XLSX',
                        element: 'button',
                        icon: 'download',
                        className: 'secondary-button',
                        action: 'download_list',
                        iconsClassName: 'size-4',
                      },
                      {
                        name: 'download_sample_csv',
                        label: 'Sample CSV',
                        element: 'button',
                        icon: 'download',
                        className: 'secondary-button',
                        action: 'download_list',
                        iconsClassName: 'size-4',
                      },
                    ]
                  }
                ]
              },
              {
                name: "files",
                element: "upload",
                label: "Upload",
                accept: ".xlsx, .csv",
                allowOne:true,
                className: "",
                required: true,
                visible: true,
                value: null,
              },
              
            ]
          },
          // {
          //   name: "cancel_and_submit_file_upload",
          //   fields: [
          //     {
          //       name: "cancel_validation",
          //       label: "Cancel",
          //       element: "button",
          //       className:
          //         "p-2 px-3  text-default-500 bg-gray-200 text-black rounded-md font-semibold",
          //     },
          //     {
          //       name: "submit_upload",
          //       label: "Upload File",
          //       element: "button",
          //       className:
          //         "p-2 px-3  text-default-500 bg-primary  rounded-md text-white font-semibold",
          //     },
          //   ],
          //   className: "flex self-end gap-2",
          // },
        ]
      }
}

const fieldsMapSchema = () => {
    return {
      name: "fields_map_container",
      className: "p-4 flex flex-col gap-3 ",
      visible: false,
      fields: [
        {
          name: "",
          className: "w-full border-b-2 pt-6 border-gray-200",
          element: 'div'
        },
        {
          name: "",
          className:"text-blue-800 font-semibold text-2xl pt-6",
          label: "Map your fields",
          element: "div",
        },
        {
          name: "fields_map_title",
          className:"text-gray-400 text-sm",
          label: "The data fields from our database are provided below. Beside them, the fields from your uploaded file are provided in select input. Match the relevent fields!! Note that every field is mandatory for each department and it should be unique!!",
          element: "div",
        },
        {
          name: "fields_map_body",
          className: "flex flex-col gap-3",
          fields: [
            {
              name: "",
              className: "grid grid-cols-2 gap-3",
              fields: [
                {
                  name: "fields_map_label_name",
                  className: "col-span-1 justify-center flex items-center text-xl text-gray-500",
                  label: "Fields in our Database",
                  element: "div",
                },
                {
                  name: "fields_map_value_name",
                  className: "w-full col-span-1 justify-center flex items-center text-xl text-gray-500",
                  label: "Fields from your file",
                  element: "div",
                },
              ]
            },
            {
              name: "fields_map_name",
              className: "grid grid-cols-2 gap-3",
              fields: [
                {
                  name: "fields_map_label_dept_code",
                  className: "col-span-1 justify-end flex items-center",
                  label: "",
                  element: "input-text",
                  value: "dept_code",
                  disabled: true
                },
                {
                  name: "fields-map-value-dept_code",
                  className: "w-full",
                  containerClassName: 'col-span-1 ',
                  placeholder: "Choose the equivalent field from your file",
                  element: "single-select",
                  options: [],
                  value: []
                },
              ]
            },
            {
              name: "fields_map_mobile",
              className: "grid grid-cols-2 gap-3",
              fields: [
                {
                  name: "fields_map_label_mobile",
                  className: "col-span-1 justify-end flex items-center",
                  label: "",
                  element: "input-text",
                  value: "dept_name",
                  disabled: true
                },
                {
                  name: "fields-map-value-dept_name",
                  className: "w-full",
                  placeholder: "Choose the equivalent field from your file",
                  containerClassName: 'col-span-1',
                  element: "single-select",
                },  
              ]
            },
          ]
        },
        {
          name: "cancel_and_submit_fields_mapping",
          fields: [
            {
              name: "proceed_fields_mapping",
              label: "Next",
              element: "button",
              className:
                "p-2 px-3  text-default-500 bg-primary  rounded-md text-white font-semibold",
            },
          ],
          className: "flex self-end gap-2 ",
        },
      ]
    }
  }

const validationSuccessTable = () =>{
    return {
      name: "file_validation_success_body",
      className: "w-full flex flex-col",
      fields: [
        {
          name: 'filters',
          className: 'flex justify-between items-center gap-2 bg-white',
          fields: [
            {
              name: "search_valid_records",
              label: "",
              placeholder: "Code, Name, ...",
              isSearch:true,
              element: "input-text",
              value: "",
              className: "w-full",
              containerClassName: "w-[40%]",
              icon:"search",
              iconsClassName:"w-[15px] flex justify-center items-center"
            },
            {
              name: 'btn_download_valid',
              label: 'Download',
              element: 'button',
              icon: 'download',
              className: 'secondary-button',
              action: 'download_list',
              iconsClassName: 'size-4',
            },
          ],
          
        },
        {
          name: "file_validation_table",
          className: 'py-3',
          element: 'table',
          variant: 'plain',
          size: 'small',
          pagination: true,
          value: { name: 'pagination', page: 1, pageSize: 10 },
          thead: [
              {
                name: "dept_code",
                label: "Department Code",
                key: "dept_code",
                ellipsis:true
              },
              {
                name: "dept_name",
                label: "Department Name",
                key: "dept_name",
              }
                            
            ],
          tbody: [],
        },
        {
          name: "",
          className: "text-gray-500 my-2 text-sm",
          element: "div",
          label: "** Further validations will be handled with database and only the unique records will be inserted!! **"
        },
        {
          name: "cancel_and_submit_finish_validation",
          fields: [
            {
              name: "cancel_validation",
              label: "Cancel",
              element: "button",
              className:
                "p-2 px-3  text-default-500 bg-gray-200 text-black rounded-md font-semibold",
            },
            {
              name: "upload_valid_data",
              label: "Upload Valid Records",
              element: "button",
              className:
                "p-2 px-3  text-default-500 bg-primary  rounded-md text-white font-semibold",
            },
          ],
          className: "flex self-end gap-2",
        },
      ]
    }
}

const validationFailedTable = () =>{
  return {
    name: "file_validation_failed_body",
    className: "w-full flex flex-col",
    fields: [
      {
        name: 'filters',
        className: 'flex justify-between items-center gap-2 bg-white',
        fields: [
          {
            name: "search_failed_records",
            label: "",
            placeholder: "Code, Name, ...",
            isSearch:true,
            element: "input-text",
            value: "",
            className: "w-full",
            containerClassName: "w-[40%]",
            icon:"search",
            iconsClassName:"w-[15px] flex justify-center items-center"
          },
          {
            name: 'btn_download_failed',
            label: 'Download',
            element: 'button',
            icon: 'download',
            className: 'secondary-button',
            action: 'download_list',
            iconsClassName: 'size-4',
          },
        ],
        
      },
      {
        name: "file_validation_table",
        className: 'py-3',
        element: 'table',
        variant: 'plain',
        size: 'small',
        pagination: true,
        value: { name: 'pagination', page: 1, pageSize: 10 },
        thead: [
          {
            name: "dept_code",
            label: "Department Code",
            key: "dept_code",
            ellipsis:true
          },
          {
            name: "dept_name",
            label: "Department Name",
            key: "dept_name",
          },
            { name: 'reason', label: 'Reason', key: 'reason',render:(e:any,record:any)=>{
            
              const bgColor:string = RejectReasonStyles[record?.reason] || "bg-[#F2F2F2]/50 text-default-300"
              return <span className={`w-full p-1 px-2 rounded-md font-medium ${bgColor}`}>{e}</span>
            }  },
                          
          ],
        tbody: [],
      },
    ]
  }
}

const tabsBody = () =>{
  return {
      name: "tabs",
      element: "tabs",
      containerClassName: "p-0 m-0",
      className: "flex items-center gap-1",
      options: [
        {
          key: "valid_records",
          label: "Valid Records",
        },
        {
          key: "failed_records",
          label: "Failed Records",
        },
      ],
      value: {
        key: "valid_records",
        label: "Valid Records",
      }
    }
  
}

const validationTableBody = () =>{
  return {
    name: "validation_table_container",
    className: "",
    visible: false,
    fields: [
      {
        name: "",
        className: "w-full border-b-2 pt-6 border-gray-200",
        element: 'div'
      },
      {
        name: "fields_map_title",
        className:"text-blue-800 font-semibold text-2xl pt-6 pb-3",
        label: "Validated records",
        element: "div",
      },
      {
        ...tabsBody()
      },
      {
        name: "validation_table_body",
        className: "pt-6",
        fields: [
          {...validationSuccessTable()}
        ]
      }
    ]
  }
}

const UploadDepartments = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const navigate=useNavigate()
    const org_id = Storage.getItem("details")?.["org_id"];
    const branch_id = Storage.getItem("details")?.["branch_id"]
    const dispatch=useDispatch();

    const [allFileRecords, setAllFileRecords] = useState<any>([])

    const [allUploadedFileKeys, setallUploadedFileKeys] = useState<any>([])

    const [fileType, setFileType] = useState("")

    const sampleXLSXTHEAD = [
      {
        name: "dept_code",
        label: "Department Code",
        key: "dept_code",
      },
      {
        name: "dept_name",
        label: "Department Name",
        key: "dept_name",
      },            
    ]

    const d1 = moment(new Date("05-10-1999")).format("MM-DD-YYYY")
    const d2 = moment(new Date("11-02-2001")).format("MM-DD-YYYY")
    const d3 = moment(new Date("03-12-1984")).format("MM-DD-YYYY")

    const sampleCSVData = [
      {
        dept_code: "dept001",
        dept_name: "ENT",
      },
      {
        dept_code: "dept002",
        dept_name: "Eye",
      },
      {
        dept_code: "dept003",
        dept_name: "Dental",
      },
    ]  
    const sampleXLSXData = [
      {
        dept_code: "dept001",
        dept_name: "ENT",
      },
      {
        dept_code: "dept002",
        dept_name: "Eye",
      },
      {
        dept_code: "dept003",
        dept_name: "Dental",
      },
    ]   

    const [schema, setSchema] = useState<any>({
        className: "w-full min-h-[80vh] bg-white rounded-lg p-8 flex flex-col gap-3 items-center",
        schema: [
            {
                name: "upload_patients_page",
                className: "w-full",
                fields: [
                    
                    {...uploadForm()},
                    {...fieldsMapSchema()},
                    {...validationTableBody()},
                ]
            }
        ]
      });



      const UPLOAD_PATIENTS_FILE = async() =>{
        setSchema((prev: any)=>{
          prev?.schema?.map((f: any)=>{
            if(f.name === "upload_patients_page"){
              f?.fields?.map((ff: any)=>{
                if(ff.name === "file_upload_body"){
                  ff?.fields?.map((fff: any)=>{
                    if(fff.name === "upload"){
                      fff?.fields?.map((s: any)=>{
                        if(s.name === "files"){
                          if(s?.value?.length > 0){
                            const file = s.value[0].originFileObj
                            if(file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
                              setFileType('xlsx')
                            }else if(file.type === "text/csv"){
                              setFileType('csv')
                            }
                            
                            const reader = new FileReader()
                            reader.onload = async (e: any) => {
                                const workbook = read(e.target.result)
        
                                const sheet = workbook.SheetNames 
                                const data: any = utils.sheet_to_json(workbook.Sheets[sheet[0]])
        
                                setAllFileRecords(data);
      
                                if(data.length > 0){
                                  const keys: any = Object.keys(data[0]);
                                  const options: any = []
                                  await keys?.map((key: any)=>{
                                    const option: any = {label: key, value: key}
                                    options.push(option) 
                                  })
  
                                  setallUploadedFileKeys(options)
  
                                }else{
                                  toast.error("No records found!!", { position: "top-center", autoClose: AUTO_CLOSE })
                                }
                            }
                            reader.readAsArrayBuffer(file)
      
                          }else{
                            toast.error("Data file missing....!", { position: "top-center", autoClose: AUTO_CLOSE })
                          }
                         
                        }
                      })
                      
                    }
                  })
                }
              })
            }
          })
          return {...prev}
        })
      }

    useEffect(()=>{

      if(allUploadedFileKeys.length > 0){
        setSchema((prev: any)=>{
          prev?.schema?.map((f: any)=>{
            if(f.name === "upload_patients_page"){
              f?.fields?.map((ff: any)=>{
                if(ff.name === "fields_map_container"){
                  ff.visible = true

                  ff?.fields?.map((fff: any)=>{
                    if(fff.name === "fields_map_body"){
                      fff?.fields?.map((g: any)=>{
                        if(g.name.includes('fields_map_')){
                          g?.fields?.map((gg: any)=>{
                            if(gg.name.includes("fields-map-value-")){
                              gg.options = allUploadedFileKeys
                            }
                          })
                        }
                      })
                    }
                  })
                }
              })
            }
          })
          return {...prev}
        })
      }
    }, [allUploadedFileKeys])

    const [validItems, setValidItems] = useState([]);
  const [failedItems, setFailedItems] = useState([]);

  interface KeyMap {
    [key: string]: string;
  }

  const validateData = (keys: KeyMap) =>{


    const transformedArray = allFileRecords.map((obj: any) => {
      const newObj: any = {};
      for (const [newKey, oldKey] of Object.entries(keys)) {
        if(newKey === "mobile"){
          
          newObj[newKey] = (obj[oldKey] !== null && obj[oldKey] !== undefined) ? obj[oldKey].toString() : ""
        }else{
          newObj[newKey] = obj[oldKey]
        }
      }
      return newObj;
    });
    console.log(transformedArray, allFileRecords);

    const occurrences = new Map();
    const validItems: any = [];
    const failedItems: any = [];

    transformedArray.forEach((item: any) => {
      const key = item?.dept_code;
      occurrences.set(key, (occurrences.get(key) || 0) + 1);
    });

    transformedArray.forEach((item: any) => {
      const key = item?.dept_code;
      if (occurrences.get(key) > 1) {
        if(key === "" || key === null || key === undefined){
          item.reason = "Data Missing"
        }else{
          item.reason = "Duplicate"
        }
        failedItems.push(item);
      } else {
        if(!item?.dept_name|| !item?.dept_code){
          item.reason = "Data Missing"
          failedItems.push(item);
        } else {
          validItems.push(item);
        }
      }
    });

    setValidItems(validItems);
    setFailedItems(failedItems);

    setSchema((prev: any)=>{
      prev?.schema?.map((f: any)=>{
        if(f.name === "upload_patients_page"){
          f?.fields?.map((ff: any)=>{
            if(ff.name === "validation_table_container"){
              ff.visible = true

              ff?.fields?.map((g: any)=>{
                if(g.name === "tabs"){
                  g.options = [
                    {
                      key: "valid_records",
                      label: `Valid Records (${validItems.length || 0})`,
                    },
                    {
                      key: "failed_records",
                      label: `Failed Records (${failedItems.length || 0}) `,
                    },
                  ]
                }
              })
            }
          })
        }
      })
      return {...prev}
    })

    
  }
  
  const handleTableData = () =>{
    
    setSchema((prev: any)=>{
      prev?.schema?.map((f: any)=>{
        if(f.name === "upload_patients_page"){
          f?.fields?.map((ff: any)=>{
            if(ff.name === "validation_table_container"){
              ff?.fields?.map((fff: any)=>{
                if(fff.name === "validation_table_body"){
                  fff?.fields?.map((g: any)=>{
                    if(g.name === "file_validation_success_body"){
                      g?.fields?.map((gg: any)=>{
                        if(gg.name === "file_validation_table"){
                          gg.tbody = validItems
                        }
                      })
                    }
                    if(g.name === "file_validation_failed_body"){
                      g?.fields?.map((gg: any)=>{
                        if(gg.name === "file_validation_table"){
                          gg.tbody = failedItems
                        }
                      })
                    }
                  })
                }
              })
            }
          })
        }
      })
      return {...prev}
    })
  }


    useEffect(()=>{
      handleTableData()
    },[validItems, failedItems])

    
    const handleSelectedRecord = async (e: any) =>{
        console.log(e);
        
        if(e.name === "tabs"){
          
            setSchema((prev: any)=>{
              prev?.schema?.map((f: any)=>{
                if(f.name === "upload_patients_page"){
                  f?.fields?.map((ff: any)=>{
                    if(ff.name === "validation_table_container"){
                      ff?.fields?.map((g: any)=>{
                        if(g.name === "validation_table_body"){
                          if(e.value.key === "valid_records"){

                            g.fields = [ {...validationSuccessTable()}  ]

                          } else if(e.value.key === "failed_records"){

                            g.fields = [ {...validationFailedTable()}  ]

                          }
                        }
                      })
                    }
                  })
                }
              })
              return {...prev}
            })
            handleTableData()
        }

        if(e.name === "files"){
          setLoading(true)
          await UPLOAD_PATIENTS_FILE();
          setTimeout(()=>{
            setLoading(false)
            window.scrollTo({
              top: document.documentElement.scrollHeight + 80,
              behavior: 'smooth',
            });
          },1000)
        }

        if(e.name === "proceed_fields_mapping"){
          setLoading(true)
          const mappedKeys: any = {
            dept_code: "",
            dept_name:""
          }
          
          await schema?.schema?.map((f: any)=>{
            if(f.name === "upload_patients_page"){
              f?.fields?.map((g: any)=>{
                if(g.name === "fields_map_container"){
                  g?.fields?.map((gg: any)=>{
                    if(gg.name === "fields_map_body"){
                      gg?.fields?.map((h: any)=>{
                        if(h.name.includes("fields_map_")){
                          h?.fields?.map((hh: any)=>{
                            if(hh.name.includes("fields-map-value-")){
                              mappedKeys[hh.name?.split("-")?.[3]] = hh?.value?.value 
                              if(hh?.value?.value  === undefined){
                                mappedKeys[hh.name?.split("-")?.[3]] = ""
                              }
                            }
                          })
                        }
                      })
                    }
                  })
                }
              })
            }
          })
          console.log(mappedKeys);
          
          const values = Object.values(mappedKeys);
          const valueSet = new Set();

          let duplicatesFound = false

          for (const value of values) {
            if (valueSet.has(value)  && value !== "" && value !== null && value !== undefined) {
              duplicatesFound =  true;
            }
            valueSet.add(value);
          }
          if(duplicatesFound){
            toast.error("Same fields can't be mapped more than once!!", { position: "top-center", autoClose: AUTO_CLOSE })
          }
          else if((mappedKeys?.dept_code !== null && mappedKeys?.dept_code !== "" && mappedKeys?.dept_code !== undefined) && (mappedKeys?.dept_name !== null && mappedKeys?.dept_name !== "" && mappedKeys?.dept_name !== undefined)){
            validateData(mappedKeys)
          }else{
            toast.error("please map the relevent fields!!", { position: "top-center", autoClose: AUTO_CLOSE })
          }

          setTimeout(()=>{
            setLoading(false);
            
            window.scrollTo({
              top: document.documentElement.scrollHeight,
              behavior: 'smooth',
            });
          },1200)

        }   

        if(e.name === "btn_download_valid"){
          let thead: any = []
          
          schema?.schema?.map((f: any)=>{
            if(f.name === "upload_patients_page"){
              f?.fields?.map((ff: any)=>{
                if(ff.name === "validation_table_container"){
                  ff?.fields?.map((fff: any)=>{
                    if(fff.name === "validation_table_body"){
                      fff?.fields?.map((g: any)=>{
                        if(g.name === "file_validation_success_body"){
                          g?.fields?.map((gg: any)=>{
                            if(gg.name === "file_validation_table"){
                              thead = gg.thead
                            }
                          })
                        }
                        if(g.name === "file_validation_failed_body"){
                          g?.fields?.map((gg: any)=>{
                            if(gg.name === "file_validation_table"){
                              gg.tbody = failedItems
                            }
                          })
                        }
                      })
                    }
                  })
                }
              })
            }
          })

          if(thead.length > 0 && validItems.length > 0){
            await Utils.exportToExcel(thead, validItems, "ValidRecords");
            toast.success("Valid Records Downloaded successfully", { position: "top-center", autoClose: AUTO_CLOSE });
          }else{
            toast.error("No data available to download", { position: "top-center", autoClose: AUTO_CLOSE });
          }
        }
        if(e.name === "btn_download_failed"){
          let thead: any = []
          
          schema?.schema?.map((f: any)=>{
            if(f.name === "upload_patients_page"){
              f?.fields?.map((ff: any)=>{
                if(ff.name === "validation_table_container"){
                  ff?.fields?.map((fff: any)=>{
                    if(fff.name === "validation_table_body"){
                      fff?.fields?.map((g: any)=>{
                        if(g.name === "file_validation_failed_body"){
                          g?.fields?.map((gg: any)=>{
                            if(gg.name === "file_validation_table"){
                              thead = gg.thead
                            }
                          })
                        }
                      })
                    }
                  })
                }
              })
            }
          })

          if(thead.length > 0 && failedItems.length > 0){
             await Utils.exportToExcel(thead, failedItems, "FailedRecords");
            toast.success("Failed Records Downloaded successfully", { position: "top-center", autoClose: AUTO_CLOSE });

          }else{
            toast.error("No data available to download", { position: "top-center", autoClose: AUTO_CLOSE });
          }
        }
        if(e.name === "upload_valid_data"){
          setLoading(true);
          

          try{
            const res = await Utils.makeApiCall(`/upload-departments`,'POST',{data: validItems, org_id: org_id, branch_id: branch_id})

            if(res.status){
              toast.success(res.message, { position: "top-center", autoClose: AUTO_CLOSE })
              navigate('/departments')
            }else{
              toast.error(res.message, { position: "top-center", autoClose: AUTO_CLOSE })
            }
          }catch(e){
            toast.error("Error in uploading files", { position: "top-center", autoClose: AUTO_CLOSE });
          }

        setLoading(false)
                              
        }
        if(e.name === "cancel_validation"){
          navigate("/departments")
        }

        if(e.name === "search_valid_records"){
          const result = validItems.filter((a: any) => a?.dept_code?.toLowerCase().includes(e.value.toLowerCase()) ||
          a?.dept_name?.toLowerCase().includes(e.value.toLowerCase()))
          setSchema((prev: any)=>{
            prev?.schema?.map((f: any)=>{
              if(f.name === "upload_patients_page"){
                f?.fields?.map((ff: any)=>{
                  if(ff.name === "validation_table_container"){
                    ff?.fields?.map((fff: any)=>{
                      if(fff.name === "validation_table_body"){
                        fff?.fields?.map((g: any)=>{
                          if(g.name === "file_validation_success_body"){
                            g?.fields?.map((gg: any)=>{
                              if(gg.name === "file_validation_table"){
                                gg.tbody = result
                              }
                            })
                          }
                          // if(g.name === "file_validation_failed_body"){
                          //   g?.fields?.map((gg: any)=>{
                          //     if(gg.name === "file_validation_table"){
                          //       gg.tbody = failedItems
                          //     }
                          //   })
                          // }
                        })
                      }
                    })
                  }
                })
              }
            })
            return {...prev}
          })
        }
        if(e.name === "search_failed_records"){
          const result = failedItems.filter((a: any) => a?.dept_code?.toLowerCase().includes(e.value.toLowerCase()) ||
          a?.dept_name?.toLowerCase().includes(e.value.toLowerCase()))
          setSchema((prev: any)=>{
            prev?.schema?.map((f: any)=>{
              if(f.name === "upload_patients_page"){
                f?.fields?.map((ff: any)=>{
                  if(ff.name === "validation_table_container"){
                    ff?.fields?.map((fff: any)=>{
                      if(fff.name === "validation_table_body"){
                        fff?.fields?.map((g: any)=>{
                          if(g.name === "file_validation_failed_body"){
                            g?.fields?.map((gg: any)=>{
                              if(gg.name === "file_validation_table"){
                                gg.tbody = result
                              }
                            })
                          }
                        })
                      }
                    })
                  }
                })
              }
            })
            return {...prev}
          })
        }
        if(e.name === "download_sample_xlsx"){
          const filteredData = sampleXLSXData.map((row: any) => {
            const newRow: any = {};
            sampleXLSXTHEAD.forEach((column: any) => {
             
                newRow[column.label] = row[column.key];
              
            });
            return newRow;
          });
        
          const worksheet = XLSX.utils.json_to_sheet(filteredData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        
          XLSX.writeFile(workbook, `sample.xlsx`);
        }
        if(e.name === "download_sample_csv"){
          const filteredData = sampleCSVData.map((row: any) => {
            const newRow: any = {};
            sampleXLSXTHEAD.forEach((column: any) => {
             
                newRow[column.label] = row[column.key];
              
            });
            return newRow;
          });
        
          const worksheet = XLSX.utils.json_to_sheet(filteredData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        
          XLSX.writeFile(workbook, `sample.csv`);
        }
    }

  return (
    <div>
      {loading && <LoadingState />}
            <ElementExecutor
                data={schema}
                setData={(e: any) => {
                    setSchema({ ...e });
                }}
                selectedRecord={(e: any) => {
                    handleSelectedRecord(e);

                }}
            />
    </div>
  )
}

export default UploadDepartments
