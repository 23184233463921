import { ElementExecutor } from '@apexcura/core'
import React, { useEffect, useState } from 'react'
import Utils, { returnSnippetCode } from '../utils';
import Storage from '../utils/local-storage';
import { toast } from 'react-toastify';
import { CONSTANTS } from '../utils/app-constants';
import { color} from 'highcharts';
import { Tooltip,Popconfirm, Switch } from "antd";
import LoadingState from './LoadingState';
import useExitPopUp from '../hooks/useExitPopUp';
import { setLoading } from '../redux/appState/appStateSlice';
import { useDispatch } from 'react-redux';
const { FAILURE_MSG, CATCH_ERROR_MSG, AUTO_CLOSE } = CONSTANTS;
const { BASE_URL } = CONSTANTS;

const FREQUENCY: any = {
    1: "Daily",
    2: "Weekly",
    3: "Monthly",
}

const organizationDetailsSchema=()=>({
    name:"org_details",
    className:"flex flex-col w-full gap-6 ",
    fields:[
        {
            name:"org_details_text",
            className:"flex justify-between items-center",
            fields:[
                {
                    name:"org_details_text",
                    label:"Organization Details",
                    className:"text-black text-lg font-semibold uppercase",
                    element:"div",
                },
                {
                    name: 'org_details_edit_icon',
                    className: '',
                    iconsClassName:"size-4",
                    element: 'button',
                    icon:`${BASE_URL}/api/public/assets/images/edit.svg`,
                    isSVGStylesOverride: false,
                    visible: true,
                },
            ]
        },
        {
            name:"org_data_fields",
            className:"flex flex-col gap-2",
            fields:[
                {
                    name:"org_data_fields_values",
                    className:"px-5 gap-4 grid grid-cols-3",
                    fields:[
                        {
                            name: "name_of_the_org",
                            className:"flex flex-col gap-1",
                            fields:[
                                {
                                    name:"orgname_text",
                                    label:"Organization Name",
                                    className:"text-gray-4 text-sm font-base",
                                    element:"div",
                                },
                                {
                                    name:"orgname_value",
                                    label:"",
                                    className:"text-black text-base font-semibold",
                                    element:"div",
                                    value:null,
                                    visible:true
                                },
                                {
                                    name:"orgname_value_textfield",
                                    label:"",
                                    value:"",
                                    className:"rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                                    element:"input-text",
                                    visible:false
                                }
                            ]
                        },
                        {
                            name: "org_address",
                            className:"flex flex-col gap-1",
                            fields:[
                                {
                                    name:"org_address_text",
                                    label:"Address",
                                    className:"text-gray-4 text-sm font-base",
                                    element:"div",
                                },
                                {
                                    name:"org_address_value",
                                    label:"",
                                    className:"text-black text-base font-semibold",
                                    element:"div",
                                    value:null,
                                    visible:true
                                },
                                {
                                    name:"org_address_textfield",
                                    label:"",
                                    value:"",
                                    className:"rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                                    element:"input-text",
                                    visible:false
                                }
                            ]
                        },
                        {
                            name: "org_website",
                            className:"flex flex-col gap-1",
                            fields:[
                                {
                                    name:"org_website_text",
                                    label:"Website",
                                    className:"text-gray-4 text-sm font-base",
                                    element:"div",
                                },
                                {
                                    name:"org_website_value",
                                    label:"",
                                    className:"text-black text-base font-semibold",
                                    element:"div",
                                    value:null,
                                    visible:true,
                                },
                                {
                                    name:"org_website_textfield",
                                    label:"",
                                    value:"",
                                    className:"rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                                    element:"input-text",
                                    visible:false,
                                    disabled:true
                                }
                            ]
                        },
                        {
                            name: "org_city",
                            className:"flex flex-col gap-1",
                            fields:[
                                {
                                    name:"org_city_text",
                                    label:"City",
                                    className:"text-gray-4 text-sm font-base",
                                    element:"div",
                                },
                                {
                                    name:"org_city_value",
                                    label:"",
                                    className:"text-black text-base font-semibold",
                                    element:"div",
                                    value:null,
                                    visible:true
                                },
                                {
                                    name:"org_city_textfield",
                                    label:"",
                                    value:"",
                                    className:"rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                                    element:"input-text",
                                    visible:false,
                                }
                            ]
                        },
                        {
                            name: "org_gstn",
                            className:"flex flex-col gap-1",
                            fields:[
                                {
                                    name:"org_gstn_text",
                                    label:"GSTN",
                                    className:"text-gray-4 text-sm font-base",
                                    element:"div",
                                },
                                {
                                    name:"org_gstn_value",
                                    label:"",
                                    className:"text-black text-base font-semibold",
                                    element:"div",
                                    value:null,
                                    visible:true,
                                },
                                {
                                    name:"org_gstn_textfield",
                                    label:"",
                                    value:"",
                                    className:"rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                                    element:"input-number",
                                    visible:false,
                                }
                            ]
                        },
                        {
                            name: "org_pincode",
                            className:"flex flex-col gap-1",
                            fields:[
                                {
                                    name:"pincode_text",
                                    label:"Pincode",
                                    className:"text-gray-4 text-sm font-base",
                                    element:"div",
                                },
                                {
                                    name:"org_pincode_value",
                                    label:"",
                                    className:"text-black text-base font-semibold",
                                    element:"div",
                                    value:null,
                                    visible:true
                                },
                                {
                                    name:"org_pincode_textfield",
                                    label:"",
                                    value:"",
                                    className:"rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                                    element:"input-number",
                                    visible:false,
                                }
                            ]
                        },
                        {
                            name: "org_mobile",
                            className:"flex flex-col gap-1",
                            fields:[
                                {
                                    name:"org_mobile_text",
                                    label:"Mobile",
                                    className:"text-gray-4 text-sm font-base",
                                    element:"div",
                                },
                                {
                                    name:"org_mobile_value",
                                    label:"",
                                    className:"text-black text-base font-semibold",
                                    element:"div",
                                    value:null,
                                    visible:true
                                },
                                {
                                    name:"org_mobile_textfield",
                                    label:"",
                                    value:"",
                                    className:"rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                                    element:"input-number",
                                    visible:false,
                                }
                            ]
                        },
                        {
                            name: "virtual_number",
                            className:"flex flex-col gap-1",
                            fields:[
                                {
                                    name:"org_virtual_number_text",
                                    label:"Virtual Number",
                                    className:"text-gray-4 text-sm font-base",
                                    element:"div",
                                },
                                {
                                    name:"org_virtual_value",
                                    label:"",
                                    className:"text-black text-base font-semibold",
                                    element:"div",
                                    value:null,
                                    visible:true
                                },
                                {
                                    name:"org_virtual_textfield",
                                    label:"",
                                    value:"",
                                    className:"rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                                    element:"input-number",
                                    visible:false,
                                }
                            ]
                        },
                        {
                            name: "meta_number",
                            className:"flex flex-col gap-1",
                            fields:[
                                {
                                    name:"meta_phone_number_text",
                                    label:"Meta Phone Number",
                                    className:"text-gray-4 text-sm font-base",
                                    element:"div",
                                },
                                {
                                    name:"meta_phone_number_value",
                                    label:"",
                                    className:"text-black text-base font-semibold",
                                    element:"div",
                                    value:null,
                                    visible:true
                                },
                                {
                                    name:"meta_phone_number_textfield",
                                    label:"",
                                    value:"",
                                    className:"rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                                    element:"input-number",
                                    visible:false,
                                }
                            ]
                        },
                    ]
                },
                {
                    name:"org_details_buttons",
                    className:"flex gap-2 items-center justify-start px-5 pt-2",
                    fields:[
                        {
                            name: "org_details_save_changes",
                            label: "Save Changes",
                            element: "button",
                            className:"p-2 px-3 text-default-500 bg-[#3341bb] rounded-md text-white font-semibold",
                            visible:false
                        },
                        {
                            name:'org_details_cancel_button',
                            label:"Cancel",
                            element:'button',
                            className:"text-primary p-2 px-3 text-default-500 bg-white border border-primary rounded-md font-semibold ", 
                            visible:false
                        }
                    ]
                }
            ]
        }
    ]
});
const brandingDetailsSchema=()=>({
    name:"branding_details",
    className:"flex flex-col w-full pt-4 gap-6",
    fields:[
        {
            name:"branding_details_text",
            className:"flex justify-between ",
            fields:[
                {
                    name:"branding_details_text",
                    label:"Branding",
                    className:"text-black text-lg font-semibold uppercase",
                    element:"div",
                },
                {
                    name:"branding_customization",
                    className:"flex flex-col items-center gap-2",
                    visible:false,
                    fields:[
                        {
                            name:"branding_customization_text",
                            label:"Customize your Branding",
                            className:"text-gray-4  text-sm font-semibold",
                            element:"div",
                        },
                        {
                            name:"branding_customization_value",
                            label:"Upload your logo and select your brand colors to make Apex Agent truly yours.",
                            className:"text-gray-3  text-base w-[60%] font-semibold",
                            element:"div",
                        },
                    ]
                },
                {
                    name: 'branding_details_edit_icon',
                    className: '',
                    iconsClassName:"size-4",
                    element: 'button',
                    icon:`${BASE_URL}/api/public/assets/images/edit.svg`,
                    isSVGStylesOverride: false,
                    visible: true,
                  },
            ]
        },
        {
            name:"branding_fields",
            className:"flex justify-between gap-2 px-5",
            fields:[
                {
                    name:"org_logo_upload",
                    className:"flex flex-col gap-2 ",
                    fields:[
                        {
                            name:"logo_design_text",
                            label:"Logo Design",
                            className:"text-gray-4 text-sm font-base",
                            element:"div",
                        },
                        {
                            name:"logo_preview_image",
                            className:"w-[160px] h-[160px] p-2 border-2 rounded-md",
                            fields:[
                                {
                                    name:"logo_design_image_preview",
                                    className:"w-full h-full object-cover",
                                    element:"image",
                                    img:"",
                                    visible:true,
                                    alt:"preview_image"
                                },
                                {
                                    name:"logo_design_image_upload_preview",
                                    element:"avatar-upload",
                                    visible:false,
                                    value:"",
                                    icon: "add_avatar",
                                    iconsClassName:"icon-button absolute -bottom-[0.7rem] -right-[0.7rem] w-7 p-2 bg-gray-200 rounded-md",
                                    containerClassName:
                                      "avatar-uploader h-[90%] w-[90%]  border border-gray-100  relative",
                                },
                            ]
                        },
                    ]
                },
                {
                    name:"color_schema",
                    className:"flex flex-col gap-2 w-[35%]",
                    fields:[
                        {
                            name:"color_schema_text",
                            label:"Color Schema",
                            className:"text-gray-4 text-sm font-base",
                            element:"div",
                        },
                        {
                            name:"org_colorpicker",
                            className:"grid grid-cols-1 gap-4 w-full border-2 p-5 rounded-md items-center justify-center",
                            fields:[
                                {
                                    name:"primary_action_button_color",
                                    className :"grid grid-cols-2 gap-2 justify-center items-center  ",
                                    fields:[
                                        {
                                            name:"primary_button_color",
                                            label:"Primary Button Bg",
                                            className:"text-gray-4 text-sm font-base ",
                                            element:"div",
                                        },
                                        {
                                            name:"primary_button_value",
                                            element:'color-picker',
                                            className:' h-[40px] -mt-7',
                                            value:null,
                                            disabled:true
                                        }
                                    ]
                                },
                                {
                                    name:"primary_text_color",
                                    className :"grid grid-cols-2 gap-2 items-center justify-center ",
                                    fields:[
                                        {
                                            name:"primary_text_color_text",
                                            label:"Primary Button Text",
                                            className:"text-gray-4 text-sm font-base ",
                                            element:"div",
                                        },
                                        {

                                            name:"primary_text_color_value",
                                            element:'color-picker',
                                            className:'h-[40px] -mt-7',
                                            value:"",
                                            disabled:true

                                        }
                                    ]
                                },
                                {
                                    name:"secondary_action_button_color",
                                    className :"grid grid-cols-2 gap-2 items-center justify-center  ",
                                    fields:[
                                        {
                                            name:"secondary_button_color",
                                            label:"Secondary Button Bg",
                                            className:"text-gray-4 text-sm font-base ",
                                            element:"div",
                                        },
                                        {
                                            name:"secondary_button_value",
                                            element:'color-picker',
                                            className:'h-[40px] -mt-7',
                                            value:null,
                                            disabled:true
                                        }
                                    ]
                                },
                                {
                                    name:"secondary_text_color",
                                    className :"grid grid-cols-2 gap-2 items-center justify-center ",
                                    fields:[
                                        {
                                            name:"secondary_text_color_text",
                                            label:"Secondary Button Text",
                                            className:"text-gray-4 text-sm font-base ",
                                            element:"div",
                                        },
                                        {

                                            name:"secondary_text_color_value",
                                            element:'color-picker',
                                            className:'h-[40px] -mt-7',
                                            value:null,
                                            disabled:true

                                        }
                                    ]
                                },
                                {
                                    name:"normal_text_color",
                                    visible:false,
                                    className :"grid grid-cols-2 gap-2 items-center justify-center ",
                                    fields:[
                                        {
                                            name:"normal_text_color_text",
                                            label:"Normal Text",
                                            className:"text-gray-4 text-sm font-base ",
                                            element:"div",
                                        },
                                        {

                                            name:"normal_text_color_value",
                                            element:'color-picker',
                                            className:'h-[40px] -mt-7',
                                            value:null,
                                            disabled:true
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    name:"preview",
                    className:"flex flex-col gap-2 w-[35%]",
                    fields:[
                        {
                            name:"preview_text_name",
                            label:"Preview",
                            className:"text-gray-4 text-sm font-base",
                            element:"div",
                        },
                        {
                            name:"preview_text_and_value",
                            className:"w-full p-5 border-2 rounded-md flex flex-col gap-5",
                            fields:[
                                // {
                                //     name:"preview_text",
                                //     label:"Lorem ipsum dolor sit amet cons. Odio sit eu lorem sindisdn Ultricies magna pharetra imperdiet turpis nec interdum id turpis. Sit eu eleifend id iaculis Massa tortor velit com Massa tortor velit com Massa tortor velit com lentesque et consequat. Massa tortor velit com Massa tortor velit com.",
                                //     className:"text-sm font-base w-full",
                                //     style:{},
                                //     element:"div",
                                // },
                                {
                                    name:"dummy_buttons",
                                    className:"flex gap-2 items-center justify-center ",
                                    fields:[
                                        {
                                            name:'dummy_secondary',
                                            label:"Secondary",
                                            element:'div',
                                            className:"p-2 px-3 text-default-500 rounded-md font-semibold ",
                                            style:{}, 
                                            visible:true
                                        },
                                        {
                                            name: "dummy_primary",
                                            label: "Primary",
                                            element: "div",
                                            className:"p-2 px-3 text-default-500 rounded-md text-white font-semibold",
                                            visible:true,
                                            style:{}, 
                                        },
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            name:"branding_details_buttons",
            className:"flex gap-2 items-center justify-start px-5 pt-2",
            fields:[
                {
                    name: "branding_details_save_changes",
                    label: "Save Changes",
                    element: "button",
                    className:"p-2 px-3 text-default-500 bg-[#3341bb] rounded-md text-white font-semibold",
                    visible:false
                },
                {
                    name:'branding_details_cancel_button',
                    label:"Cancel",
                    element:'button',
                    className:"text-primary p-2 px-3 text-default-500 bg-white border border-primary rounded-md font-semibold ", 
                    visible:false
                }
            ]
        }
    ]
});
const chatbotDetailsSchema=()=>({
    name:"chatbot_details",
    className:"flex flex-col pt-4 gap-6",
    fields:[
        {
            name:"chatbot_text",
            className:"flex justify-between ",
            fields:[
                {
                    name:"chatbot_text",
                    label:"Virtual Assistant",
                    className:"text-black text-lg font-semibold uppercase",
                    element:"div",
                },
                {
                    name: 'chatbot_edit_icon',
                    className: '',
                    iconsClassName:"size-4",
                    element: 'button',
                    icon:`${BASE_URL}/api/public/assets/images/edit.svg`,
                    isSVGStylesOverride: false,
                    visible: true,
                },
            ]
        },
        {
            name:"chatbot_details_snippet",
            className:"justify-between gap-2 px-5 w-full grid grid-cols-12",
            fields:[
                {
                    name:"chatbot_name",
                    className:"flex flex-col gap-5 col-span-4",
                    fields:[
                        {
                            name: "name_of_the_chatbot",
                            className:"flex items-center gap-2 w-full ",
                            fields:[
                                {
                                    name:"chatbot_name_text",
                                    label:"Virtual Assistant Name:",
                                    className:"text-gray-4 text-sm font-base",
                                    element:"div",
                                },
                                {
                                    name:"chatbot_name_value",
                                    label:"",
                                    className:"text-black text-base font-semibold",
                                    element:"div",
                                    value:null,
                                    visible:true
                                },
                                {
                                    name:"chatbot_name_value_textfield",
                                    label:"",
                                    value:"",
                                    className:"rounded-md px-3 py-1 text-gray-700 font-semibold focus:outline-none",
                                    element:"input-text",
                                    visible:false,
                                }
                            ]
                        },
                        {
                            name:"chatbot_logo_upload",
                            className:"flex flex-col gap-2  w-full ",
                            fields:[
                                {
                                    name:"chatbot_design_text",
                                    label:"Virtual Assistant Icon",
                                    className:"text-gray-4 text-sm font-base",
                                    element:"div",
                                },
                                {
                                    name:"chatbot_preview_image",
                                    className:"w-[140px] h-[140px] p-2 border-2 rounded-md",
                                    fields:[
                                        {
                                            name:"chatbot_design_image_preview",
                                            className:"w-full h-full object-cover",
                                            element:"image",
                                            img:"",
                                            visible:true,
                                            alt:"preview_image"
                                        },
                                        {
                                            name:"chatbot_design_image_upload_preview",
                                            className:"w-[100%] h-[100%] flex items-center justify-center",
                                            element:"avatar-upload",
                                            visible:false,
                                            value:"",
                                            icon: "add_avatar",
                                            iconsClassName:"icon-button absolute -bottom-[0.7rem] -right-[0.7rem] w-7 p-2 bg-gray-200 rounded-md",
                                            containerClassName:
                                              "avatar-uploader h-[90%] w-[90%]  border border-gray-100  relative",
                                        },
                                    ]
                                },
                            ]
                        },
                    ]
                },
                {
                    name:"chatbot_snippet_code",
                    className:"col-span-8 w-full flex flex-col gap-5",
                    fields:[
                        {
                            name:"chatbot_snippet_and_copy_button",
                            className:"w-[100%] flex justify-between items-center",
                            fields:[
                                {
                                    name:"chatbot_snippet_code_name",
                                    element:"div",
                                    label:"Script Code",
                                    className:"text-gray-4 text-base font-semibold",
                                },
                                {
                                    name:"chatbot_copy_button",
                                    element:"button",
                                    icon:`${BASE_URL}/api/public/assets/images/copy.svg`,
                                    visible: true,
                                    label:"Copy",
                                    className: "flex gap-2 items-center justify-center px-3 py-2 text-base ",
                                    iconsClassName: "size-4",
                                    isSVGStylesOverride: false,
                                }
                            ]
                        },
                        {
                            name:'chatbot-snippet-code_text',
                            className:'h-[150px]  bg-white overflow-scroll border border-2 font-mono whitespace-pre',
                            element:'div',
                            label:returnSnippetCode(Storage.getItem("details")["website"])
                        }
                    ]
                }
            ]
        },
        {
            name:"chatbot_buttons",
            className:"flex gap-2 items-center justify-start px-5 pt-2",
            visible:false,
            fields:[
                {
                    name: "chatbot_save_changes",
                    label: "Save Changes",
                    element: "button",
                    className:"p-2 px-3 text-default-500 bg-[#3341bb] rounded-md text-white font-semibold",
                    visible:true
                },
                {
                    name:'chatbot_cancel_button',
                    label:"Cancel",
                    element:'button',
                    className:"text-primary p-2 px-3 text-default-500 bg-white border border-primary rounded-md font-semibold ", 
                    visible:true
                }
            ]
        }
    ]
});

const featuresRecipientsModalSchema = () =>({
    name:"feature_config_modal",
    element: "modal",
    // width: 800,
    containerClassName:"!w-[90%]  ",
    className: 'rounded-lg !w-full !h-[90vh]',
    visible:false,
    fields:[
        {
            name: "modal_head",
            className: "flex justify-between items-center p-4 bg-primary/5",
            fields: [
                {
                    name:'',
                    className:'font-semibold text-lg text-[#3341BB]',
                    element:'div',
                    label:'Features Recipients'
                },
                {
                    name: "feature_config_modal_close",
                    element: "button",
                    label: "X",
                    className:
                      "!w-[35px] !h-[35px] rounded-full bg-primary text-xl text-white flex justify-center items-center",
                },
            ]
        },
        {
            name: "feature_config_modal_body",
            className: "p-4",
            fields:[
                {
                    name: "feature_config_modal_data",
                    className: "flex items-center justify-between",
                    fields: [
                        {
                            name: "feature_config_modal_data_values",
                            className: "flex items-center gap-2",
                            fields:[
                                {
                                    name: "",
                                    className: "font-semibold text-md",
                                    element: "div",
                                    label: "Feature Name: "
                                },
                                {
                                    name: "feature",
                                    className: "",
                                    element: "div",
                                    label: "N/A"
                                },
                            ]
                        },
                        {
                            name: "feature_config_modal_data_values",
                            className: "flex items-center gap-2",
                            fields:[
                                {
                                    name: "",
                                    className: "font-semibold text-md",
                                    element: "div",
                                    label: "Frequency: "
                                },
                                {
                                    name: "frequency",
                                    className: "",
                                    element: "div",
                                    label: "N/A"
                                },
                            ]
                        },
                        {
                            name: "feature_config_modal_data_values",
                            className: "flex items-center gap-2",
                            fields:[
                                {
                                    name: "",
                                    className: "font-semibold text-md",
                                    element: "div",
                                    label: "Status: "
                                },
                                {
                                    name: "status",
                                    className: "",
                                    element: "div",
                                    label: "N/A"
                                },
                            ]
                        }
                    ]
                },
                {
                    name: "",
                    className: "text-lg font-medium text-[#3341BB] pt-6",
                    element: "div",
                    label: "WhatsApp Recipients"
                },
                {
                    name: "whatsapp_recipients",
                    label: "Recipients from Existing users",
                    element: "single-select",
                    visible: true,
                    mode: "tags",
                    value: [],
                    className: " h-[15vh]",
                    options: [],
                    containerClassName: "flex flex-col col-span-1",
                    labelClassName: "text-sm pb-1",
                },
                {
                    name: "",
                    className: "text-lg font-medium text-[#3341BB]  pt-6",
                    element: "div",
                    label: "Email Recipients"
                },
                {
                    name: "email_recipients",
                    label: "Recipients from Existing users",
                    element: "single-select",
                    visible: true,
                    mode: "tags",
                    value: [],
                    className: " h-[15vh]",
                    options: [
                        {label: "kjsdn", value: "skdni"}
                    ],
                    containerClassName: "flex flex-col col-span-1",
                    labelClassName: "text-sm pb-1",
                },
                {
                    name: "cancel_and_submit_update",
                    className: "w-full justify-end flex gap-2 pt-2",
                    fields: [
                      {
                        name: "cancel_feature_config",
                        label: "Cancel",
                        element: "button",
                        className:
                          "p-2 px-3  text-default-500 bg-gray-200 text-black rounded-md font-semibold",
                      },
                      {
                        name: "update_feature_recipients_config",
                        label: "Save",
                        element: "button",
                        className:
                          "p-2 px-5  text-default-500 bg-primary  rounded-md text-white font-semibold",
                      },
                    ],
                   
                  },
            ]
        }
    ]
})

const featuresDetailsSchema = () =>({
    name:"features_container",
    className:"flex flex-col pt-4 gap-6",
    fields: [

        {
            name: "",
            className: "text-black text-lg font-semibold uppercase",
            element: "div",
            label: "FEATURES"
        },
        {

            className: 'mt-2',
            name: 'features_table',
            element: 'table',
            variant: 'plain',
            size: 'small',
            count: 0,
            view: true,
            visible: true,
            pagination: true,
            value: { name: 'pagination', page: 1, pageSize: 10 },
            thead: [
                {
                    name: 'feature',
                    label: 'Feature',
                    key: 'feature',

                },
                {
                    name: 'whatsapp_count',
                    label: 'Recipients (Whatsapp)',
                    key: 'whatsapp_count',
                },
                {
                    name: 'email_count',
                    label: 'Recipients (Email)',
                    key: 'email_count',
                },
                {
                    name: 'status',
                    label: 'Status',
                    key: 'status',
                    render: (e:any) =>{e === 1 ? "Active" : "In Active" }
                },
                {
                    name: 'configure',
                    label: 'Configure',
                    key: 'configure',
                    
                }
               
                // {
                //   name: "actions",
                //   label: "",
                //   key: "actions",
                //   render: (e: any, record: any) => {
                //     return (
                //         <div
                //         onKeyDown={() =>
                //           handleSelectedRecord({ name: "edit", value: record })
                //         }
                //         tabIndex={0}
                //         role="button"
                //         onClick={() =>
                //           handleSelectedRecord({ name: "edit", value: record })
                //         }
                //       >
                //         <img
                //           src={
                //             `${BASE_URL}/api/public/assets/images/edit.svg`
                //           }
                //           alt="Edit the record"
                //           className="w-4 h-4"
                //         />
                //       </div>
                //     );
                //   },
                // },
            ],
            tbody: [],
        },
    ]
})


const Profile = () => {
    const [organizationDetails,setOganizationDetails]=useState({...organizationDetailsSchema()})
    const [brandingDetails,setbrandingDetails]=useState({...brandingDetailsSchema()})
    const [chatBotSchema,setChatBotSchema]=useState<any>({...chatbotDetailsSchema()})
    const [featuresSchema,setFeaturesSchema]=useState<any>({...featuresDetailsSchema()})
    const [featuresRecipientsModal,setFeaturesRecipientsModal]=useState<any>({...featuresRecipientsModalSchema()})
    const [primaryButtonBg,setPrimaryButtonBg]=useState<string>("");
    const [primaryTextColor,setPrimaryTextColor]=useState<string>("");
    const [secondaryButtonBg,setSecondaryButtonBg]=useState<string>("");
    const [secondaryTextColor,setSecondaryTextColor]=useState<string>("");
    const [normalText,setNormalText]=useState<string>("");
    const [orgDetails,setOrgDetails]=useState<any>({});
    const [orgname,setOrgName]=useState("");
    const [address,setAddress]=useState("");
    const [city,setCity]=useState("");
    const [gstn,setGstn]=useState("")
    const [pincode,setPincode]=useState("");
    const [mobile,setMobile]=useState("");
    const [virtualNumber,setVirtualNumber]=useState("");
    const [metaNumber,setMetaNumber]=useState("");
    const [chatbotName,setChatbotName]=useState("")
    const [brandingLogo,setBrandingLogo]=useState<any>("");
    const [chatbotLogo,setChatbotLogo]=useState("");
    const [brandinguploadLogo,setBrandingUploadLogo]=useState<any>(false);
    const [chatbotUploadLogo,setChatbotUploadLogo]=useState<any>(false);
    const [schema,setSchema]=useState<any>({
        name:"profile_schema",
        className:"flex flex-col w-full gap-4 bg-white rounded-lg p-4 divide-y",
        schema:[
            {...organizationDetails},
            {...brandingDetails},
            // {...chatBotSchema},
            {...featuresRecipientsModal},
            {...featuresSchema}
        ]
    })
    const [detectChange, setDetectChange] = useState(false)
    useExitPopUp(detectChange)
    const dispatch = useDispatch();
    const org_id=Storage.getItem('details')['org_id'];

    useEffect(()=>{
        API_GET_OrganizationDetails();
    },[])

    useEffect(()=>{
        setbrandingDetails((prev:any)=>{
            prev.fields=prev.fields.map((field:any)=>{
                if(field.name==="branding_fields"){
                    field.fields=field.fields.map((ffield:any)=>{
                        if(ffield.name==="preview"){
                            ffield.fields=ffield.fields.map((fffield:any)=>{
                                if(fffield.name==="preview_text_and_value"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="preview_text"){
                                            //ffffield.style=`!text-[${normalText}] text-sm font-base w-full`;
                                            ffffield.style={
                                                color:normalText,
                                            }
                                        }
                                        if(ffffield.name==="dummy_buttons"){
                                            ffffield.fields=ffffield.fields.map((fffffield:any)=>{
                                                if(fffffield.name==="dummy_secondary"){
                                                    // fffffield.className=`!text-[${secondaryTextColor}] p-2 px-3 text-default-500 !bg-[${secondaryButtonBg}] rounded-md font-semibold`;
                                                    fffffield.style={
                                                        color:secondaryTextColor,
                                                        backgroundColor:secondaryButtonBg
                                                    }
                                                }
                                                if(fffffield.name==="dummy_primary"){
                                                    // fffffield.className=`!text-[${primaryTextColor}] p-2 px-3 text-default-500 !bg-[${primaryButtonBg}] rounded-md font-semibold`;
                                                    fffffield.style={
                                                        color:primaryTextColor,
                                                        backgroundColor:primaryButtonBg
                                                    }
                                                }
                                                return fffffield;
                                            })
                                        }
                                        return ffffield;
                                    })
                                }
                                return fffield;
                            })
                        }
                        if(ffield.name==="org_logo_upload"){ 
                            ffield.fields=ffield.fields.map((fffield:any)=>{
                                if(fffield.name==="logo_preview_image"){
                                    fffield.fields=fffield.fields.map((fffffield:any)=>{
                                        if(fffffield.name==="logo_design_image_preview"){
                                            fffffield.img=`${BASE_URL}/api/${orgDetails?.logo}`
                                        }
                                        if(fffffield.name==="logo_design_image_upload_preview"){
                                            fffffield.value=`${BASE_URL}/api/${orgDetails?.logo}`
                                        }
                                        return fffffield;
                                    })
                                }
                                return fffield;
                            })
                        }
                        return ffield;
                    })
                }
                return field;
            })
            return {...prev}
        })
    },[primaryButtonBg,primaryTextColor,secondaryButtonBg,secondaryTextColor,normalText])

    const API_GET_OrganizationDetails=async ()=>{
        dispatch(setLoading(true))
        try{
            const res:any=await Utils.makeApiCall( `/getOrganizationDetails?organizationId=${org_id}`,"GET");
            if(res.status){

                handleChangeOrgDetails(res.data);
                setOrgDetails(res.data)
            }else{
                toast.error(res.message ? res.message : FAILURE_MSG,{position:"top-center",autoClose:AUTO_CLOSE})
            }
        }catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
            console.error("Failed to fetch data", error);
        }
        dispatch(setLoading(false))
    }

    const API_PUT_OrganizationDetails=async (save_button:string)=>{
        const payload:FormData=new FormData();
        if(save_button==="org_details_save_changes"){
            if(orgname.length===0){
                toast.error("Organization Name cannot be empty.",{position:"top-center",autoClose:AUTO_CLOSE})
                return
            }
            if(address.length===0){
                toast.error("Address cannot be empty.",{position:"top-center",autoClose:AUTO_CLOSE})
                return
            }
            if(city.length===0){
                toast.error("City cannot be empty.",{position:"top-center",autoClose:AUTO_CLOSE})
                return
            }
            if(pincode.length===0){
                toast.error("Pincode cannot be empty.",{position:"top-center",autoClose:AUTO_CLOSE})
                return
            }
            if(mobile.length===0){
                toast.error("Mobile cannot be empty.",{position:"top-center",autoClose:AUTO_CLOSE})
                return
            }
            if(virtualNumber.length===0){
                toast.error("Virtual Number cannot be empty.",{position:"top-center",autoClose:AUTO_CLOSE})
                return
            }
            if(metaNumber.length===0){
                toast.error("Meta Phone Number cannot be empty.",{position:"top-center",autoClose:AUTO_CLOSE})
                return
            }
            payload.append("gstin",gstn)
            payload.append("name",orgname)
            payload.append("phone_number",mobile)
            payload.append("address",address)
            payload.append("city",city)
            payload.append("zipcode",pincode)
            payload.append("virtual_number",virtualNumber)
            payload.append("meta_phone_number",metaNumber)
        } else if(save_button==="branding_details_save_changes"){
            if(orgDetails.primary_btn_color===primaryButtonBg && orgDetails.primary_btn_color===primaryTextColor && orgDetails.secondary_btn_color===secondaryButtonBg && orgDetails.secondary_btn_text_color===secondaryTextColor && orgDetails.primary_text_color===normalText){
                toast.error("Please select atleast one color.",{position:"top-center",autoClose:AUTO_CLOSE})
                return
            }
            payload.append("primary_btn_color",primaryButtonBg)
            payload.append("primary_btn_text_color",primaryTextColor)
            payload.append("secondary_btn_color",secondaryButtonBg)
            payload.append("secondary_btn_text_color",secondaryTextColor)
            payload.append("primary_text_color",normalText)
             if(brandinguploadLogo){
                payload.append(`logo`, brandinguploadLogo.originFileObj);
             }
        } else if(save_button==="chatbot_save_changes"){
            if(chatbotName.length===0){
                toast.error("Chatbot Name cannot be empty.",{position:"top-center",autoClose:AUTO_CLOSE})
                return
            }
            payload.append("chatbot_name",chatbotName);
            if(chatbotUploadLogo){
                payload.append(`chatbot_icon`, chatbotUploadLogo.originFileObj);
             }
        }

        dispatch(setLoading(true))
        try{
            const res:any=await Utils.makeFormdataApiCall( `/organization?organizationId=${org_id}`,"PUT",payload);
            if(res.status){
                toast.success('Organization Details updated successfully', { position: "top-center", autoClose: 2000 })
                API_GET_OrganizationDetails()
                handleOrgDetailsCancelButton()
                handleBrandingCancelButton();
                handleChatBotCancelButton();
            }else{
                toast.error(res.message ? res.message : FAILURE_MSG,{position:"top-center",autoClose:AUTO_CLOSE})
            }
        }catch (error) {
            toast.error(CATCH_ERROR_MSG, { position: "top-center", autoClose: AUTO_CLOSE });
            console.error("Failed to update data", error);
        }
        dispatch(setLoading(false))
    }

    const handleChangeOrgDetails=(data:any)=>{
        setOganizationDetails((prev:any)=>{
            prev.fields=prev.fields.map((field:any)=>{
                if(field.name==="org_data_fields"){
                    field.fields=field.fields.map((ffield:any)=>{
                        if(ffield.name==="org_data_fields_values"){
                            ffield.fields=ffield.fields.map((fffield:any)=>{
                                if(fffield.name==="name_of_the_org"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="orgname_value"){
                                            ffffield.label=data?.name;
                                        }
                                        if(ffffield.name==="orgname_value_textfield"){
                                            ffffield.value=data?.name;
                                        }
                                        return ffffield;
                                    })
                                }
                                if(fffield.name==="org_address"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="org_address_value"){
                                            ffffield.label=data?.address;
                                        }
                                        if(ffffield.name==="org_address_textfield"){
                                            ffffield.value=data?.address;
                                        }
                                        return ffffield;
                                    })
                                }
                                if(fffield.name==="org_website"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="org_website_value"){
                                            ffffield.label=data?.website;
                                        }
                                        if(ffffield.name==="org_website_textfield"){
                                            ffffield.value=data?.website;
                                        }
                                        return ffffield;
                                    })
                                }
                                if(fffield.name==="org_city"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="org_city_value"){
                                            ffffield.label=data?.city;
                                        }
                                        if(ffffield.name==="org_city_textfield"){
                                            ffffield.value=data?.city;
                                        }
                                        return ffffield;
                                    })
                                }
                                if(fffield.name==="org_gstn"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="org_gstn_value"){
                                            ffffield.label=data?.gstin;
                                        }
                                        if(ffffield.name==="org_gstn_textfield"){
                                            ffffield.value=data?.gstin;
                                        }
                                        return ffffield;
                                    })
                                }
                                if(fffield.name==="org_pincode"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="org_pincode_value"){
                                            ffffield.label=data?.zipcode;
                                        }
                                        if(ffffield.name==="org_pincode_textfield"){
                                            ffffield.value=data?.zipcode;
                                        }
                                        return ffffield;
                                    })
                                }
                                if(fffield.name==="org_mobile"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="org_mobile_value"){
                                            ffffield.label=data?.phone_number;
                                        }
                                        if(ffffield.name==="org_mobile_textfield"){
                                            ffffield.value=data?.phone_number;
                                        }
                                        return ffffield;
                                    })
                                }
                                if(fffield.name==="virtual_number"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="org_virtual_value"){
                                            ffffield.label=data?.virtual_number;
                                        }
                                        if(ffffield.name==="org_virtual_textfield"){
                                            ffffield.value=data?.virtual_number;
                                        }
                                        return ffffield;
                                    })
                                }
                                if(fffield.name==="meta_number"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="meta_phone_number_value"){
                                            ffffield.label=data?.meta_phone_number;
                                        }
                                        if(ffffield.name==="meta_phone_number_textfield"){
                                            ffffield.value=data?.meta_phone_number;
                                        }
                                        return ffffield;
                                    })
                                }
                                return fffield;
                            })
                        }
                        return ffield;
                    })
                }
                return field;
            })
            return {...prev}
        })

        setbrandingDetails((prev:any)=>{
            prev.fields=prev.fields.map((field:any)=>{
                if(field.name==="branding_fields"){
                    field.fields=field.fields.map((ffield:any)=>{
                        if(ffield.name==="color_schema"){
                            ffield.fields=ffield.fields.map((fffield:any)=>{
                                if(fffield.name==="org_colorpicker"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        ffffield.fields=ffffield.fields.map((fffffield:any)=>{
                                            if(fffffield.name==="primary_button_value"){
                                                fffffield.value=data?.primary_btn_color
                                            }
                                            if(fffffield.name==="primary_text_color_value"){
                                                fffffield.value=data?.primary_btn_text_color
                                            }
                                            if(fffffield.name==="secondary_button_value"){
                                                fffffield.value=data?.secondary_btn_color
                                            }
                                            if(fffffield.name==="secondary_text_color_value"){
                                                fffffield.value=data?.secondary_btn_text_color
                                            }
                                            if(fffffield.name==="normal_text_color_value"){
                                                fffffield.value=data?.primary_text_color
                                            }
                                            return fffffield;
                                        })
                                        return ffffield;
                                    })
                                }
                                return fffield;
                            })
                        }
                        if(ffield.name==="org_logo_upload"){ 
                            ffield.fields=ffield.fields.map((fffield:any)=>{
                                if(fffield.name==="logo_preview_image"){
                                    fffield.fields=fffield.fields.map((fffffield:any)=>{
                                        if(fffffield.name==="logo_design_image_preview"){
                                            fffffield.img=`${BASE_URL}/api/${data?.logo}`
                                        }
                                        // if(fffffield.name==="logo_design_image_upload_preview"){
                                        //     fffffield.value=`data:image/png;base64,${data?.logo}`
                                        // }
                                        return fffffield;
                                    })
                                }
                                return fffield;
                            })
                        }
                        return ffield;
                    })
                }
                return field;
            })
            return {...prev}
        })

        setChatBotSchema((prev:any)=>{
            prev.fields=prev.fields.map((field:any)=>{
                if(field.name==="chatbot_details_snippet"){
                    field.fields=field.fields.map((ffield:any)=>{
                        if(ffield.name==="chatbot_name"){
                            ffield.fields=ffield.fields.map((fffield:any)=>{
                                if(fffield.name==="name_of_the_chatbot"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="chatbot_name_value"){
                                            ffffield.label=data?.chatbot_name
                                        }
                                        if(ffffield.name==="chatbot_name_value_textfield"){
                                            ffffield.value=data?.chatbot_name
                                        }
                                        return ffffield;
                                    })
                                }
                                if(fffield.name==="chatbot_logo_upload"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="chatbot_preview_image"){
                                            ffffield.fields=ffffield.fields.map((fffffield:any)=>{
                                                if(fffffield.name==="chatbot_design_image_preview"){
                                                    fffffield.img=`${BASE_URL}/api/${data?.chatbot_icon}`
                                                }
                                                // if(fffffield.name==="chatbot_design_image_upload_preview"){
                                                //     fffffield.value=`data:image/png;base64,${data?.chatbot_icon}`
                                                // }
                                                return fffffield;
                                            })
                                        }
                                        return ffffield;
                                    })
                                }
                                return fffield;
                            })
                        }
                        
                        return ffield;
                    })
                }
                return field;
            })
            return {...prev}
        })

        setPrimaryButtonBg(data?.primary_btn_color);
        setPrimaryTextColor(data?.primary_btn_text_color);
        setSecondaryButtonBg(data?.secondary_btn_color);
        setSecondaryTextColor(data?.secondary_btn_text_color);
        setNormalText(data?.primary_text_color);
        setOrgName(data?.name)
        setAddress(data?.address)
        setCity(data?.city)
        setGstn(data?.gstin)
        setPincode(data?.zipcode)
        setMobile(data?.phone_number)
        setVirtualNumber(data?.virtual_number)
        setChatbotName(data?.chatbot_name)
        setMetaNumber(data?.meta_phone_number)
        setBrandingLogo(data?.logo)
        setChatbotLogo(data?.chatbot_icon)
    }

    const handleorganizationDetails = () => {
        setOganizationDetails((prev: any) => {
            prev.fields = prev.fields.map((ffield: any) => {
                if (ffield.name === "org_details_text") {
                    ffield.fields = ffield.fields.map((fffield: any) => {
                        if (fffield.name === "org_details_edit_icon") {
                            fffield.visible = false;
                        }
                        return fffield;
                    });
                }
                if (ffield.name === "org_data_fields") {
                    ffield.fields = ffield.fields.map((fffield: any) => {
                        if (fffield.name === "org_data_fields_values") {
                            fffield.fields = fffield.fields.map((ffffield: any) => {
                                ffffield.fields = ffffield.fields.map((fffffield: any) => {
                                    if (fffffield.name.endsWith("_value")) {
                                        fffffield.visible=false;
                                    }
                                    if (fffffield.name.endsWith("_text") && fffffield.name!=="org_gstn_text") {
                                        fffffield.required=true;
                                    }
                                    if (fffffield.name.endsWith("_textfield")) {
                                        fffffield.visible=true;
                                    }
                                    return fffffield;
                                });
                                return ffffield;
                            });
                        }
                        if (fffield.name === "org_details_buttons") {
                            fffield.fields = fffield.fields.map((ffffield: any) => {
                                if (ffffield.name === "org_details_save_changes" || ffffield.name === "org_details_cancel_button") {
                                    ffffield.visible = true;
                                }
                                return ffffield;
                            });
                        }
                        return fffield;
                    });
                }
                return ffield;
            });
            return { ...prev };
        });
    }
    const handleBrandingDetails=()=>{
        setbrandingDetails((prev: any) => {
            prev.fields = prev.fields.map((ffield: any) => {
                if (ffield.name === "branding_details_text") {
                    ffield.fields = ffield.fields.map((fffield: any) => {
                        if (fffield.name === "branding_details_edit_icon") {
                            fffield.visible = false;
                        }
                        return fffield;
                    });
                }
                if(ffield.name==="branding_fields"){
                    ffield.fields=ffield.fields.map((fffield:any)=>{
                        if(fffield.name==="color_schema"){
                            fffield.fields=fffield.fields.map((ffffield:any)=>{
                                if(ffffield.name==="org_colorpicker"){
                                    ffffield.fields=ffffield.fields.map((fffffield:any)=>{
                                        fffffield.fields=fffffield.fields.map((ffffffield:any)=>{
                                            if(["primary_button_value","primary_text_color_value","secondary_button_value","secondary_text_color_value","normal_text_color_value"].includes(ffffffield.name)){
                                                ffffffield.disabled=false;
                                            }
                                            return ffffffield;
                                        })
                                        return fffffield;
                                    })
                                }
                                return ffffield;
                            })
                        }
                        if(fffield.name==="org_logo_upload"){
                            fffield.fields=fffield.fields.map((ffffield:any)=>{
                                if(ffffield.name==="logo_preview_image"){
                                    ffffield.fields=ffffield.fields.map((fffffield:any)=>{
                                        if(fffffield.name==="logo_design_image_preview"){
                                            fffffield.visible=false;
                                        }
                                        if(fffffield.name==="logo_design_image_upload_preview"){
                                            fffffield.visible=true
                                        }
                                        return fffffield;
                                    })
                                }
                                return ffffield;
                            })
                        }
                        return fffield;
                    })
                }
                if (ffield.name === "branding_details_buttons") {
                    ffield.fields = ffield.fields.map((ffffield: any) => {
                        if (ffffield.name === "branding_details_save_changes" || ffffield.name === "branding_details_cancel_button") {
                            ffffield.visible = true;
                        }
                        return ffffield;
                    });
                }
                return ffield;
            });
            return { ...prev };
        });
    }
    const handleChatbotSchema=()=>{
        setChatBotSchema((prev:any)=>{
            prev.fields=prev.fields.map((field:any)=>{
                if(field.name==="chatbot_text"){
                    field.fields=field.fields.map((ffield:any)=>{
                        if(ffield.name==="chatbot_edit_icon"){
                            ffield.visible=false;
                        }
                        return ffield;
                    })
                }
                if(field.name==="chatbot_details_snippet"){
                    field.fields=field.fields.map((ffield:any)=>{
                        if(ffield.name==="chatbot_name"){
                            ffield.fields=ffield.fields.map((fffield:any)=>{
                                if(fffield.name==="name_of_the_chatbot"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="chatbot_name_value"){
                                            ffffield.visible=false;
                                        }
                                        if(ffffield.name==="chatbot_name_text"){
                                            ffffield.required=true;
                                        }
                                        if(ffffield.name==="chatbot_name_value_textfield"){
                                            ffffield.visible=true;
                                        }
                                        return ffffield;
                                    })
                                }
                                if(fffield.name==="chatbot_logo_upload"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="chatbot_preview_image"){
                                            ffffield.fields=ffffield.fields.map((fffffield:any)=>{
                                                if(fffffield.name==="chatbot_design_image_preview"){
                                                    fffffield.visible=false;
                                                }
                                                if(fffffield.name==="chatbot_design_image_upload_preview"){
                                                    fffffield.visible=true;
                                                }
                                                return fffffield;
                                            })
                                        }
                                        return ffffield;
                                    })
                                }
                                return fffield;
                            })
                        }
                        
                        return ffield;
                    })
                }
                if(field.name==="chatbot_buttons"){
                    field.visible=true;
                }
                return field;
            })
            return {...prev}
        })
    }
    const handleOrgDetailsCancelButton = () => {
        setOganizationDetails((prev: any) => {
          prev.fields = prev.fields.map((ffield: any) => {
            if (ffield.name === "org_details_text") {
              ffield.fields = ffield.fields.map((fffield: any) => {
                if (fffield.name === "org_details_edit_icon") {
                  fffield.visible = true;
                }
                return fffield;
              });
            }
            if (ffield.name === "org_data_fields") {
              ffield.fields = ffield.fields.map((fffield: any) => {
                if (fffield.name === "org_data_fields_values") {
                  fffield.fields = fffield.fields.map((ffffield: any) => {
                    ffffield.fields = ffffield.fields.map((fffffield: any) => {
                        if (fffffield.name.endsWith("_value")) {
                            fffffield.visible=true;
                        }
                        if (fffffield.name.endsWith("_text")) {
                            fffffield.required=false;
                        }
                        if (fffffield.name.endsWith("_textfield")) {
                            if(fffffield.name==="orgname_value_textfield"){
                                fffffield.value=orgDetails?.name
                            }
                            if(fffffield.name==="org_address_textfield"){
                                fffffield.value=orgDetails?.address
                            }
                            if(fffffield.name==="org_website_textfield"){
                                fffffield.value=orgDetails?.website
                            }
                            if(fffffield.name==="org_city_textfield"){
                                fffffield.value=orgDetails?.city
                            }
                            if(fffffield.name==="org_gstn_textfield"){
                                fffffield.value=orgDetails?.gstin
                            }
                            if(fffffield.name==="org_pincode_textfield"){
                                fffffield.value=orgDetails?.zipcode
                            }
                            if(fffffield.name==="org_mobile_textfield"){
                                fffffield.value=orgDetails?.phone_number
                            }
                            if(fffffield.name==="org_virtual_textfield"){
                                fffffield.value=orgDetails?.virtual_number
                            }
                            if(fffffield.name==="meta_phone_number_textfield"){
                                fffffield.value=orgDetails?.meta_phone_number;
                            }
                            fffffield.visible=false;
                        }
                      return fffffield;
                    });
                    return ffffield;
                  });
                }
                if (fffield.name === "org_details_buttons") {
                  fffield.fields = fffield.fields.map((ffffield: any) => {
                    if (ffffield.name === "org_details_save_changes" || ffffield.name === "org_details_cancel_button") {
                      ffffield.visible = false;
                    }
                    return ffffield;
                  });
                }
                return fffield;
              });
            }
            return ffield;
          });
          return { ...prev };
        });

        setOrgName(orgDetails?.name)
        setAddress(orgDetails?.address)
        setCity(orgDetails?.city)
        setGstn(orgDetails?.gstin)
        setPincode(orgDetails?.zipcode)
        setMobile(orgDetails?.phone_number)
        setVirtualNumber(orgDetails?.virtual_number)
    };
    const handleBrandingCancelButton = () => {

        setbrandingDetails((prev: any) => {
            prev.fields = prev.fields.map((ffield: any) => {
                if (ffield.name === "branding_details_text") {
                    ffield.fields = ffield.fields.map((fffield: any) => {
                        if (fffield.name === "branding_details_edit_icon") {
                            fffield.visible = true;
                        }
                        return fffield;
                    });
                }
                if(ffield.name==="branding_fields"){
                    ffield.fields=ffield.fields.map((fffield:any)=>{
                        if(fffield.name==="color_schema"){
                            fffield.fields=fffield.fields.map((ffffield:any)=>{
                                if(ffffield.name==="org_colorpicker"){
                                    ffffield.fields=ffffield.fields.map((fffffield:any)=>{
                                        fffffield.fields=fffffield.fields.map((ffffffield:any)=>{
                                            if(ffffffield.name==="primary_button_value"){
                                                ffffffield.value=orgDetails?.primary_btn_color
                                                ffffffield.disabled=true
                                            }
                                            if(ffffffield.name==="primary_text_color_value"){
                                                ffffffield.value=orgDetails?.primary_btn_text_color
                                                ffffffield.disabled=true
                                            }
                                            if(ffffffield.name==="secondary_button_value"){
                                                ffffffield.value=orgDetails?.secondary_btn_color
                                                ffffffield.disabled=true
                                            }
                                            if(ffffffield.name==="secondary_text_color_value"){
                                                ffffffield.value=orgDetails?.secondary_btn_text_color
                                                ffffffield.disabled=true
                                            }
                                            if(ffffffield.name==="normal_text_color_value"){
                                                ffffffield.value=orgDetails?.primary_text_color
                                                ffffffield.disabled=true
                                            }
                                            return ffffffield;
                                        })
                                        return fffffield;
                                    })
                                }
                                return ffffield;
                            })
                        }
                        if(fffield.name==="org_logo_upload"){
                            fffield.fields=fffield.fields.map((ffffield:any)=>{
                                if(ffffield.name==="logo_preview_image"){
                                    ffffield.fields=ffffield.fields.map((fffffield:any)=>{
                                        if(fffffield.name==="logo_design_image_preview"){
                                            fffffield.visible=true;
                                            fffffield.img=`${BASE_URL}/api/${brandingLogo}`
                                        }
                                        if(fffffield.name==="logo_design_image_upload_preview"){
                                            fffffield.visible=false 
                                        }
                                        return fffffield;
                                    })
                                }
                                return ffffield;
                            })
                        }
                        return fffield;
                    })
                }
                if (ffield.name === "branding_details_buttons") {
                    ffield.fields = ffield.fields.map((ffffield: any) => {
                        if (ffffield.name === "branding_details_save_changes" || ffffield.name === "branding_details_cancel_button") {
                            ffffield.visible = false;
                        }
                        return ffffield;
                    });
                }
                return ffield;
            });
            return { ...prev };
        });

        setPrimaryButtonBg(orgDetails?.primary_btn_color);
        setPrimaryTextColor(orgDetails?.primary_btn_text_color);
        setSecondaryButtonBg(orgDetails?.secondary_btn_color);
        setSecondaryTextColor(orgDetails?.secondary_btn_text_color);
        setNormalText(orgDetails?.primary_text_color);
        setBrandingLogo(orgDetails?.logo)
    }
    const handleChatBotCancelButton=()=>{
        setChatBotSchema((prev:any)=>{
            prev.fields=prev.fields.map((field:any)=>{
                if(field.name==="chatbot_text"){
                    field.fields=field.fields.map((ffield:any)=>{
                        if(ffield.name==="chatbot_edit_icon"){
                            ffield.visible=true;
                        }
                        return ffield;
                    })
                }
                if(field.name==="chatbot_details_snippet"){
                    field.fields=field.fields.map((ffield:any)=>{
                        if(ffield.name==="chatbot_name"){
                            ffield.fields=ffield.fields.map((fffield:any)=>{
                                if(fffield.name==="name_of_the_chatbot"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="chatbot_name_value"){
                                            ffffield.visible=true;
                                        }
                                        if(ffffield.name==="chatbot_name_text"){
                                            ffffield.required=false;
                                        }
                                        if(ffffield.name==="chatbot_name_value_textfield"){
                                            ffffield.visible=false;
                                            ffffield.value=orgDetails?.chatbot_name
                                        }
                                        return ffffield;
                                    })
                                }
                                if(fffield.name==="chatbot_logo_upload"){
                                    fffield.fields=fffield.fields.map((ffffield:any)=>{
                                        if(ffffield.name==="chatbot_preview_image"){
                                            ffffield.fields=ffffield.fields.map((fffffield:any)=>{
                                                if(fffffield.name==="chatbot_design_image_preview"){
                                                    fffffield.visible=true;
                                                    fffffield.img=`${BASE_URL}/api/${orgDetails?.chatbot_icon}`
                                                }
                                                if(fffffield.name==="chatbot_design_image_upload_preview"){
                                                    fffffield.visible=false;
                                                }
                                                return fffffield;
                                            })
                                        }
                                        return ffffield;
                                    })
                                }
                                return fffield;
                            })
                        }
                        return ffield;
                    })
                }
                if(field.name==="chatbot_buttons"){
                    field.visible=false;
                }
                return field;
            })
            return {...prev}
        })
        setChatbotName(orgDetails?.chatbot_name)
        setChatbotLogo(orgDetails?.chatbot_icon)
    }

    const [currentFeatId, setCurrentFeatId] = useState("")
    const handleFeatureConfigModal = (data: any, visible: boolean) =>{
        setLoading(true)
        if(visible){
            getRecipientsList(data.id)
            setCurrentFeatId(data.id)
        }else{
            setCurrentFeatId("")
        }
        
        setSchema((prev: any)=>{
            prev?.schema?.map((f: any)=>{
                if(f.name === "feature_config_modal"){
                    f.visible = visible
                    f?.fields?.map((ff: any)=>{
                        if(ff.name === "feature_config_modal_body"){
                            ff?.fields?.map((k: any)=>{
                                if(k.name === "feature_config_modal_data"){
                                    k?.fields?.map((kk: any)=>{
                                        if(kk.name === "feature_config_modal_data_values"){
                                            kk?.fields?.map((g: any)=>{
                                                if(g.name === "feature"){
                                                    g.label = data['feature']
                                                }
                                                if(g.name === "frequency"){
                                                    g.label = FREQUENCY[data.frequency] || "N/A"
                                                }
                                                if(g.name === "status"){
                                                    g.label = data.status === 1? "Active" : "In Active"
                                                }
                                            })
                                            
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
            return {...prev}
        })
        setLoading(false)
    }

    useEffect(()=>{
        getFeatures()
    }, [])

    const getFeatures = async () =>{
        try{

            const res = await Utils.makeApiCall(`/all-features`, "GET");

            if(res.status){
                setSchema((prev: any) =>{

                    prev?.schema?.map((f: any)=>{
                        if(f.name === "features_container"){
                            f?.fields?.map((ff: any)=>{
                                if(ff.name === "features_table"){
                                    ff.tbody = res.data
                                    ff.thead.map((k: any)=>{
                                        if(k.name === "status"){
                                            k.render =  (e: any, record: any) => {
                                                return(
                                                    <div className='w-full flex gap-2 justify-center' >
                                                        <Tooltip title={record?.status === 1 ? "Click to Turn off" : "Click to Turn On"} >
                                                            <Switch size="small" defaultChecked={record?.status === 1 ? true : false} 
                                                            onChange={()=>handleOrgFeatStatus(record)}
                                                            />
                                                    </Tooltip>
                                                    </div>
                                                )
                                              }
                                        }
                                        if(k.name === "configure"){
                                            k.render= (e: any, record: any) =>{
                                                return(
                                                    <div className='w-full flex gap-2 justify-center' >
                                                            <div
                                                                onKeyDown={() =>{
                                                                    handleFeatureConfigModal(record, true)
                                                                }}
                                                                tabIndex={0}
                                                                role="button"
                                                                className='w-fit'
                                                                onClick={() =>{
                                                                    console.log(record);
                                                                    
                                                                    handleFeatureConfigModal(record, true)
                                                                }}
                                                                    >
                                                                        <Tooltip title="Configure">
                                                                        <img
                                                                            src={
                                                                                `${BASE_URL}/api/public/assets/images/edit.svg`
                                                                            }
                                                                            alt="Comment"
                                                                            className="w-4 h-4"
                                                                        />
                                                                        </Tooltip>
                                                            </div>
                                                    </div>
                                                )
                                            }
                                        }
                                    })
                                }
                            })
                        }
                    })

                    return {...prev}
                })
            }

        }catch(e){
            console.log(e)
        }
    }

    const getRecipientsList = async (featId: any) =>{
        setLoading(true)
        try{
            const res = await Utils.makeApiCall(`/feature-recipients-list?feat_id=${featId}`, "GET");

            if(res.status){

                const whatsOptions: any = []
                const emailOptions: any = []

                const whatsValues: any = []
                const emailValues: any = []
                await res.users.map((d: any)=>{
                    const optW = {
                        label: `${d?.fullname || "User"} (${d?.phonenumber}) `,
                        value: d?.id,
                        user_id: d?.id,
                        phone: d?.phonenumber
                    }
                    const optE = {
                        label: `${d?.fullname || "User"} (${d?.email}) `,
                        value: d?.id,
                        user_id: d?.id,
                        email: d?.email
                    }

                    whatsOptions.push(optW)
                    emailOptions.push(optE)
                })

                await res.whatsappRecipients.map((d: any)=>{
                    const optW = {
                        label: `${d?.fullname || "User"} (${d?.phonenumber}) `,
                        value: d?.user_id || d?.phonenumber,
                        user_id: d?.user_id,
                        phone: d?.phonenumber
                    }
                    whatsValues.push(optW)
                })

                await res.emailRecipients.map((d: any)=>{
                    const optE = {
                        label: `${d?.fullname || "User"} (${d?.email}) `,
                        value: d?.user_id || d?.email,
                        user_id: d?.user_id,
                        email: d?.email
                    }
                    emailValues.push(optE)
                })
                
                setSchema((prev: any)=>{
                    prev?.schema?.map((f: any)=>{
                        if(f.name === "feature_config_modal"){
                            f?.fields?.map((ff: any)=>{
                                if(ff.name === "feature_config_modal_body"){
                                    ff?.fields?.map((fff: any)=>{
                                        if(fff.name === "whatsapp_recipients"){
                                            fff.options = whatsOptions
                                            fff.value = whatsValues
                                        }
                                        if(fff.name === "email_recipients"){
                                            fff.options = emailOptions
                                            fff.value = emailValues
                                        }
                                    })
                                }
                            })
                        }
                    })
                    return {...prev}
                })
            }
        }catch(e){
            console.log(e)
        }
        setLoading(false)
    } 

    const updateFeatureRecipients = async () =>{
        const whatsappRecipients: any = []
        const emailRecipients: any = []

        const whatsappUserLess: any = []
        const emailUserLess: any = []
        
        await schema?.schema?.map((f: any)=>{
            if(f.name === "feature_config_modal"){
                f?.fields?.map((ff: any)=>{
                    if(ff.name === "feature_config_modal_body"){
                        ff?.fields?.map((fff: any)=>{
                            if(fff.name === "whatsapp_recipients"){
                                fff.value.map((v: any)=>{
                                    
                                    if(v.user_id === "" || v.user_id === null || v.user_id === undefined){
                                        whatsappUserLess.push(v.value)
                                    }else{
                                        whatsappRecipients.push(v.value)
                                    }
                                })
                            }
                            if(fff.name === "email_recipients"){
                                fff.value.map((v: any)=>{
                                    if(v.user_id === "" || v.user_id === null || v.user_id === undefined){
                                        emailUserLess.push(v.value)
                                    }else{
                                        emailRecipients.push(v.value)
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })

        const payload = {
            "feature_id": currentFeatId,
            "whatsapp_recipients": whatsappRecipients,
            "email_recipients": emailRecipients,
            "whatsapp_userless": whatsappUserLess,
            "email_userless": emailUserLess
        }

        
        try{
            const res = await Utils.makeApiCall("/update-feature-recipients", "POST", payload)
            if(res.status){
                toast.success("Notification Recipients Updated!!",{position:"top-center",autoClose:1500})
                handleFeatureConfigModal("", false)
            }else{
                toast.error("Failed to Update Notification Recipients!!",{position:"top-center",autoClose:1500})
                getFeatures()
            }

        }catch(e){
            toast.error("Failed to Update Notification Recipients!!",{position:"top-center",autoClose:1500})
            getFeatures()
        }
        
           
    }

    const handleOrgFeatStatus = async (record: any)=>{
        try{
            console.log(record);

            const res = await Utils.makeApiCall(`/update-org-feat-status?feat_id=${record.id}&status=${record.status === 1 ? 0 : 1}`, "GET");
            if(res.status){
                getFeatures()
                toast.success("Status Updated!!",{position:"top-center",autoClose:1500})
            }
            
        }catch(e){
            console.log(e)
            getFeatures()
            toast.error("Failed to Update Status!!",{position:"top-center",autoClose:1500})
        }
    }


    
    const handleselectedRecord= async(e:any)=>{
        if(e.name==="org_details_edit_icon"){
            handleorganizationDetails();
        }
        if(e.name==="branding_details_edit_icon"){
            handleBrandingDetails()
        }
        if(e.name==="chatbot_edit_icon"){
            handleChatbotSchema();
        }
        if (e.name === "org_details_cancel_button") {
            setDetectChange(false)
            handleOrgDetailsCancelButton();
        }
        if(e.name==="branding_details_cancel_button"){
            setDetectChange(false)
            handleBrandingCancelButton()
        }
        if(e.name==="chatbot_cancel_button"){
            setDetectChange(false)
            handleChatBotCancelButton()
        }
        if(e.name==="primary_button_value"){
            setPrimaryButtonBg(e.value)
        }
        if(e.name==="primary_text_color_value"){
            setPrimaryTextColor(e.value)
        }
        if(e.name==="secondary_button_value"){
            setSecondaryButtonBg(e.value)
        }
        if(e.name==="secondary_text_color_value"){
            setSecondaryTextColor(e.value);
        }
        if(e.name==="normal_text_color_value"){
            setNormalText(e.value)
        }
        if (e.name === "chatbot_copy_button") {
            
            const snippetField = chatBotSchema.fields.find((field: { name: string; }) => field.name === "chatbot_details_snippet")
                ?.fields.find((field: { name: string; }) => field.name === "chatbot_snippet_code")
                ?.fields.find((field: { name: string; }) => field.name === "chatbot-snippet-code_text");
        
            if (snippetField) {
                const text = snippetField.label; 
                navigator.clipboard.writeText(text);
                toast.success("Snippet is copied.",{position:"top-center",autoClose:500})
            }
        }
        if(e.name==="orgname_value_textfield"){
            setOrgName(e.value)
        }
        if(e.name==="org_address_textfield"){
            setAddress(e.value)
        }
        if(e.name==="org_website_textfield"){
            setAddress(e.value)
        }
        if(e.name==="org_city_textfield"){
            setCity(e.value)
        }
        if(e.name==="org_gstn_textfield"){
            setGstn(e.value)
        }
        if(e.name==="org_pincode_textfield"){
            setPincode(e.value)
        }
        if(e.name==="org_mobile_textfield"){
            setMobile(e.value)
        }
        if(e.name==="org_virtual_textfield"){
            setVirtualNumber(e.value)
        }
        if(e.name==="chatbot_name_value_textfield"){
            setChatbotName(e.value)
        }
        if(e.name==="meta_phone_number_textfield"){
            setMetaNumber(e.value)
        }
        if(e.name==="org_details_save_changes"){
            setDetectChange(false)
            API_PUT_OrganizationDetails("org_details_save_changes")
        }
        if(e.name==="branding_details_save_changes"){
            setDetectChange(false)
            API_PUT_OrganizationDetails("branding_details_save_changes")
        }
        if(e.name==="chatbot_save_changes"){
            setDetectChange(false)
            API_PUT_OrganizationDetails("chatbot_save_changes")
        }
        if(e.name==="logo_design_image_upload_preview"){
            setBrandingUploadLogo(e.value);
        }
        if(e.name==="chatbot_design_image_upload_preview"){
            setChatbotUploadLogo(e.value)
        }
        if(e.name === "feature_config_modal_close"){
            handleFeatureConfigModal([], false)
            getFeatures()
        }
        if(e.name === "update_feature_recipients_config"){
            await updateFeatureRecipients()
            getFeatures()
        }
    }

  return (
    <div className=''>
        <ElementExecutor
            data={schema}
            selectedRecord={(e:any)=>{
                handleselectedRecord(e)
                if(["meta_phone_number_textfield","chatbot_name_value_textfield","org_virtual_textfield","org_mobile_textfield","org_pincode_textfield","org_gstn_textfield","org_city_textfield","org_website_textfield","org_address_textfield","primary_button_value","primary_text_color_value","secondary_button_value","secondary_text_color_value","normal_text_color_value","orgname_value_textfield"].includes(e?.name)){
                    setDetectChange(true) 
                }
            }}
        />
    </div>
  )
}

export default Profile;